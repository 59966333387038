import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'
import { STAFF_COLOURS, VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

import { withModalService } from 'services/utils/modal'
import { calculateEdgeTimesBasedOnStatistics } from 'services/legacy/membershipsShifts/single/selectors'

import { Chart, Space, Table, Typography } from 'components'

import i18n from 'translations'

import { COLUMNS_WIDTHS } from '../../StaffRotaView'
import ChartRotaLegend from '../ChartRotaLegend'
import { StyledChartWrapper, StyledCustomTooltip } from './ChartRotaStyled'

const CHART_COLUMN = {
  EXPECTED: 'expected',
  REQUIRED: 'required',
}

const ChartRota = ({
  date,
  day,
  earliestTime,
  latestTime,
  modalActions,
  modalConsts,
  room,
  statistics,
  viewMode,
  widgetMode,
}) => {
  let finalEarliestTime = earliestTime
  let finalLatestTime = latestTime

  const handleShowChildrenExpectedModal = (data, dayIndex) => {
    const dateDay = _.isNumber(dayIndex)
      ? moment(date).add(dayIndex, 'day')
      : moment(day)

    modalActions.show(modalConsts.TYPES.STAFF_ROTA_CHILDREN_EXPECTED, {
      data,
      filters: {
        date: dateDay,
        room,
      },
    })
  }

  const renderTooltip = ({ point: { data } }) => {
    const { childrenExpected, staffExpected, staffRequired, time, x } = data || {}

    if (0 > x) {
      return null
    }

    return (
      <StyledCustomTooltip $widgetMode={widgetMode}>
        <Typography fontSize={14} bold>
          {_.upperFirst(i18n.t('global:time'))}
          {': '}
          {moment(time).format('HH:mm')}
        </Typography>
        <Space space="4px" />
        <Typography fontSize={14} bold>
          {i18n.t('module:Staff:StaffRota:staffRequired')}
          {': '}
          {staffRequired || 0}
        </Typography>
        <Space space="4px" />
        <Typography fontSize={14} bold>
          {i18n.t('module:Staff:StaffRota:staffExpected')}
          {': '}
          {staffExpected || 0}
        </Typography>
        <Space space="4px" />
        <Typography fontSize={14} bold>
          {i18n.t('module:Staff:StaffRota:childrenExpected')}
          {': '}
          {childrenExpected || 0}
        </Typography>
      </StyledCustomTooltip>
    )
  }

  const renderChart = (dayIndex) => {
    const colors = {
      [CHART_COLUMN.EXPECTED]: STAFF_COLOURS.EXPECTED,
      [CHART_COLUMN.REQUIRED]: STAFF_COLOURS.REQUIRED,
    }

    const dateDay = _.isNumber(dayIndex)
      ? moment(date).add(dayIndex, 'day')
      : moment(day)
    const stats = statistics?.[dateDay.format(DEFAULT_DATE_FORMAT)]?.[0] || {}
    const { breakdowns } = stats || {}

    if (VIEW_MODE.WEEK === viewMode) {
      const edgeTimesFromStatistics = calculateEdgeTimesBasedOnStatistics(statistics, dateDay)

      finalEarliestTime = _.min([finalEarliestTime, edgeTimesFromStatistics.from])
      finalLatestTime = _.max([finalEarliestTime, edgeTimesFromStatistics.to])
    }

    if (!breakdowns?.length) {
      if (stats?.closed) {
        return (
          <Typography align="left" color={NEUTRAL_COLOURS.GRAY}>
            {i18n.t('module:Staff:StaffRota:nurseryClosureDate')}
          </Typography>
        )
      }
    }

    const timeInterval = 5
    const timeIntervalInMilliseconds = timeInterval * 1000
    const everyEachMinute = 60 / timeInterval

    let expectedList = _.times((((finalLatestTime - finalEarliestTime)) * everyEachMinute), (index) => ({
      time: moment().set('hour', finalEarliestTime).set('minute', 0).add(timeInterval * index, 'minute'),
      x: index,
      y: 0,
    }))

    let requiredList = _.times((((finalLatestTime - finalEarliestTime)) * everyEachMinute), (index) => ({
      time: moment().set('hour', finalEarliestTime).set('minute', 0).add(timeInterval * index, 'minute'),
      x: index,
      y: 0,
    }))

    let gridYValues = []

    _.each(breakdowns, ({
      children,
      childrenExpected,
      endTime,
      staffExpected,
      staffRequired,
      startTime,
    }) => {
      const startTimeInParts = parseInt(
        moment(startTime).add(-finalEarliestTime, 'hour').utc().format('x') / 60 / timeIntervalInMilliseconds,
      )
      const endTimeInParts = parseInt(
        moment(endTime).add(-finalEarliestTime, 'hour').utc().format('x') / 60 / timeIntervalInMilliseconds,
      )

      gridYValues = [
        0,
        ...gridYValues,
        staffExpected,
        staffRequired,
      ]

      expectedList = _.map(expectedList, (item, index) => {
        if (index >= startTimeInParts && index <= endTimeInParts) {
          return {
            ...item,
            children,
            childrenExpected,
            staffExpected,
            staffRequired,
            y: staffExpected,
          }
        }

        return {
          ...item,
        }
      })

      requiredList = _.map(_.cloneDeep(requiredList), (item, index) => {
        if (index >= startTimeInParts && index <= endTimeInParts) {
          return {
            ...item,
            children,
            childrenExpected,
            staffExpected,
            staffRequired,
            x: item.x,
            y: staffRequired,
          }
        }

        return {
          ...item,
        }
      })
    })

    expectedList.unshift({
      childrenExpected: 0,
      staffExpected: 0,
      staffRequired: 0,
      x: -1,
      y: 0,
    })

    requiredList.unshift({
      childrenExpected: 0,
      staffExpected: 0,
      staffRequired: 0,
      x: -1,
      y: 0,
    })

    if (0 < expectedList?.[0]?.staffExpected) {
      expectedList[0].staffExpected = 0
    }

    if (0 < expectedList?.[0]?.staffRequired) {
      expectedList[0].staffRequired = 0
    }

    if (0 < requiredList?.[0]?.staffExpected) {
      requiredList[0].staffExpected = 0
    }

    if (0 < requiredList?.[0]?.staffRequired) {
      requiredList[0].staffRequired = 0
    }

    gridYValues = _.uniq(gridYValues)

    if (!gridYValues?.length) {
      gridYValues = [0, 1]
    }

    return (
      <StyledChartWrapper mode={viewMode}>
        <Chart.Line
          axisLeft={{
            format: (value) => {
              if (VIEW_MODE.DAY !== viewMode || !_.includes(gridYValues, value) || !value) {
                return ''
              }

              return value
            },
            orient: 'left',
            tickPadding: -10,
            tickRotation: 0,
            tickSize: 0,
          }}
          colors={(bar) => colors[bar.id]}
          data={[
            {
              data: expectedList,
              id: CHART_COLUMN.EXPECTED,
            },
            {
              data: requiredList,
              id: CHART_COLUMN.REQUIRED,
            },
          ]}
          enableGridY={VIEW_MODE.DAY === viewMode}
          gridYValues={gridYValues}
          height={80}
          minAndMaxValue={[_.min(gridYValues), _.max(gridYValues)]}
          tooltip={renderTooltip}
          onClick={({ data }) => handleShowChildrenExpectedModal(data, dayIndex)}
        />
      </StyledChartWrapper>
    )
  }

  if (widgetMode) {
    return renderChart()
  }

  return (
    <Table.Tr>
      <Table.Td width={`${COLUMNS_WIDTHS.LEFT}px`}>
        <ChartRotaLegend />
      </Table.Td>
      {VIEW_MODE.WEEK === viewMode && _.times(AVAILABLE_DAYS, (i) => (
        <Table.Td key={i}>
          {renderChart(i)}
        </Table.Td>
      ))}
      {VIEW_MODE.DAY === viewMode && (
        <Table.Td>
          {renderChart()}
        </Table.Td>
      )}
    </Table.Tr>
  )
}

const enhance = compose(
  withModalService,
)

export default enhance(ChartRota)

