import _ from 'lodash'

import React from 'react'

import { Nursery } from 'services/nurseries/models'
import { PaginationDetails } from 'constants/pagination'

import { EmptyState, Flex, InfiniteScroll, Masonry, Page, SearchBar, Spinner } from 'components'

import i18n from 'translations'

import NurseryItem from './components/NurseryItem'

interface OrganizationDashboardViewProps {
  authAccessMap: {
    module: {
      NurseryAdd: boolean
    }
  }
  isLoading: boolean
  nurseries: Nursery[]
  onEditClick: (nurseryId: number) => void
  onSearchChange: (search: string) => void
  pagination: PaginationDetails
}

const OrganizationDashboardView: React.FC<OrganizationDashboardViewProps> = ({
  authAccessMap,
  isLoading,
  nurseries,
  onEditClick,
  onSearchChange,
  pagination,
}) => {
  const renderContent = () => {
    if (isLoading && 1 === pagination.page) {
      return <Spinner />
    }

    if (!nurseries?.length) {
      return (
        <EmptyState icon="nursery" text1={i18n.t('module:Dashboard:Organization:nothingFound')} />
      )
    }

    return (
      <InfiniteScroll
        dataLength={nurseries ? nurseries.length : 0}
        hasMore={pagination.page < pagination.pageCount}
        isFetching={isLoading}
        next={() => pagination.onPageChange(+pagination.page + 1)}
      >
        <Flex justifyContent="center">
          <Masonry options={{ fitWidth: true }}>
            {_.map(nurseries, (nursery) => (
              <NurseryItem
                authAccessMap={authAccessMap}
                nursery={nursery}
                onEditClick={onEditClick}
              />
            ))}
          </Masonry>
        </Flex>
      </InfiniteScroll>
    )
  }

  return (
    <Page isLoading={isLoading && 1 === pagination.page}>
      <SearchBar placeholder={i18n.t('module:Dashboard:Organization:searchBarPlaceholder')} onChange={onSearchChange} />
      <Page.Content>{renderContent()}</Page.Content>
    </Page>
  )
}

export default OrganizationDashboardView
