import { generateBasicActions } from 'utils/service'

import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_VIEWINGS_WIDGET_STATISTICS,
  GET_VIEWINGS_WIDGET_STATISTICS,
  GET_VIEWINGS_WIDGET_STATISTICS_FAILED,
  GET_VIEWINGS_WIDGET_STATISTICS_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ viewingsStatisticsWidgetApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_VIEWINGS_WIDGET_STATISTICS_FAILED,
      init: GET_VIEWINGS_WIDGET_STATISTICS,
      success: GET_VIEWINGS_WIDGET_STATISTICS_SUCCESS,
    },
    options,
    service: viewingsStatisticsWidgetApiClient,
    serviceMethod: 'get',
  })
)

export const clear = () => ({
  type: CLEAR_VIEWINGS_WIDGET_STATISTICS,
})
