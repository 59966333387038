import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { BasicModalProps } from 'modals'
import { EnquiryShowAround } from 'services/legacy/enquiries/models'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withEnquiryViewingsService, withEnquiryViewingsServiceProps } from 'services/legacy/enquiries'
import { withRouter, withRouterProps } from 'services/router'
import appSelectors from 'services/app/selectors'

import ViewingEditModalView from './ViewingEditModalView'

const mapState = (state: RootState, { enquiryViewingsSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(enquiryViewingsSingleState),
  isFetching: appSelectors.getIsFetching(enquiryViewingsSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export interface EnquiryViewingEditModalProps {
  onSubmitSuccess: () => void
  showAroundData: EnquiryShowAround
}

type ViewingEditModalContainerProps = EnquiryViewingEditModalProps
  & PropsFromRedux
  & BasicModalProps
  & withModalServiceProps
  & withEnquiryViewingsServiceProps
  & withRouterProps

const ViewingEditModalContainer: React.FC<ViewingEditModalContainerProps> = ({
  enquiryViewingsSelectors,
  errorMessages,
  hideModal,
  isFetching,
  onSubmitSuccess,
  showAroundData,
}) => {
  const showAroundInitialValues = useMemo(() => (
    enquiryViewingsSelectors.getEnquiryViewingsSingleInitialValues(showAroundData)
  ), [showAroundData])
  const { enquiry, id: showAroundId } = showAroundData || {}
  const { id: enquiryId } = enquiry || {}

  const handleSubmitSuccess = () => {
    if (onSubmitSuccess) {
      onSubmitSuccess()
    }

    hideModal()
  }

  return (
    <ViewingEditModalView
      enquiryId={enquiryId}
      errorMessages={errorMessages}
      isFetching={isFetching}
      showAroundId={showAroundId}
      showAroundInitialValues={showAroundInitialValues}
      onCancelClick={hideModal}
      onSubmitSuccess={handleSubmitSuccess}
    />
  )
}

const enhance = compose(
  withRouter,
  withModalService,
  withEnquiryViewingsService,
  connector,
)

export default enhance(ViewingEditModalContainer)
