import styled from 'styled-components'

import { Link } from 'react-router'

import { getBrandingColor } from 'utils/branding'

import { StyledAvatarWithText } from 'components/GroupAvatars/GroupAvatarsStyled'

export const StyledContainer = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
`

export const StyledLinkContainer = styled(Link)`
  padding: 12px 24px;
  display: flex;
  align-items: center;
`

export const StyledDetail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  gap: 3px;
  padding-top: 1px;
`

export const StyledAvatarContainer = styled.div`
  ${StyledAvatarWithText} {
    color: ${getBrandingColor('tertiary-color')};
    font-size: 12px;
    position: absolute;

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
      border: 1px solid #E3E3E3;
    }
  }
`
