import Widget from './Widget'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import WidgetList from './WidgetList'
import WidgetPart from './WidgetPart'
import WidgetEmptyState from './WidgetEmptyState'

Widget.Header = WidgetHeader
Widget.Content = WidgetContent
Widget.List = WidgetList
Widget.Part = WidgetPart
Widget.EmptyState = WidgetEmptyState

export default Widget
