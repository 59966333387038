import React, { useEffect, useState } from 'react'
import TimeWidgetView from './TimeWidgetView'

const TimeWidgetContainer = () => {
  const [date, setDate] = useState<Date>(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return <TimeWidgetView date={date} />
}

export default TimeWidgetContainer
