import _ from 'lodash'

import React from 'react'

import { StartersAndLeaversWidgetRecord, StartersAndLeaversWidgetType } from 'services/startersAndLeaversWidget/models'
import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Flex, Icon, InfiniteScroll, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { formatDate } from '../WidgetHelpers'

interface StartersAndLeaversWidgetViewProps {
  isFetching: boolean
  onLoadNextPage: () => void
  page: number
  pageCount: number
  startersAndLeavers: StartersAndLeaversWidgetRecord[]
}

const StartersAndLeaversWidgetView: React.FC<StartersAndLeaversWidgetViewProps> = ({
  isFetching,
  onLoadNextPage,
  page,
  pageCount,
  startersAndLeavers,
}) => {
  const renderListRow = (record: StartersAndLeaversWidgetRecord) => {
    const { child, class: nurseryClass, date, type } = record

    return (
      <Widget.List.Row
        key={child.id}
        leftBottomColumn={(
          <Flex alignItems="center" gap="8px">
            <Icon
              color={StartersAndLeaversWidgetType.STARTER === type ? NEUTRAL_COLOURS.SUCCESS : FLAG_COLOURS.WARNING}
              height={14}
              icon={StartersAndLeaversWidgetType.STARTER === type ? 'starter' : 'leaver'}
            />
            <Flex>
              <Typography fontSize={14} nowrap>
                {formatDate(date)}
              </Typography>
            </Flex>
            <Flex flex="1">
              <Typography color={FLAG_COLOURS.INFO} fontSize={14} ellipsis>
                {nurseryClass.name}
              </Typography>
            </Flex>
          </Flex>
        )}
        leftTopColumn={child.name}
        persons={[child]}
        to={generateRoute('CHILDREN.CHILD', { childId: child.id })}
      />
    )
  }

  const renderContent = () => {
    if (!startersAndLeavers?.length) {
      return (
        <Widget.EmptyState
          icon="leavers"
          text1={i18n.t('module:Dashboard:Widgets:StartersAndLeaversWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:StartersAndLeaversWidget:emptyStateText2')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={startersAndLeavers?.length || 0}
        hasMore={pageCount > page}
        isFetching={isFetching}
        next={onLoadNextPage}
      >
        <Widget.List>
          {_.map(startersAndLeavers, renderListRow)}
        </Widget.List>
      </InfiniteScroll>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:StartersAndLeaversWidget:title')} />
      <Widget.Content isLoading={isFetching}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default StartersAndLeaversWidgetView
