import React from 'react'

import { Nursery } from 'services/nurseries/models'
import { PaginationDetails } from 'constants/pagination'

import { generateRoute } from 'utils/routing'

import { Section } from 'components'
import NurseriesListTable from 'module/Settings/components/NurseriesListTable'

import i18n from 'translations'

interface SessionsListViewProps {
  isFetching: boolean
  onPageChange: (page: any) => any
  pagination: PaginationDetails
  records: Nursery[]
}

const SessionsListView: React.FC<SessionsListViewProps> = ({
  isFetching,
  onPageChange,
  pagination,
  records,
}) => (
  <Section title={i18n.t('module:Settings:Sessions:List:sessions')}>
    <NurseriesListTable
      {...pagination}
      getActionRouting={({ id }) => generateRoute('SETTINGS.SESSIONS.NURSERY', { nurseryId: id })}
      isFetching={isFetching}
      noDataIcon="nursery"
      nurseries={records}
      onPageChange={onPageChange}
    />
  </Section>

)

export default SessionsListView
