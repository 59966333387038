import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledCustomButton = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
  background: ${NEUTRAL_COLOURS.WHITE};
  padding: 16px 24px;
  grid-template-columns: 64px auto auto 1fr;
  gap: 16px;
`

export const StyledIconWrapper = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;

  ${({ $background }) => $background && `background: ${$background}`}
`
