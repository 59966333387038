import styled from 'styled-components'
import { Property } from 'csstype'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledContainerProps {
  $marginBottom?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;

  ${({ $marginBottom }) => $marginBottom && `
    margin-bottom: 20px;
  `}
`

interface StyledHeaderProps {
  $borderBottom?: boolean
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  padding: 10px 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #BBB;
  margin-bottom: 5px;
  
  ${({ $borderBottom }) => $borderBottom && `
    padding: 10px 0 20px;
    border-bottom: 1px dashed #DDD;
    margin-bottom: 5px;
  `}
`

export const StyledFooter = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

interface StyledItemProps {
  $center?: boolean
  $padding?: Property.Padding
  $proportion?: Property.GridTemplateColumns
  $withoutBorder?: boolean
}

export const StyledItem = styled.div<StyledItemProps>`
  padding: 20px 0;
  margin: 0 10px;
  display: grid;
  grid-template-columns: 180px auto;
  grid-column-gap: 10px;
  flex-direction: row;
  
  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}

  ${({ $proportion }) => $proportion && `
    grid-template-columns: ${$proportion};
  `}

  ${({ $center }) => $center && `
    justify-content: center;
  `}

  &:not(:last-child) {
    border-bottom: ${({ $withoutBorder }) => ($withoutBorder ? 'none' : '1px dashed #DDD')};
  }

  @media(max-width: 580px) {
    flex-direction: column;
    grid-template-columns: auto;
  }
`

export const StyledLabel = styled.div`
  @media(max-width: 580px) {
    margin-bottom: 5px;
  }
`

interface StyledValueProps {
  $valueAlign?: Property.AlignSelf
}

export const StyledValue = styled.div<StyledValueProps>`
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $valueAlign }) => $valueAlign && `
    align-self: ${$valueAlign};
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin-left: 3px;
  }
`

interface StyledSectionProps {
  $labelWidth?: boolean
}

export const StyledSection = styled.div<StyledSectionProps>`
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 10px 20px;
  border-radius: ${layout.borderRadius};
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ $labelWidth }) => $labelWidth && `
    ${StyledLabel} {
      min-width: ${$labelWidth}px;
      width: ${$labelWidth}px;
    }
  `}
`

export const StyledButton = styled.div`
  margin-left: 10px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin-left: 0;
    margin-top: 5px;
  }
`
