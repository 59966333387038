import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainerWrapper = styled.div`
  height: 60px;
  min-height: 60px;
`

interface StyledContainerProps {
  $noBackground?: boolean
  $standard?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  height: 60px;
  background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  left: 0;
  right: 0;

  ${({ $noBackground }) => $noBackground && `
    background: transparent;
  `}

  @media(max-width: 700px) {
    position: relative;
    padding: 0 20px;
  }

  ${({ $standard }) => $standard && `
    position: relative;
    padding: 0;
  `}
`

interface StyledInputContainerProps {
  $standard?: boolean
}

export const StyledInputContainer = styled.div<StyledInputContainerProps>`
  max-width: 500px;
  height: 40px;
  flex: 1;
  border-radius: 20.5px;
  position: relative;

  ${({ $standard }) => $standard && `
    max-width: none;
  `}
`

export const StyledIconContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`

export const StyledClearIconContainer = styled.div`
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  color: #989898;
  padding-left: 45px;
  padding-right: 20px;
  line-height: 40px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  border-radius: 20.5px;

  &::placeholder {
    color: #969696;
  }
`
