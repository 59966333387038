import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  GET_NEEDS_ATTENTION_WIDGET,
  GET_NEEDS_ATTENTION_WIDGET_FAILED,
  GET_NEEDS_ATTENTION_WIDGET_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface NeedsAttentionWidgetSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    itemFailed: GET_NEEDS_ATTENTION_WIDGET_FAILED,
    itemInit: GET_NEEDS_ATTENTION_WIDGET,
    itemSuccess: GET_NEEDS_ATTENTION_WIDGET_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
