import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { FEATURE_FLAGS, FeatureFlags, ROLES, Roles } from 'constants/security'

import {
  hasAccessThisRoleAndAbove,
  hasFullAccessExceptFinAdmin,
  hasFullAccessExceptNonTeaching,
  hasFullAccessExceptTeachers,
} from 'services/security/user/selectors'
import { getAuthNursery } from 'services/security/selectors'

const getUsers = (state: RootState) => state.users

const getUsersSettings = createSelector([getUsers], (state) => state.settings)

export const getUserSettings = createSelector([getUsersSettings], (state) => state.data)

export const getAllNurseryWidgetSettings = createSelector([getUserSettings], (state) => (
  state && state.widgetSettings && state.widgetSettings.nurseriesSettings
))

export const getSpecificNurseryWidgetSettings = createSelector(
  [getAllNurseryWidgetSettings, getAuthNursery],
  (nurseryWidgetSettings, nursery) => {
    const widgetSettings = _.find(nurseryWidgetSettings, ({ id }) => id === nursery.id)
    const { settings } = widgetSettings || {}

    return settings
  },
)

export const getNurserySelectedWidgets = createSelector(
  [getSpecificNurseryWidgetSettings],
  (nurseryWidgetSettings) => {
    const WidgetList = require('module/Dashboard/Widgets').default

    if (!nurseryWidgetSettings) {
      return _.values(WidgetList)
    }

    const selectedWidgetNames = _.filter(nurseryWidgetSettings, ({ enabled }) => enabled)
    const selectedWidgets = _.map(selectedWidgetNames, ({ name }) => _.find(WidgetList, ({ id }) => name === id))
    const missingWidgets = _.filter(WidgetList, ({ id }) => (
      !_.find(selectedWidgetNames, ({ name }) => name === id)
      && !_.find(nurseryWidgetSettings, ({ name }) => name === id)
    )) || []

    // remove missing(undefined) widgets from selectedWidgets
    return [
      ..._.filter(selectedWidgets, (widget) => !!widget),
      ...missingWidgets,
    ]
  },
)

export const getUpdatedNurseryWidgetsValuesForm = (values, userSettings, nurseryId) => {
  const nurseriesSettings = _.clone(userSettings.widgetSettings.nurseriesSettings)
  _.remove(nurseriesSettings, ({ id }) => id === nurseryId)

  return {
    userSettings: {
      ...userSettings,
      widgetSettings: {
        ...userSettings.widgetSettings,
        nurseriesSettings: [
          ...nurseriesSettings,
          {
            id: nurseryId,
            settings: _.map(values, ({ enabled, id }) => ({
              enabled: !!enabled,
              name: id,
            })),
          },
        ],
      },
    },
  }
}

export const getWidgetsPermission = (state) => {
  // NOTE: Importing this in header causes destroy of application
  const auth = require('utils/auth').default
  const WidgetList = require('module/Dashboard/Widgets').default

  return ({
    [WidgetList.AttendanceWidget.id]: hasFullAccessExceptNonTeaching(state),
    [WidgetList.ChildrenSearchWidget.id]: hasFullAccessExceptFinAdmin(state),
    [WidgetList.DraftInvoicesWidget.id]: (
      auth.SELECTORS.getIsAuthorised(state, {
        flags: [
          FEATURE_FLAGS.FINANCE_MVP,
          FEATURE_FLAGS.FINANCE_AUTOMATION,
        ],
        roles: [
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
          ROLES.SUPER_ADMIN,
        ],
      })
    ),
    [WidgetList.MonthlyIncomeWidget.id]: (
      auth.SELECTORS.getIsAuthorised(state, {
        flags: [
          FEATURE_FLAGS.FINANCE_MVP,
          FEATURE_FLAGS.FINANCE_AUTOMATION,
        ],
        roles: [
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
          ROLES.SUPER_ADMIN,
        ],
      })
    ),
    [WidgetList.NextStepsWidget.id]: hasFullAccessExceptFinAdmin(state),
    [WidgetList.ObservationDiaryButtonsWidget.id]: hasFullAccessExceptTeachers(state),
    [WidgetList.ObservationsWidget.id]: hasFullAccessExceptFinAdmin(state),
    [WidgetList.TimeWidget.id]: true,
    [WidgetList.TodayAllergiesWidget.id]: hasFullAccessExceptFinAdmin(state),
    [WidgetList.TopOutstandingAccountsWidget.id]: (
      auth.SELECTORS.getIsAuthorised(state, {
        flags: [
          FEATURE_FLAGS.FINANCE_MVP,
          FEATURE_FLAGS.FINANCE_AUTOMATION,
        ],
        roles: [
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
          ROLES.SUPER_ADMIN,
        ],
      })
    ),
    [WidgetList.BirthdaysWidget.id]: hasFullAccessExceptNonTeaching(state),
    [WidgetList.ExtraSessionRequestsWidget.id]: hasAccessThisRoleAndAbove(state, {
      flags: [FeatureFlags.REQUESTING_EXTRA_SESSIONS],
      role: Roles.DEPUTY_MANAGER,
    }),
    [WidgetList.AccidentsIncidentsSignaturesWidget.id]: hasFullAccessExceptFinAdmin(state),
    [WidgetList.StartersAndLeaversWidget.id]: hasAccessThisRoleAndAbove(state, {
      flags: [FeatureFlags.LJ_REPORTS],
      role: Roles.DEPUTY_MANAGER,
    }),
    [WidgetList.ViewingsWidget.id]: hasAccessThisRoleAndAbove(state, {
      flags: [FeatureFlags.ENQUIRIES],
      role: Roles.DEPUTY_MANAGER,
    }),
    [WidgetList.NeedsAttentionWidget.id]: hasFullAccessExceptNonTeaching(state),
    [WidgetList.AgedDebtWidget.id]: (
      auth.SELECTORS.getIsAuthorised(state, {
        flags: [
          FEATURE_FLAGS.FINANCE_AUTOMATION,
        ],
        roles: [
          ROLES.SUPER_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
        ],
      })
    ),
    [WidgetList.AnnualRevenueWidget.id]: (
      auth.SELECTORS.getIsAuthorised(state, {
        flags: [
          FEATURE_FLAGS.FINANCE_AUTOMATION,
        ],
        roles: [
          ROLES.SUPER_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
        ],
      })
    ),
    [WidgetList.StaffingOverviewWidget.id]: hasAccessThisRoleAndAbove(state, {
      flags: [FeatureFlags.STAFF_REGISTER],
      role: Roles.TEACHER,
    }),
  })
}

export const getFullWidgetsList = createSelector(
  [getSpecificNurseryWidgetSettings, getWidgetsPermission],
  (nurseryWidgetSettings, authAccessMap) => {
    const WidgetList = require('module/Dashboard/Widgets').default

    return _.sortBy(
      _.map(
        _.filter(WidgetList, ({ id }) => authAccessMap[id]),
        (widget) => {
          const { id } = widget

          const widgetItem = _.find(nurseryWidgetSettings, (item) => item.name === id)
          let itsEnabled = true

          if (nurseryWidgetSettings) {
            if (_.isUndefined(widgetItem)) {
              itsEnabled = true
            } else {
              itsEnabled = _.get(_.find(nurseryWidgetSettings, (item) => item.name === id), 'enabled', false)
            }
          }

          return ({
            draggableId: id,
            enabled: itsEnabled,
            id,
            // @ts-ignore
            order: _.findIndex(nurseryWidgetSettings, (item) => item.name === id),
            widget,
          })
        },
      ),
      ({ order }) => (-1 === order ? Number.MAX_SAFE_INTEGER : order),
    )
  },
)

