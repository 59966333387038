import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withUsersService, withUsersServiceProps } from 'services/users'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouter, withRouterProps } from 'services/router'
import appSelectors from 'services/app/selectors'
import * as securitySelectors from 'services/security/selectors'
import * as usersSelectors from 'services/users/selectors'

import NurseryDashboardView from './NurseryDashboardView'

const mapState = (state, { usersSettingsState }) => ({
  authAccessMap: {
    section: usersSelectors.getWidgetsPermission(state),
  },
  currentUser: securitySelectors.getAuthProfile(state),
  isFetching: appSelectors.getIsFetching(usersSettingsState),
  isOffline: appSelectors.getAppIsOffline(state),
  selectedWidgets: usersSelectors.getNurserySelectedWidgets(state),
})

const connector = connect(mapState)

export type PropsFromRedux = ConnectedProps<typeof connector>

type NurseryDashboardContainerProps = PropsFromRedux
  & withAppServiceProps
  & withRouterProps
  & withUsersServiceProps
  & withSecurityServiceProps

const NurseryDashboardContainer: React.FC<NurseryDashboardContainerProps> = ({
  authAccessMap,
  currentUser,
  isFetching,
  isOffline,
  navigate,
  selectedWidgets,
  usersActions,
}) => {
  useEffect(() => {
    if (currentUser?.user?.id) {
      usersActions.getUserSettings(currentUser.user.id)
    } else {
      navigate(generateRoute('AUTHENTICATION.LOGIN'))
    }

    return () => {
      usersActions.clearUserSettings()
    }
  }, [])

  return (
    <NurseryDashboardView
      authAccessMap={authAccessMap}
      isFetching={isFetching}
      isOffline={isOffline}
      selectedWidgets={selectedWidgets}
    />
  )
}

const enhance = compose(
  withAppService,
  withUsersService,
  withSecurityService,
  withRouter,
  connector,
)

export default enhance(NurseryDashboardContainer)
