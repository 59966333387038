import styled from 'styled-components'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledItemProps {
  $yellow?: boolean
}

export const StyledItem = styled.div<StyledItemProps>`
  display: grid;
  grid-template-columns: 40px 1fr 24px;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 16px;
  background: ${getBrandingColor('quinary-color')};

  ${({ $yellow }) => $yellow && `
    background: ${FLAG_COLOURS.WARNING_LIGHT};
  `}
`

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${NEUTRAL_COLOURS.WHITE};
`
