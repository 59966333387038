import React from 'react'
import { PieTooltipProps } from '@nivo/pie'

import { Currency } from 'components'

import i18n from 'translations'

import { DataItem } from './PieChart'
import {
  StyledTooltipContainer,
  StyledTooltipTitle,
  StyledTooltipTitleContainer,
  StyledTooltipValue,
} from './PieChartStyled'

const PieChartTooltip: React.FC<PieTooltipProps<DataItem>> = ({ datum }) => {
  const { color, data } = datum
  const { id, isCurrencyValue, percentage, value } = data

  const renderValue = (): React.ReactNode => {
    if (isCurrencyValue) {
      return (
        <Currency value={value} sameSize />
      )
    }

    return <strong>{value}</strong>
  }

  const renderValueContent = (): React.ReactNode => {
    if (value && percentage) {
      return (
        <React.Fragment>
          {i18n.t('global:Total')}
          {': '}
          <StyledTooltipValue>{renderValue()}</StyledTooltipValue>
          <br />
          {i18n.t('global:Percentage')}
          {': '}
          <StyledTooltipValue>{`${percentage}%`}</StyledTooltipValue>
        </React.Fragment>
      )
    }

    return <strong>{renderValue()}</strong>
  }

  return (
    <StyledTooltipContainer>
      <StyledTooltipTitleContainer>
        <svg height={10} viewBox="0 0 10 10" width={10}>
          <circle cx={5} cy={5} fill={color} r={5} />
        </svg>
        <StyledTooltipTitle>{id}</StyledTooltipTitle>
      </StyledTooltipTitleContainer>
      {renderValueContent()}
    </StyledTooltipContainer>
  )
}

export default PieChartTooltip
