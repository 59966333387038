import styled from 'styled-components'

import browser from 'browser-detect'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { BROWSERS } from 'constants/browsers'

import { StyledContainer as StyledSearchBarContainer } from 'components/SearchBar/SearchBarStyled'

import { ITEM_HEIGHT } from './Conversations'

interface StyledContainerProps {
  $height?: number
  $itemsLength?: number
  $width?: number
}

interface StyledListItemContentContainerProps {
  $isUnread?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: ${({ $width }) => $width}px;
  min-height: 212px;
  max-height: calc(100vh - 100px);
  padding: 16px 10px;
  display: grid;
  grid-template-rows: auto 1fr;
  
  ${({ $height, $itemsLength }) => $itemsLength && (() => {
    const totalHeight = $itemsLength * ITEM_HEIGHT
    const viewPortHeight = $height - 150

    if (totalHeight > viewPortHeight) {
      return `
        min-height: ${viewPortHeight}px;
      `
    }

    return `
      min-height: ${totalHeight + 129}px;
    `
  })()}
  
  @media(max-width: 450px) {
    width: 320px;    
  }  
`

export const StyledHeader = styled.header`
  display: grid;
  grid-row-gap: 16px;
`

export const StyledTitle = styled.div`
  display: flex;
  padding: 0 6px;
  justify-content: space-between;
  min-height: 32px;
  align-items: center;
`

export const StyledActionContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;  
`

export const StyledItemActionContainer = styled.div`
  display: flex;  
  align-items: center;  
`

export const StyledSearchWrapper = styled.div`
  display: grid;
  position: relative;
  
  ${StyledSearchBarContainer} {
    padding: 0;
  }
`

export const StyledListContainer = styled.div`
  height: 100%;
  flex: 1;
  
  .ReactVirtualized__List {
    padding-right: 5px;
  }
  
  ${browser().name === BROWSERS.SAFARI && `
    height: calc(100vh - 80px - 152px);
    flex: 1 1 auto;
  `}

  & div {
    /* stylelint-disable */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 8px;
      background-color: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    }
    /* stylelint-enable */
  }
`

export const StyledListItemWrapperContainer = styled.div`
  display: flex;  
  border-radius: 8px;   
  align-items: flex-start;      

  &:hover {
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};    
    height: 77px;
  }
  
  ${({ isActive }) => isActive && `
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  `}
`

export const StyledListItemContainer = styled(Link)`
  display: grid;
  padding: 12px;
  border-radius: 8px; 
  transition: .4s;
  grid-template-columns: 48px auto;
  grid-column-gap: 16px;
  margin: 2px 0;  
  width: 100%;
`

export const StyledListItemContentContainer = styled.div<StyledListItemContentContainerProps>`
  display: grid;
  color: ${NEUTRAL_COLOURS.BASIC};
  align-items: center;
  flex-direction: column;
  padding: 3px 0;
  row-gap: 3px;
  grid-template-columns: 1fr auto;
  opacity: ${({ $isUnread }) => ($isUnread ? 1 : 0.6)}
`

export const StyledListItemContent = styled.div`
  display: flex;
`

export const StyledListItemInfo = styled.div`
  display: grid;
  grid-gap: 5px;
  height: 45px;
`

export const StyledIconWrapper = styled.div`
  color: ${NEUTRAL_COLOURS.WHITE};
  display: grid;
  justify-content: flex-end;  
`
