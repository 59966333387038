export enum BirthdayWidgetType {
  CHILD = 'child',
  STAFF = 'staff',
}

export interface BirthdayWidgetRecord {
  age: number
  birthDate: string
  name: string
  photo: string
  type: BirthdayWidgetType
}
