import _ from 'lodash'

import React from 'react'

import { Nursery } from 'services/nurseries/models'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Button, Flex, Hyperlink, Icon, Space, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import WidgetList from 'module/Dashboard/components/Widget/WidgetList'
import { StyledBox } from './NurseryItemStyled'

interface NurseryItemProps {
  authAccessMap: {
    module: {
      NurseryAdd: boolean
    }
  }
  nursery: Nursery
  onEditClick: (id: number) => void
}

const NurseryItem = ({ authAccessMap, nursery, onEditClick }: NurseryItemProps) => {
  const {
    activeChildrenCount,
    activeStaffCount,
    address,
    archived,
    id,
    name,
    postCode,
    primaryContact,
    telephone,
  } = nursery
  const { name: contactName, roles } = primaryContact || {}
  const publicUrl = getNurseryPublicUrl(nursery)

  const handleEditClick = () => {
    onEditClick(id)
  }

  const renderLabel = () => (
    <Hyperlink target="_blank" to={publicUrl} width="100%">
      <Flex gap="10px">
        <Flex flex="1">
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={20} fontWeight="600">
            {`${name}${archived ? ` (${i18n.t('global:disabled')})` : ''}`}
          </Typography>
        </Flex>
        <Flex margin="3px 0 0">
          <Icon height={16} icon="chevron-right" />
        </Flex>
      </Flex>
    </Hyperlink>
  )

  const renderStatsWrapper = (count: number, label: string) => (
    <Flex flex="1">
      <Flex alignItems="center" gap="8px">
        <Typography fontSize={24} fontWeight="600">
          {count}
        </Typography>
        <Typography fontSize={14}>
          {label}
        </Typography>
      </Flex>
    </Flex>
  )

  return (
    <Flex flexDirection="column" key={id} padding="0 0 20px">
      <Widget height="min-content" width="340px">
        <Widget.Header label={renderLabel()} />
        <Widget.Content>
          <Flex>
            <Flex flex="1" padding="6px 0 0">
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                {address || postCode ? (
                  <React.Fragment>
                    {address}
                    {address && postCode ? <br /> : null}
                    {postCode}
                  </React.Fragment>
                ) : i18n.t('module:Dashboard:NurseryItem:noAddressProvided')}
              </Typography>
            </Flex>
            {authAccessMap.module.NurseryAdd && (
              <Flex margin="0 -18px 0 0">
                <Button
                  hierarchy="tertiary"
                  label={i18n.t('global:Edit')}
                  negativeMargins
                  negativeVerticalMargins
                  onClick={handleEditClick}
                />
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center" gap="6px" margin="11px 0">
            <Icon color={NEUTRAL_COLOURS.GRAY} height={14} icon="call" />
            <Typography color={NEUTRAL_COLOURS.BASIC} fontWeight="600">
              {telephone}
            </Typography>
          </Flex>
          <StyledBox $quinary>
            {primaryContact ? (
              <WidgetList.Row
                leftBottomColumn={(
                  <Typography fontSize={14} ellipsis>
                    {_.map(roles, (key) => i18n.t(`global:roles:${key}`)).join(', ')}
                  </Typography>
                )}
                leftTopColumn={contactName}
                persons={[primaryContact]}
              />
            ) : (
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                {i18n.t('module:Dashboard:NurseryItem:noPrimaryContact')}
              </Typography>
            )}
          </StyledBox>
          <StyledBox>
            <Flex alignItems="center" gap="10px">
              {renderStatsWrapper(activeChildrenCount, i18n.t('global:Children'))}
              {renderStatsWrapper(activeStaffCount, i18n.t('global:Staff'))}
            </Flex>
          </StyledBox>
          <Space margin="0 0 15px" />
        </Widget.Content>
      </Widget>
    </Flex>
  )
}

export default NurseryItem
