import React, { PropsWithChildren } from 'react'
import { Motion, spring } from 'react-motion'

import { getBrandingColor } from 'utils/branding'

import {
  StyledArcContainer,
  StyledBackgroundPath,
  StyledContent,
  StyledG,
  StyledProgressPath,
  StyledSvg,
} from './ArcStyled'

interface ArcProps {
  background?: string
  color?: string
  content: string | React.ReactNode
  contentFontSize?: number
  size?: number
  total: number
  value: number
}

const Arc: React.FC<PropsWithChildren<ArcProps>> = ({
  background = '#D7D7D8',
  color = getBrandingColor('tertiary-color'),
  content,
  contentFontSize,
  size = 50,
  total,
  value,
}) => {
  const arcNull = 396
  const arcFull = 792

  const rawPerc = !value && !total ? 0 : (value / total)
  const arcBase = arcFull - arcNull
  const arcProgress = rawPerc * arcBase
  const arcFinalValue = arcNull + arcProgress > arcFull ? arcFull : arcNull + arcProgress

  return (
    <StyledArcContainer $size={size}>
      <StyledSvg
        $size={size}
        viewBox="0 0 44.502914 44.502915"
      >
        <StyledG>
          <StyledBackgroundPath
            $strokeBackground={background}
            d="M 150.4,152.4 C 166.1,137.2 175.9,115.9 175.9,92.3 175.9,46.1 138.5,8.7 92.3,8.7
               46.1,8.7 8.7,46.1 8.7,92.3 c 0,23.1 9.4,44 24.5,59.1"
          />
          <Motion
            defaultStyle={{ strokeDashoffset: arcNull }}
            style={{
              strokeDashoffset: spring(arcFinalValue, {
                damping: 40,
                stiffness: 90,
              }),
            }}
          >
            {(arcValue) => (
              <StyledProgressPath
                $strokeBackground={color}
                d="M 150.4,151.4 C 166.1,136.2 175.9,114.9 175.9,91.3 175.9,45.1 138.5,7.7 92.3,7.7
                   46.1,7.7 8.7,45.1 8.7,91.3 c 0,23.1 9.4,44 24.5,59.1"
                style={{ strokeDashoffset: arcValue.strokeDashoffset }}
              />
            )}
          </Motion>
        </StyledG>
      </StyledSvg>
      <StyledContent $fontSize={contentFontSize}>
        {content}
      </StyledContent>
    </StyledArcContainer>
  )
}

export default Arc
