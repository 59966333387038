import _ from 'lodash'

import React from 'react'

import { Child } from 'services/child/models'

import { generateRoute } from 'utils/routing'

import { Currency, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

interface TopOutstandingAccountsWidgetViewProps {
  childrenList: Child[]
  isLoading: boolean
  totalOutstanding: number
}

const TopOutstandingAccountsWidgetView: React.FC<TopOutstandingAccountsWidgetViewProps> = ({
  childrenList,
  isLoading,
  totalOutstanding,
}) => {
  const renderListItem = (child) => {
    const { balance, id, name } = child

    const to = generateRoute('CHILDREN.CHILD.FINANCE.TRANSACTIONS', { childId: id })

    return (
      <Widget.List.Row
        key={id}
        leftTopColumn={name}
        persons={[child]}
        rightColumn={(
          <Typography color={FLAG_COLOURS.ERROR}>
            <Currency value={balance ? balance * -1 : 0} sameSize />
          </Typography>
        )}
        to={to}
      />
    )
  }

  const renderContent = () => {
    if (!childrenList?.length) {
      return (
        <Widget.EmptyState
          icon="invoice-paid"
          text1={i18n.t('module:Dashboard:Widgets:TopOutstandingAccountsWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:TopOutstandingAccountsWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography fontSize={28} margin="2px 0 8px" bold>
          <Currency value={totalOutstanding} sameSize />
        </Typography>
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="4px 0" bold>
          {i18n.t('module:Dashboard:Widgets:TopOutstandingAccountsWidget:largestBalances')}
        </Typography>
        <Widget.List>
          {_.map(childrenList, renderListItem)}
        </Widget.List>
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:TopOutstandingAccountsWidget:title')} />
      <Widget.Content isLoading={isLoading}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default TopOutstandingAccountsWidgetView
