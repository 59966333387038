import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { BasicListResponse } from 'constants/service'
import { NextStep } from 'services/legacy/nextSteps/models'
import { RootState } from 'core/reducers'
import { NEXT_STEPS_FILTERS } from 'services/legacy/nextSteps/constants'

import * as nextStepsSelectors from 'services/legacy/nextSteps/selectors'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNextStepsService, withNextStepsServiceProps } from 'services/legacy/nextSteps'
import { withSecurityService, withSecurityServiceProps } from 'services/security'

import NextStepsWidgetView from './NextStepsWidgetView'

const mapState = (state: RootState, { securitySelectors }) => ({
  user: securitySelectors.getAuthUser(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type NextStepsWidgetContainerProps = PropsFromRedux
  & withAppServiceProps
  & withNextStepsServiceProps
  & withSecurityServiceProps

const NEXT_STEPS_GROUPS = {
  read: [
    'child',
    'childObservation.child',
    'childObservation.observation',
    'frameworkArea',
    'nextMontessoriActivity',
    'nextMontessoriActivity.childObservation',
    'nextStep',
    'nextStep.childObservation',
    'nextStep.frameworkAreas',
    'observation',
  ],
}

const NextStepsWidgetContainer: React.FC<NextStepsWidgetContainerProps> = ({ nextStepsActions, user }) => {
  const [nextSteps, setNextSteps] = useState<NextStep[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const handleGetNextStepsSuccess = (response: BasicListResponse<NextStep>) => {
    setIsFetching(false)
    setNextSteps(response.data)
  }

  const fetch = () => {
    const criteria = nextStepsSelectors.getNextStepsListCriteria({
      isChildArchived: false,
      keyPerson: user.id,
      type: NEXT_STEPS_FILTERS.WORKING_ON,
    })

    return nextStepsActions.list({
      onSuccess: handleGetNextStepsSuccess,
      onlyData: true,
      params: {
        criteria,
        groups: NEXT_STEPS_GROUPS,
        page: 0,
      },
    })
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <NextStepsWidgetView
      isLoading={isFetching}
      nextSteps={nextSteps}
    />
  )
}

const enhance = compose(
  withAppService,
  withNextStepsService,
  withSecurityService,
  connector,
)

export default enhance(NextStepsWidgetContainer)
