import styled from 'styled-components'

export const StyledLegend = styled.div`
  display: grid;
  grid-row-gap: 5px;
  align-items: center;
  flex-direction: column;
`

export const StyledLegendItem = styled.div`
  display: grid;
  grid-template-columns: 30px min-content;
  align-items: center;
  grid-column-gap: 10px;
  text-align: left;
  white-space: nowrap;
`

interface StyledLineProps {
  $background: string
}

export const StyledLine = styled.div<StyledLineProps>`
  width: 25px;
  height: 4px;
  border-radius: 3px;
  
  ${({ $background }) => $background && `
    background: ${$background};
  `}
`

