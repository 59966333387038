import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import { BirthdayWidgetListState } from './constants'

export interface withBirthdayWidgetServiceProps {
  birthdayWidgetActions: typeof actions
  birthdayWidgetListState: BirthdayWidgetListState
}

const withBirthdayWidgetService = <P extends withBirthdayWidgetServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ birthdayWidget }) => ({ birthdayWidget })

  const mapDispatch = injectActions('birthdayWidgetActions', actions)

  const Component = ({ birthdayWidget, birthdayWidgetActions, ...others }) => (
    <WrappedComponent
      birthdayWidgetActions={birthdayWidgetActions}
      birthdayWidgetListState={birthdayWidget.list}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withBirthdayWidgetService
