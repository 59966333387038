import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as enquiryViewingsSelectors from './selectors'

export interface withEnquiryViewingsServiceProps {
  enquiryViewingsActions: typeof actions
  enquiryViewingsListState: any // @TODO
  enquiryViewingsSelectors: typeof enquiryViewingsSelectors
  enquiryViewingsSingleState: any // @TODO
}

const withEnquiryViewingsService = <P extends withEnquiryViewingsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ enquiryViewings }) => ({ enquiryViewings })

  const mapDispatch = injectActions('enquiryViewingsActions', actions)

  const Component = ({ enquiryViewings, enquiryViewingsActions, ...others }) => (
    <WrappedComponent
      enquiryViewingsActions={enquiryViewingsActions}
      enquiryViewingsListState={enquiryViewings.list}
      enquiryViewingsSelectors={enquiryViewingsSelectors}
      enquiryViewingsSingleState={enquiryViewings.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiryViewingsService
