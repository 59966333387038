import versionData from './version'

export const properties = {
  amplitudeApiKey: AMPLITUDE_API_KEY,
  analyticsSessionTime: 30 * 60 * 1000,
  assetsToken: '_bt',
  baseApiUri: API_BASE_URI,
  codeVersion: versionData.version,
  debugSubdomain: DEBUG_SUBDOMAIN,
  defaultSubdomain: DEFAULT_SUBDOMAIN || 'app',
  defaultViewTitle: 'Blossom Educational',
  drinkingOptions: [['cup', 'Cup'], ['beaker', 'Beaker'], ['bottle', 'Bottle']],
  env: APP_ENV,
  helpDeskNumber: '+44 (0) 1923 545 200',
  identityExpireTime: 29, // days, represented in days. API set token for 30days
  identityKey: IDENTITY_KEY,
  identityKeyLegacy: `AUTH_V9_${APP_ENV}`,
  idlePromptTimeout: 60000,
  infoBannerExpirationTime: 15, // days
  knowledgeBase: 'https://helpcentre.blossomeducational.com/en/',
  languages: [
    'Abkhaz',
    'Afar',
    'Afrikaans',
    'Akan',
    'Albanian',
    'Amharic',
    'Arabic',
    'Aragonese',
    'Armenian',
    'Assamese',
    'Avaric',
    'Avestan',
    'Aymara',
    'Azerbaijani',
    'Bambara',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bihari',
    'Bislama',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Cantonese',
    'Catalan; Valencian',
    'Chamorro',
    'Chechen',
    'Chichewa; Chewa; Nyanja',
    'Chuvash',
    'Cornish',
    'Corsican',
    'Cree',
    'Croatian',
    'Czech',
    'Danish',
    'Divehi; Dhivehi; Maldivian;',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Faroese',
    'Fijian',
    'Finnish',
    'French Creole',
    'French',
    'Fula; Fulah; Pulaar; Pular',
    'Galician',
    'Georgian',
    'German',
    'Greek; Modern',
    'Guaraní',
    'Gujarati',
    'Haitian; Haitian Creole',
    'Hausa',
    'Hebrew (modern)',
    'Herero',
    'Hindi',
    'Hiri Motu',
    'Hungarian',
    'Icelandic',
    'Ido',
    'Igbo',
    'Indonesian',
    'Interlingua',
    'Interlingue',
    'Inuktitut',
    'Inupiaq',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kalaallisut; Greenlandic',
    'Kannada',
    'Kanuri',
    'Kashmiri',
    'Kazakh',
    'Khmer',
    'Kikuyu; Gikuyu',
    'Kinyarwanda',
    'Kirghiz; Kyrgyz',
    'Kirundi',
    'Komi',
    'Kongo',
    'Korean',
    'Kurdish',
    'Kwanyama; Kuanyama',
    'Lao',
    'Latin',
    'Latvian',
    'Limburgish; Limburgan; Limburger',
    'Lingala',
    'Lithuanian',
    'Luba-Katanga',
    'Luganda',
    'Luxembourgish; Letzeburgesch',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Mandarin',
    'Manx',
    'Māori',
    'Marathi (Marāṭhī)',
    'Marshallese',
    'Mongolian',
    'Nauru',
    'Navajo; Navaho',
    'Ndonga',
    'Nepali',
    'North Ndebele',
    'Northern Sami',
    'Norwegian Bokmål',
    'Norwegian Nynorsk',
    'Norwegian',
    'Nuosu',
    'Occitan',
    'Ojibwe; Ojibwa',
    'Old Church Slavonic; Church Slavic; Church Slavonic; Old Bulgarian; Old Slavonic',
    'Oriya',
    'Oromo',
    'Ossetian; Ossetic',
    'Pāli',
    'Panjabi; Punjabi',
    'Papiamentu',
    'Pashto; Pushto',
    'Persian',
    'Polish',
    'Portuguese',
    'Quechua',
    'Romanian; Moldavian; Moldovan',
    'Romanian',
    'Romansh',
    'Russian',
    'Samoan',
    'Sango',
    'Sanskrit (Saṁskṛta)',
    'Sardinian',
    'Scottish Gaelic; Gaelic',
    'Serbian',
    'Shona',
    'Sign language',
    'Sindhi',
    'Sinhala; Sinhalese',
    'Slovak',
    'Slovene',
    'Somali',
    'South Ndebele',
    'Southern Sotho',
    'Spanish; Castilian',
    'Sundanese',
    'Swahili',
    'Swati',
    'Swedish',
    'Tagalog',
    'Tahitian',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan Standard; Tibetan; Central',
    'Tigrinya',
    'Tonga (Tonga Islands)',
    'Tsonga',
    'Tswana',
    'Turkish',
    'Turkmen',
    'Twi',
    'Uighur; Uyghur',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Venda',
    'Vietnamese',
    'Volapük',
    'Walloon',
    'Welsh',
    'Western Frisian',
    'Wolof',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zhuang; Chuang',
  ],
  maritalStatusOptions: [
    ['married', 'Married'],
    ['partners', 'Partners'],
    ['divorced', 'Divorced'],
    ['widowed', 'Widowed'],
    ['separated', 'Separated'],
    ['single', 'Single'],
    ['co-habiting', 'Co-habiting'],
  ],
  nurseryDomainPrefix: NURSERY_DOMAIN_PREFIX || 'https://',
  nurseryDomainSuffixes: [
    '.bmapp.co.uk',
    '.blossom.localhost',
    '.blossomeducational.com',
    '.dev.blsm.uk',
    '.staging.blsm.uk',
    '.demo.blsm.uk',
    '.hotfix.blsm.uk',
    '.dev.blsmlab.co.uk',
    '.staging.blsmlab.co.uk',
    '.demo.blsmlab.co.uk',
    '.hotfix.blsmlab.co.uk',
  ],
  observationStates: [['emerging', 'Emerging'], ['developing', 'Developing'], ['secure', 'Secure']],
  pagination: {
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 1,
  },
  paymentsImportTemplateFileName: 'Payment import template.csv',
  pinIdentityKey: PIN_IDENTITY_KEY,
  ravenDNS: RAVEN_DNS,
  referralLink: 'https://blossomeducational.com/refer-blossom/?utm_source=nursery-app&utm_medium=referrral&utm_campaign=amazon-voucher', // eslint-disable-line
  release: RELEASE,
  sentry: {
    ignoreErrors: [
      'Invalid attempt to spread non-iterable instance',
      /Invalid attempt to spread non-iterable instance.*/gm,
      'ResizeObserver loop limit exceeded',
      'The network connection was lost.',
      'Non-Error exception captured',
      'SERVICE_CALL_ERROR:  [status 401]',
      'SERVICE_CALL_ERROR:  [status 403]',
      'SERVICE_CALL_ERROR:  [status 500]',
      'SERVICE_CALL_ERROR:  [status undefined]',
      /chrome-extension:\/\//gm,
      /safari-extension:\/\//gm,
      /UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:*/,
      /UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:*/gm,
    ],
  },
  showMaintenanceBanner: false,
  supportEmail: 'support@blossomeducational.com',
  tinyMceApiKey: TINY_MCE,
  toilettingOptions: [
    ['nappies', 'Nappies'], ['training', 'Training'], ['toilet-trained', 'Toilet trained'],
  ],
  upload: {
    chunk: 3,
    limits: {
      activity: 100,
      child: 50,
      membership: 50,
      nursery: 100,
      observation: 100,
    },
    statusesDelay: 5000,
  },
  userTitles: ['Mr', 'Mrs', 'Ms', 'Miss', 'Master', 'Dr'],
}
