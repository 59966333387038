import React from 'react'

import { EmptyState, Typography } from 'components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { StyledEmptyState } from './WidgetStyled'

interface WidgetEmptyStateProps {
  icon: IconType
  text1: string
  text2: string
}

const WidgetEmptyState = ({ icon, text1, text2 }: WidgetEmptyStateProps) => (
  <StyledEmptyState>
    <EmptyState
      icon={icon}
      text1={(
        <React.Fragment>
          <Typography bold>
            {text1}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="5px 0 0">
            {text2}
          </Typography>
        </React.Fragment>
      )}
      iconComponentSmallMargin
    />
  </StyledEmptyState>
)

export default WidgetEmptyState
