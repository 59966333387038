import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

export const StyledChartWrapper = styled.div`
  margin: -10px -2px -10px -10px;
  
  ${({ mode }) => VIEW_MODE.WEEK === mode && `
    margin: -10px 0;
  `}
  
  text {
    fill: ${NEUTRAL_COLOURS.GRAY} !important;
    font-family: ${layout.fontFamily} !important;
    font-weight: 600;
    font-size: 12px !important;
  }
`

export const StyledCustomTooltip = styled.div`
  background: ${NEUTRAL_COLOURS.BASIC};
  padding: 10px 12px;
  border-radius: 12px;
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 160px;
  text-align: left;
  position: relative;
  z-index: 300;
  
  &:after {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    border-top: 10px solid ${NEUTRAL_COLOURS.BASIC};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  ${({ $widgetMode }) => $widgetMode && `
    transform: translateX(-50px) translateY(3px);

    &:after {
      transform: translateX(calc(-50% + 50px));
    }
  `}
`
