import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $height?: string
  $part?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background-color: ${NEUTRAL_COLOURS.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  overflow: auto;
  color: ${getBrandingColor('primary-color')};
  font-size: 22px;
  height: ${({ $height }) => $height || '280px'};

  ${({ $width }) => $width && `
    width: ${$width};
  `};

  @media(min-width: 1100px) {
    max-width: 340px;
  }

  ${({ $part }) => $part && `
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    background-color: transparent;
  `}
`

export const StyledHeader = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};
  display: flex;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 16px 24px 8px;
  line-height: 21.6px;
`

export const StyledContent = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  
  ${({ isLoading }) => isLoading && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  ${({ horizontalAlign }) => horizontalAlign && `
    justify-content: ${horizontalAlign};
  `}
  
  ${({ verticalAlign }) => verticalAlign && `
    align-items: ${verticalAlign};
  `}
  
  ${({ noPadding }) => noPadding && `
    padding: 0;
  `}
`

export const StyledBadge = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border-radius: 25px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
`

export const StyledEmptyState = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & > div {
    padding: 0;
  }
`
