import styled from 'styled-components'

export const StyledTimeAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 17px;
`

export const StyledTimeSpan = styled.span`
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: .8;
`

export const StyledDateSpan = styled.span`
  font-size: 20px;
  line-height: .8;
  margin-top: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
`

export const StyledSuffixSpan = styled.span`
  font-size: 12px;
  font-weight: 600;
  transform: translateY(-6px);
  display: inline-block;
`
