import React from 'react'

import AdminDashboard from './AdminDashboard'
import NurseryDashboard from './NurseryDashboard'
import OrganizationDashboard from './OrganizationDashboard'
import { StyledWrapper } from './DashboardStyled'

interface DashboardViewProps {
  hasOrganizationAccess: boolean
  isAdministrationContext: boolean
  isOrganizationContext: boolean
  isSuperAdmin: boolean
}

const DashboardView: React.FC<DashboardViewProps> = ({
  hasOrganizationAccess,
  isAdministrationContext,
  isOrganizationContext,
  isSuperAdmin,
}) => {
  const renderContent = () => {
    if (isSuperAdmin && isAdministrationContext) {
      return (
        <AdminDashboard />
      )
    }

    if (isOrganizationContext && hasOrganizationAccess) {
      return (
        <OrganizationDashboard />
      )
    }

    return (
      <NurseryDashboard />
    )
  }

  return (
    <StyledWrapper>
      {renderContent()}
    </StyledWrapper>
  )
}

export default DashboardView
