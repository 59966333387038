import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, Icon } from 'components'

import {
  StyledActionsContainer,
  StyledBadgeContainer,
  StyledIconContainer,
  StyledListItem,
  StyledTitleContainer,
} from './FolderListStyled'

interface FolderListItemProps {
  actions?: React.ReactNode
  badge?: React.ReactNode
  icon: IconType
  title: string
  to: string
}

const FolderListItem: React.FC<PropsWithChildren<FolderListItemProps>> = ({ actions, badge, icon, title, to }) => (
  <Link to={to}>
    <StyledListItem>
      <StyledIconContainer>
        <Button
          icon={icon}
          negativeMargins
        />
        {badge && (
          <StyledBadgeContainer>
            {badge}
          </StyledBadgeContainer>
        )}
      </StyledIconContainer>
      <StyledTitleContainer>
        {title}
        <Icon
          color={NEUTRAL_COLOURS.GRAY_SECONDARY}
          height={20}
          icon="chevron-right"
        />
      </StyledTitleContainer>
      {actions && (
        <StyledActionsContainer>
          {actions}
        </StyledActionsContainer>
      )}
    </StyledListItem>
  </Link>
)

export default FolderListItem
