import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import { NeedsAttentionStatistics } from 'services/needsAttentionWidget/models'
import { FLAG_COLOURS } from 'constants/colors'
import { INJURIES_STATUS } from 'services/legacy/injuries/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { STATUS_TYPES } from 'services/legacy/requestedExtraSessions/constants'

import { generateRoute } from 'utils/routing'

import { Flex, Hyperlink, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledIconContainer, StyledItem } from './NeedsAttentionWidgetStyled'

interface NeedsAttentionWidgetViewProps {
  authAccessMap: {
    pendingApprovalFormativeReports: boolean
    pendingApprovalInjuries: boolean
    pendingApprovalObservations: boolean
    pendingRequestExtraSessions: boolean
    signaturesNeededInjuries: boolean
    todayViewings: boolean
  }
  isFetching: boolean
  needsAttentionStatistics: NeedsAttentionStatistics
}

const NeedsAttentionWidgetView: React.FC<NeedsAttentionWidgetViewProps> = ({
  authAccessMap,
  isFetching,
  needsAttentionStatistics,
}) => {
  const renderItem = ({
    icon,
    labelKey,
    to,
    yellow,
  }: {
    icon: IconType
    labelKey: string
    to: string
    yellow?: boolean
  }) => {
    if (0 === needsAttentionStatistics[labelKey]) {
      return null
    }

    return (
      <Hyperlink to={to} variant="div">
        <StyledItem $yellow={yellow}>
          <StyledIconContainer>
            <Icon color={yellow && FLAG_COLOURS.WARNING} height={18} icon={icon} />
          </StyledIconContainer>
          <Typography>
            {i18n.t(`module:Dashboard:Widgets:NeedsAttentionWidget:${labelKey}${1 < needsAttentionStatistics[labelKey]
              ? '_plural'
              : ''}`, {
              components: [
                <Typography fontWeight="600" variant="span" />,
              ],
              value: needsAttentionStatistics[labelKey],
            })}
          </Typography>
          <Flex>
            <Icon height={18} icon="chevron-right" />
          </Flex>
        </StyledItem>
      </Hyperlink>
    )
  }

  const renderContent = () => {
    if (!_.find(_.values(needsAttentionStatistics), (value) => 0 < value)) {
      return (
        <Widget.EmptyState
          icon="check"
          text1={i18n.t('module:Dashboard:Widgets:NeedsAttentionWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:NeedsAttentionWidget:emptyStateText2')}
        />
      )
    }

    const todayViewingsSearchParams = new URLSearchParams({
      'showAroundDate.after': moment().startOf('week').format(DEFAULT_DATE_FORMAT),
      'showAroundDate.before': moment().endOf('week').format(DEFAULT_DATE_FORMAT),
    }).toString()

    return (
      <Flex flexDirection="column" gap="8px" margin="8px 0">
        {authAccessMap.signaturesNeededInjuries && renderItem({
          icon: 'accident',
          labelKey: 'signaturesNeededInjuries',
          to: `${generateRoute('SAFEGUARDING.INJURY.LIST')}?status=${INJURIES_STATUS.SIGNATURES_NEEDED.value}`,
          yellow: true,
        })}
        {authAccessMap.todayViewings && renderItem({
          icon: 'enquiry-show-around',
          labelKey: 'todayViewings',
          to: `${generateRoute('ENQUIRIES.VIEWINGS')}?${todayViewingsSearchParams}`,
        })}
        {authAccessMap.pendingRequestExtraSessions && renderItem({
          icon: 'extra-session-request',
          labelKey: 'pendingRequestExtraSessions',
          to: `${generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')}?status=${STATUS_TYPES.PENDING}`,
        })}
        {authAccessMap.pendingApprovalInjuries && renderItem({
          icon: 'accident',
          labelKey: 'pendingApprovalInjuries',
          to: `${generateRoute('APPROVALS.INJURY')}?status=${INJURIES_STATUS.PENDING_APPROVAL.value}`,
        })}
        {authAccessMap.pendingApprovalObservations && renderItem({
          icon: 'observations',
          labelKey: 'pendingApprovalObservations',
          to: generateRoute('APPROVALS.OBSERVATIONS'),
        })}
        {authAccessMap.pendingApprovalFormativeReports && renderItem({
          icon: 'formative-reports',
          labelKey: 'pendingApprovalFormativeReports',
          to: generateRoute('APPROVALS.FORMATIVE_REPORTS'),
        })}
      </Flex>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:NeedsAttentionWidget:title')} />
      <Widget.Content isLoading={isFetching}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default NeedsAttentionWidgetView
