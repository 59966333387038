import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledBoxHeaderProps {
  $primary?: boolean
  $secondary?: boolean
}

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 10px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin: 0 40px;
  }

  @media(max-width: 440px) {
    margin: 0 5px;

    & h1 {
      font-size: 38px;
      margin: 0 0 24px;
    }
  }
`

export const StyledAdditionalFeaturesTitle = styled.div`
  & > h2 {
    font-weight: 400;

    @media(max-width: ${layout.mobileWidthResolution}px) {
      text-align: center;
    }

    @media(max-width: 440px) {
      font-size: 16px;
    }
  }
`

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 20px;
  }
`

export const StyledBoxHeader = styled.div<StyledBoxHeaderProps>`
  padding: 10px 0;
  display: grid;
  justify-content: left;
  padding-left: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${NEUTRAL_COLOURS.WHITE};
  
  ${({ $primary }) => $primary && `
    background-color: ${getBrandingColor('primary-color')};
  `}
  
  ${({ $secondary }) => $secondary && `
    background-color: ${getBrandingColor('secondary-color')};
  `}
`

export const StyledBoxContent = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 4px 20px;
  height: calc(100% - 44px);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    align-items: flex-start;
    padding: 4px 30px;
  }

  @media(max-width: 440px) {
    padding: 4px 20px;
  }
`

export const StyledList = styled.ol<StyledBoxHeaderProps>`
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  
  li {
    position: relative;
    margin-left: 24px;
      
    &:before {
      margin-right: 8px;
      content: '✓';
      margin-left: -24px;
      font-weight: 600;
    }
      
    ${({ $primary }) => $primary && `
      &:before {
        color: ${getBrandingColor('primary-color')};
      }
    `}
  
    ${({ $secondary }) => $secondary && `
      &:before {
        color: ${getBrandingColor('secondary-color')};
      }
    `}

    @media(max-width: 440px) {
      font-size: 14px;
    }
  }
`

export const StyledFooter = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px;

  @media(max-width: 440px) {
    margin-top: 20px;
    padding: 20px 10px;
  }
`

export const StyledFooterTitle = styled.div`
  margin-left: 3px;

  @media(max-width: 440px) {
    & p {
      font-size: 16px;
    }
  }
`

export const StyledContactInfo = styled.div`
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-template-rows: repeat(2, 22px);
  align-items: end;
  align-content: center;
  margin-left: 3px;

  @media(max-width: 440px) {
    grid-template-columns: 48px 1fr;
    grid-template-rows: repeat(2, 20px);

    & p {
      font-size: 14px;
    }
  }
`
