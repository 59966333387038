import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_VIEWINGS_WIDGET_STATISTICS,
  GET_VIEWINGS_WIDGET_STATISTICS,
  GET_VIEWINGS_WIDGET_STATISTICS_FAILED,
  GET_VIEWINGS_WIDGET_STATISTICS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface ViewingsStatisticsWidgetSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_VIEWINGS_WIDGET_STATISTICS,
    itemFailed: GET_VIEWINGS_WIDGET_STATISTICS_FAILED,
    itemInit: GET_VIEWINGS_WIDGET_STATISTICS,
    itemSuccess: GET_VIEWINGS_WIDGET_STATISTICS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
