import React, { Component } from 'react'

import { WrappedComponentType } from 'constants/types'
import { DEFAULT_PER_PAGE, PaginationDetails } from 'constants/pagination'
import { noop } from 'constants/global'

import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'

interface PaginationUtilsState {
  page?: number
  perPage?: number
  setQuery?: boolean
}

export interface PaginationUtilsProps extends PaginationUtilsState {
  getPageCount?: (items: any) => number
  getPaginationDetails?: (totalResults: number) => PaginationDetails
  onPageChange?: (cb?: any) => (page: any) => any
  setPageLocationQuery: (setQuery: boolean, cb?: any) => void
  setPerPage?: (perPage: any, cb?: any) => void
}

export interface withPaginationUtilsProps extends withRouteUtilsProps {
  paginationUtils: PaginationUtilsProps
}

const withPaginationUtils = <P extends withPaginationUtilsProps> (
  WrappedComponent: WrappedComponentType<P>,
) => {
  class EnhancedComponent extends Component<P & withPaginationUtilsProps, PaginationUtilsState> {
    static authParams = null

    constructor(props) {
      super(props)

      const { page = 1 } = props.location.query

      this.state = {
        page: +page,
        perPage: DEFAULT_PER_PAGE,
        setQuery: true,
      }
    }

    setPerPage = (perPage, cb) => {
      this.setState({ perPage }, cb)
    }

    setPageLocationQuery = (setQuery, cb) => {
      this.setState({ setQuery }, cb)
    }

    getPageCount = (items) => {
      const { perPage } = this.state

      return Math.ceil(items / perPage)
    }

    handlePageChange = (cb?) => (page) => {
      const { setLocationQuery } = this.props
      const { setQuery } = this.state

      if (setQuery) {
        setLocationQuery({ page })
      }

      this.setState({ page }, cb || noop)
    }

    handlePaginationDetails = (totalResults: number): PaginationDetails => {
      const { page, perPage } = this.state

      return {
        onPageChange: (newPage) => this.handlePageChange()(newPage),
        page: +page,
        pageCount: this.getPageCount(totalResults),
        perPage,
        totalResults,
      }
    }

    render() {
      const { page, perPage } = this.state

      const paginationUtils = {
        getPageCount: this.getPageCount,
        getPaginationDetails: this.handlePaginationDetails,
        onPageChange: this.handlePageChange,
        page,
        perPage,
        setPageLocationQuery: this.setPageLocationQuery,
        setPerPage: this.setPerPage,
      }

      return <WrappedComponent paginationUtils={paginationUtils} {...this.props as P} />
    }
  }

  EnhancedComponent.authParams = WrappedComponent.authParams

  return withRouterUtils(EnhancedComponent)
}

export default withPaginationUtils
