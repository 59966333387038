import i18n from 'translations'

import AccidentsIncidentsSignaturesWidget from './AccidentsIncidentsSignaturesWidgetContainer'

export const WIDGET_NAME = {
  component: AccidentsIncidentsSignaturesWidget,
  id: 'ACCIDENTS_INCIDENTS_SIGNATURES_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:titleInSettings'),
}

export default AccidentsIncidentsSignaturesWidget
