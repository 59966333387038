import moment from 'moment'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { withAppService, withAppServiceProps } from 'services/app'
import { withRegisterService, withRegisterServiceProps } from 'services/legacy/register'
import * as registerSelectors from 'services/legacy/register/selectors'
import appSelectors from 'services/app/selectors'

import TodayAllergiesWidgetView from './TodayAllergiesWidgetView'

const mapState = (state: RootState, { registerState }) => ({
  childrenList: registerSelectors.getRegisterDataSelector(state),
  isFetching: appSelectors.getIsFetching(registerState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type TodayAllergiesWidgetContainerProps = PropsFromRedux
  & withAppServiceProps
  & withRegisterServiceProps

const TodayAllergiesWidgetContainer: React.FC<TodayAllergiesWidgetContainerProps> = ({
  childrenList,
  isFetching,
  registerActions,
}) => {
  const fetch = () => {
    const criteria = registerSelectors.getRegisterChildrenCriteria({
      allergy: 1,
      entryDate: moment().format(DEFAULT_DATE_FORMAT),
      present: 1,
    })

    registerActions.list({
      criteria,
      includes: ['legacyInformation'],
      limit: 0,
    })
  }

  useEffect(() => {
    fetch()

    return () => {
      registerActions.clear()
    }
  }, [])

  return (
    <TodayAllergiesWidgetView
      allergyChildren={childrenList}
      isLoading={isFetching}
    />
  )
}

const enhance = compose(
  withAppService,
  withRegisterService,
  connector,
)

export default enhance(TodayAllergiesWidgetContainer)
