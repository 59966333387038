import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { BasicSingleResponse } from 'constants/service'
import { NeedsAttentionStatistics } from 'services/needsAttentionWidget/models'
import { RootState } from 'core/reducers'

import { withNeedsAttentionWidgetService, withNeedsAttentionWidgetServiceProps } from 'services/needsAttentionWidget'
import {
  hasAccessExcludeFinanceAdminAndTeachers,
  hasFullAccessExceptFinAdmin,
  hasOnlyManagementAccess,
} from 'services/security/selectors'

import NeedsAttentionWidgetView from './NeedsAttentionWidgetView'

const mapState = (state: RootState) => ({
  authAccessMap: {
    pendingApprovalFormativeReports: hasAccessExcludeFinanceAdminAndTeachers(state),
    pendingApprovalInjuries: hasAccessExcludeFinanceAdminAndTeachers(state),
    pendingApprovalObservations: hasAccessExcludeFinanceAdminAndTeachers(state),
    pendingRequestExtraSessions: hasOnlyManagementAccess(state),
    signaturesNeededInjuries: hasFullAccessExceptFinAdmin(state),
    todayViewings: hasOnlyManagementAccess(state),
  },
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type NeedsAttentionWidgetContainerProps = PropsFromRedux & withNeedsAttentionWidgetServiceProps

const NeedsAttentionWidgetContainer: React.FC<NeedsAttentionWidgetContainerProps> = ({
  authAccessMap,
  needsAttentionWidgetActions,
}) => {
  const [needsAttentionStatistics, setNeedsAttentionStatistics] = useState<NeedsAttentionStatistics | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getStatisticsSuccess = (response: BasicSingleResponse<NeedsAttentionStatistics>) => {
    setNeedsAttentionStatistics(response.data)
    setIsFetching(false)
  }

  const fetchStatistics = () => {
    needsAttentionWidgetActions.get({
      onSuccess: getStatisticsSuccess,
      onlyData: true,
    })
  }

  useEffect(() => {
    fetchStatistics()
  }, [])

  return (
    <NeedsAttentionWidgetView
      authAccessMap={authAccessMap}
      isFetching={isFetching}
      needsAttentionStatistics={needsAttentionStatistics}
    />
  )
}

const enhance = compose(
  withNeedsAttentionWidgetService,
  connector,
)

export default enhance(NeedsAttentionWidgetContainer)
