import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledItem = styled.div<{ $isToday: boolean }>`
  display: grid;
  grid-template-columns: 48px 1fr 24px;
  align-items: center;
  gap: 24px;
  padding: 12px 16px;
  border-radius: 16px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  cursor: pointer;

  ${({ $isToday }) => $isToday && `
    background: ${getBrandingColor('quinary-color')};
  `}
`
