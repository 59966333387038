import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { Child } from 'services/child/models'
import { BirthdayWidgetRecord, BirthdayWidgetType } from 'services/birthdayWidget/models'
import { Membership } from 'services/legacy/memberships/models'

import { getOrdinalNumber } from 'utils/string'
import { getBrandingColor } from 'utils/branding'

import { Icon, InfiniteScroll, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledIcon, StyledTodayBirthdays } from './BirthdaysWidgetStyled'

interface BirthdaysWidgetViewProps {
  birthdayRecords: BirthdayWidgetRecord[]
  isLoading: boolean
  onLoadNextPage: () => void
  page: number
  pageCount: number
}

const BirthdaysWidgetView: React.FC<BirthdaysWidgetViewProps> = ({
  birthdayRecords,
  isLoading,
  onLoadNextPage,
  page,
  pageCount,
}) => {
  const todayBirthdays = []
  const upcomingBirthdays = []

  _.each(birthdayRecords, (record: BirthdayWidgetRecord) => {
    if (moment(record.birthDate).format('MMDD') === moment().format('MMDD')) {
      todayBirthdays.push(record)
    } else {
      upcomingBirthdays.push(record)
    }
  })

  const renderRightColumn = (birthDate: string, isToday: boolean) => (
    isToday
      ? (
        <StyledIcon>
          <Icon height={24} icon="birthday" />
        </StyledIcon>
      )
      : (
        <Typography color={NEUTRAL_COLOURS.GRAY} nowrap>
          {moment(birthDate).utc().format('D MMM')}
        </Typography>
      )
  )

  const renderLeftColumn = (record: BirthdayWidgetRecord, isToday: boolean) => {
    const { birthDate, type } = record

    if (BirthdayWidgetType.STAFF === type) {
      return (
        <Typography color={isToday && getBrandingColor('secondary-color')} fontSize={14}>
          {_.upperFirst(i18n.t('global:staffMember'))}
        </Typography>
      )
    }

    const numberOfYears = isToday
      ? moment().diff(moment(birthDate), 'years')
      : moment().diff(moment(birthDate), 'years') + 1
    const whichBirthday = i18n.t(
      'module:Dashboard:Widgets:BirthdaysWidget:whichBirthday',
      { ordinalNumber: getOrdinalNumber(numberOfYears) },
    )

    if (isToday) {
      return (
        <Typography color={getBrandingColor('secondary-color')} fontSize={14}>
          {whichBirthday}
        </Typography>
      )
    }

    return whichBirthday
  }

  const renderListRow = (record: BirthdayWidgetRecord) => {
    const { birthDate, name, photo } = record
    const isToday = moment(birthDate).format('D M') === moment().format('D M')
    const person = { name, photo } as Child | Membership

    return (
      <Widget.List.Row
        key={`${name}-${birthDate}`}
        leftBottomColumn={renderLeftColumn(record, isToday)}
        leftTopColumn={name}
        persons={[person]}
        rightColumn={renderRightColumn(birthDate, isToday)}
      />
    )
  }

  const renderTodayBirthdays = () => (
    <StyledTodayBirthdays>
      <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={14} margin="4px 0" bold>
        {_.upperFirst(i18n.t('global:today'))}
      </Typography>
      <Widget.List>
        {_.map(todayBirthdays, renderListRow)}
      </Widget.List>
    </StyledTodayBirthdays>
  )

  const renderUpcomingBirthdays = () => (
    <React.Fragment>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="4px 0" bold>
        {_.upperFirst(i18n.t('global:upcoming'))}
      </Typography>
      <Widget.List>
        {_.map(upcomingBirthdays, renderListRow)}
      </Widget.List>
    </React.Fragment>
  )

  const renderContent = () => (
    !birthdayRecords?.length
      ? (
        <Widget.EmptyState
          icon="birthday"
          text1={i18n.t('module:Dashboard:Widgets:BirthdaysWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:BirthdaysWidget:emptyStateText2')}
        />
      )
      : (
        <InfiniteScroll
          dataLength={birthdayRecords.length}
          hasMore={pageCount > page}
          next={onLoadNextPage}
          scrollableTarget="birthdaysScrollableTarget"
          lightSpinner
        >
          {0 < todayBirthdays?.length && renderTodayBirthdays()}
          {0 < upcomingBirthdays?.length && renderUpcomingBirthdays()}
        </InfiniteScroll>
      )
  )

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:BirthdaysWidget:title')} />
      <Widget.Content id="birthdaysScrollableTarget" isLoading={isLoading}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default BirthdaysWidgetView
