import i18n from 'translations'

import NeedsAttentionWidget from './NeedsAttentionWidgetContainer'

export const WIDGET_NAME = {
  component: NeedsAttentionWidget,
  id: 'NEEDS_ATTENTION_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:NeedsAttentionWidget:titleInSettings'),
}

export default NeedsAttentionWidget
