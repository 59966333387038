import color from 'color'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'

import { Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledCustomButton, StyledIconWrapper } from './ObservationDiaryButtonsWidgetStyled'

interface ObservationDiaryButtonsWidgetViewProps {
  onSendDailyDiaryButtonClick: () => void
}

const ObservationDiaryButtonsWidgetView: React.FC<ObservationDiaryButtonsWidgetViewProps> = ({
  onSendDailyDiaryButtonClick,
}) => (
  <Widget part>
    <Widget.Part>
      <Link to={generateRoute('LEARNING.OBSERVATIONS.ADD')} onlyActiveOnIndex>
        <StyledCustomButton>
          <StyledIconWrapper $background={getBrandingColor('quinary-color')}>
            <Icon icon="observations" />
          </StyledIconWrapper>
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18} bold>
            {i18n.t('module:Dashboard:Widgets:ObservationDiaryButtonsWidget:makeObservation')}
          </Typography>
          <Icon height={16} icon="chevron-right" />
        </StyledCustomButton>
      </Link>
    </Widget.Part>
    <Widget.Part>
      <StyledCustomButton onClick={onSendDailyDiaryButtonClick}>
        <StyledIconWrapper $background={color(getBrandingColor('tertiary-color')).alpha(0.1).rgb().string()}>
          <Icon color={getBrandingColor('tertiary-color')} icon="send" />
        </StyledIconWrapper>
        <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18} bold>
          {i18n.t('module:Dashboard:Widgets:ObservationDiaryButtonsWidget:sendDiary')}
        </Typography>
        <Icon height={16} icon="chevron-right" />
      </StyledCustomButton>
    </Widget.Part>
  </Widget>
)

export default ObservationDiaryButtonsWidgetView
