export default {
  AVATARS: {
    BIG: {
      height: 120,
      width: 120,
    },
    MEDIUM: {
      height: 40,
      width: 40,
    },
    MEDIUM_BIG: {
      height: 48,
      width: 48,
    },
    MEDIUM_BIGGER: {
      height: 80,
      width: 80,
    },
    SMALL: {
      height: 30,
      width: 30,
    },
    STANDARD: {
      height: 55,
      width: 55,
    },
    VERY_SMALL: {
      height: 24,
      width: 24,
    },
  },
  FULL_VIEW: {
    height: 768,
    max: true,
    width: 1024,
  },
  MEDIA_PICKER_PREVIEW: {
    height: 300,
    width: 450,
  },
  MEDIA_PICKER_THUMBNAIL: {
    height: 40,
    width: 40,
  },
  MEDIA_PICKER_TILE_THUMBNAIL: {
    height: 135,
    max: true,
    width: 220,
  },
  NURSERY_LOGO: {
    height: 100,
    max: true,
    width: 200,
  },
}
