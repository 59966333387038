import React, { PropsWithChildren } from 'react'

import { StyledWidgetPart } from './WidgetPartStyled'

const WidgetPart: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledWidgetPart>
    {children}
  </StyledWidgetPart>
)

export default WidgetPart
