import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledSquareProps {
  $color: string
}

export const StyledSquare = styled.div<StyledSquareProps>`
  border-radius: 16px;
  color: ${NEUTRAL_COLOURS.WHITE};
  height: 18px;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: ${({ $color }) => $color};
`
