import moment from 'moment'

import React from 'react'
import { Link } from 'react-router'

import { Option } from 'constants/models'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { RotaStatisticsPlannedPeriod } from 'services/legacy/membershipsShifts/models'

import { generateRoute } from 'utils/routing'

import { calculateEdgeTimesBasedOnStatistics } from 'services/legacy/membershipsShifts/selectors'

import { Flex, Icon, InfiniteDropdowns, Space, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import ChartRotaLegend from 'module/Staff/StaffRota/components/ChartRotaLegend'
import ChartRota from 'module/Staff/StaffRota/components/ChartRota'

import i18n from 'translations'

import { StyledBorderBox, StyledChartContainer, StyledWidgetContainer } from './StaffingOverviewWidgetStyled'

export interface Statistics {
  [YYYY_MM_DD: string]: RotaStatisticsPlannedPeriod[]
}

interface StaffingOverviewWidgetViewProps {
  doesntExistAnyNurseryClass: boolean
  isFetching: boolean
  nurseryClass: Option
  onNurseryClassChange: (option: Option) => void
  statistics: Statistics
}

const StaffingOverviewWidgetView: React.FC<StaffingOverviewWidgetViewProps> = ({
  doesntExistAnyNurseryClass,
  isFetching,
  nurseryClass,
  onNurseryClassChange,
  statistics,
}) => {
  const todayDate = moment()
  const edgeTimesFromStatistics = calculateEdgeTimesBasedOnStatistics(statistics, todayDate)
  const earliestTime = edgeTimesFromStatistics.from
  const latestTime = edgeTimesFromStatistics.to + 2
  const staffingFlags = statistics?.[todayDate.format(DEFAULT_DATE_FORMAT)]?.[0]?.staffingFlags || []

  const renderChart = () => (
    <Flex flexDirection="column">
      <Flex>
        <Flex width="160px" />
        <Flex flex={1} padding="16px 10px">
          <Typography fontWeight={600} transform="uppercase">
            {moment().format('ddd')}
            {' '}
            <Typography color={NEUTRAL_COLOURS.GRAY} variant="span" inline>
              {moment().format('D')}
            </Typography>
          </Typography>
        </Flex>
      </Flex>
      <Flex>
        <Flex height="84px" width="160px">
          <StyledBorderBox>
            <Flex alignItems="center" height="100%" justifyContent="center">
              <ChartRotaLegend />
            </Flex>
          </StyledBorderBox>
        </Flex>
        <Flex flex={1} height="84px">
          <StyledBorderBox $noBorderLeft>
            <StyledChartContainer>
              <ChartRota
                date={todayDate}
                day={todayDate}
                earliestTime={earliestTime}
                latestTime={latestTime}
                room={nurseryClass?.value}
                statistics={statistics}
                viewMode={VIEW_MODE.DAY}
                widgetMode
              />
            </StyledChartContainer>
          </StyledBorderBox>
        </Flex>
      </Flex>
      <Flex>
        <Flex height="33px" width="160px">
          <StyledBorderBox $grayBackground $noBorderTop>
            <Flex alignItems="center" height="100%" padding="0 14px">
              <Typography>
                {i18n.t('module:Dashboard:Widgets:StaffingOverviewWidget:staffingFlags')}
              </Typography>
            </Flex>
          </StyledBorderBox>
        </Flex>
        <Flex flex={1} height="33px">
          <StyledBorderBox $grayBackground $noBorderLeft $noBorderTop>
            <Flex alignItems="center" height="100%" padding="0 8px">
              <Typography>
                {staffingFlags?.length}
              </Typography>
            </Flex>
          </StyledBorderBox>
        </Flex>
      </Flex>
    </Flex>
  )

  const renderContent = () => {
    if (doesntExistAnyNurseryClass) {
      return (
        <Widget.EmptyState
          icon="rooms"
          text1={i18n.t('module:Dashboard:Widgets:StaffingOverviewWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:StaffingOverviewWidget:emptyStateText2')}
        />
      )
    }

    return (
      <StyledWidgetContainer>
        <Space space="2px" />
        <InfiniteDropdowns.Rooms
          clearable={false}
          maxMenuHeight={160}
          value={nurseryClass}
          allRoomTypes
          onChange={onNurseryClassChange}
        />
        {renderChart()}
      </StyledWidgetContainer>
    )
  }

  return (
    <Widget>
      <Widget.Header
        label={(
          <Flex alignItems="center" gap="12px">
            <Typography fontSize={18} fontWeight={600}>
              {i18n.t('module:Dashboard:Widgets:StaffingOverviewWidget:title')}
            </Typography>
            <Link to={`${generateRoute('STAFF.ROTA')}${nurseryClass?.value ? `?room=${nurseryClass.value}` : ''}`}>
              <Icon height={16} icon="chevron-right" />
            </Link>
          </Flex>
        )}
      />
      <Widget.Content isLoading={isFetching}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default StaffingOverviewWidgetView
