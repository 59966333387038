import * as AttendanceWidget from './AttendanceWidget'
import * as BirthdaysWidget from './BirthdaysWidget'
import * as ChildrenSearchWidget from './ChildrenSearchWidget'
import * as DraftInvoicesWidget from './DraftInvoicesWidget'
import * as MonthlyIncomeWidget from './MonthlyIncomeWidget'
import * as NextStepsWidget from './NextStepsWidget'
import * as ObservationDiaryButtonsWidget from './ObservationDiaryButtonsWidget'
import * as ObservationsWidget from './ObservationsWidget'
import * as TimeWidget from './TimeWidget'
import * as TodayAllergiesWidget from './TodayAllergiesWidget'
import * as TopOutstandingAccountsWidget from './TopOutstandingAccountsWidget'
import * as ExtraSessionRequestsWidget from './ExtraSessionRequestsWidget'
import * as AccidentsIncidentsSignaturesWidget from './AccidentsIncidentsSignaturesWidget'
import * as StartersAndLeaversWidget from './StartersAndLeaversWidget'
import * as ViewingsWidget from './ViewingsWidget'
import * as NeedsAttentionWidget from './NeedsAttentionWidget'
import * as AgedDebtWidget from './AgedDebtWidget'
import * as StaffingOverviewWidget from './StaffingOverviewWidget'
import * as AnnualRevenueWidget from './AnnualRevenueWidget'

/* eslint sort-keys-fix/sort-keys-fix:0 */
/* eslint sort-keys:0 */
export default {
  TimeWidget: TimeWidget.WIDGET_NAME,
  AttendanceWidget: AttendanceWidget.WIDGET_NAME,
  NeedsAttentionWidget: NeedsAttentionWidget.WIDGET_NAME,
  TodayAllergiesWidget: TodayAllergiesWidget.WIDGET_NAME,
  ChildrenSearchWidget: ChildrenSearchWidget.WIDGET_NAME,
  ObservationDiaryButtonsWidget: ObservationDiaryButtonsWidget.WIDGET_NAME,
  BirthdaysWidget: BirthdaysWidget.WIDGET_NAME,
  ObservationsWidget: ObservationsWidget.WIDGET_NAME,
  NextStepsWidget: NextStepsWidget.WIDGET_NAME,
  ViewingsWidget: ViewingsWidget.WIDGET_NAME,
  AccidentsIncidentsSignaturesWidget: AccidentsIncidentsSignaturesWidget.WIDGET_NAME,
  ExtraSessionRequestsWidget: ExtraSessionRequestsWidget.WIDGET_NAME,
  StaffingOverviewWidget: StaffingOverviewWidget.WIDGET_NAME,
  AnnualRevenueWidget: AnnualRevenueWidget.WIDGET_NAME,
  MonthlyIncomeWidget: MonthlyIncomeWidget.WIDGET_NAME,
  AgedDebtWidget: AgedDebtWidget.WIDGET_NAME,
  TopOutstandingAccountsWidget: TopOutstandingAccountsWidget.WIDGET_NAME,
  DraftInvoicesWidget: DraftInvoicesWidget.WIDGET_NAME,
  StartersAndLeaversWidget: StartersAndLeaversWidget.WIDGET_NAME,
}
