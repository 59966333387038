import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Hyperlink, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledCell } from './components/NurseriesListStyled'

export const renderItem = ({ authAccessMap, item }) => {
  const {
    activeChildrenCount,
    activeStaffCount,
    archived,
    id,
    name,
    primaryContact,
    publicUrl,
  } = item

  return ({
    children: activeChildrenCount,
    edit: (
      <Tooltip
        placement="left"
        title={authAccessMap.module.NurseryEdit ? '' : i18n.t('module:Nurseries:noPermissionToEdit')}
      >
        <Button.TableAction
          disabled={!authAccessMap.module.NurseryEdit}
          to={generateRoute('NURSERIES.DETAILS', { nurseryId: id })}
        />
      </Tooltip>
    ),
    manager: (
      <StyledCell>
        <Typography>
          {primaryContact?.name}
        </Typography>
      </StyledCell>
    ),
    nursery: (
      <StyledCell>
        <Hyperlink target="_blank" to={publicUrl} secondary>
          {archived ? `${name} (${i18n.t('global:disabled')})` : name}
        </Hyperlink>
        {/* {(address || postCode) && (
          <StyledContact>
            <StyledIcon>
              <Icon
                color={NEUTRAL_COLOURS.GRAY}
                height={11}
                icon="change-location"
              />
            </StyledIcon>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} variant="span">
              {`${address}, ${postCode}`}
            </Typography>
          </StyledContact>
        )}
        {telephone && (
          <StyledContact>
            <StyledIcon>
              <Icon
                color={NEUTRAL_COLOURS.GRAY}
                height={11}
                icon="call"
              />
            </StyledIcon>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} variant="span">
              {telephone}
            </Typography>
          </StyledContact>
        )} */}
      </StyledCell>
    ),
    staff: activeStaffCount,
  })
}
