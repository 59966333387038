import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { BasicListResponse } from 'constants/service'
import { StartersAndLeaversWidgetRecord } from 'services/startersAndLeaversWidget/models'

import {
  withStartersAndLeaversWidgetService,
  withStartersAndLeaversWidgetServiceProps,
} from 'services/startersAndLeaversWidget'

import StartersAndLeaversWidgetView from './StartersAndLeaversWidgetView'

type StartersAndLeaversWidgetContainerProps = withStartersAndLeaversWidgetServiceProps

const GROUPS_STARTERS_AND_LEAVERS = {
  read: ['child'],
}

const StartersAndLeaversWidgetContainer: React.FC<StartersAndLeaversWidgetContainerProps> = ({
  startersAndLeaversWidgetActions,
}) => {
  const [startersAndLeavers, setStartersAndLeavers] = useState<StartersAndLeaversWidgetRecord[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)

  const getListSuccess = (response: BasicListResponse<StartersAndLeaversWidgetRecord>) => {
    setStartersAndLeavers(1 === response.meta.start ? response.data : [...startersAndLeavers, ...response.data])
    setIsFetching(false)
    setPage(response.meta.start)

    if (1 === response.meta.start) {
      setPageCount(Math.ceil(response.meta.total_results / response.meta.limit))
    }
  }

  const fetchList = () => {
    startersAndLeaversWidgetActions.list({
      onSuccess: getListSuccess,
      onlyData: true,
      params: {
        groups: GROUPS_STARTERS_AND_LEAVERS,
        page: page + 1,
      },
    })
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <StartersAndLeaversWidgetView
      isFetching={isFetching}
      page={page}
      pageCount={pageCount}
      startersAndLeavers={startersAndLeavers}
      onLoadNextPage={fetchList}
    />
  )
}

const enhance = compose(
  withStartersAndLeaversWidgetService,
)

export default enhance(StartersAndLeaversWidgetContainer)
