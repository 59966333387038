export const NEUTRAL_COLOURS = {
  BASIC: '#4D4D4D',
  BLUE: '#29ABE2',
  BLUE_SECONDARY: '#99CFE9',
  GRAY: '#989898',
  GRAY_SECONDARY: '#D8D8D8',
  GRAY_TERTIARY: '#EDEDED',
  GRAY_QUATERNARY: '#F6F6F6', // eslint-disable-line
  GRAY_QUINARY: '#F5F5F5',
  GREEN: '#00BD66',
  GREEN_SECONDARY: '#7FC28F',
  SALMON: '#FF917C',
  SILVER: '#EFEFF0',
  SUCCESS: '#77DD77',
  WHITE: '#FFF',
}

export const FLAG_COLOURS = {
  ERROR: '#E20714',
  ERROR_LIGHT: '#FBE1E2',
  INFO: '#36A9E0',
  INFO_LIGHT: '#E5F5Fb',
  SUCCESS: '#64D164',
  SUCCESS_LIGHT: '#EEFBEE',
  WARNING: '#FFA600',
  WARNING_LIGHT: '#FFF4E0',
}

export const DOCUMENT_FILES_COLOURS = {
  DOC: '#087ED4',
  PDF: '#F70000',
  PRESENTATION: '#DD9A00',
  SPREADSHEET: '#00AE5D',
}

// Legacy
export default {
  alert: {
    error: {
      backgroundColor: '#F7E3E6',
    },
  },
  chartColors: {
    darkGray: '#707271',
  },
  disabled: '#F2F2F2',
  finance: {
    credit: '#7EC38F',
    debit: '#E7402C',
  },
  gold: '#F8B232',
  gray: '#878787',
  inputBorderColor: '#e4e4e4',
  lightGray: '#c5c5c5',
  lockedColor: '#f5a623',
  pieChartColors: [
    ['#5cedc1', '#00ba9d'],
    ['#1b7dff', '#76c9ed'],
    ['#9850c3', '#b86cff'],
    ['#f46fa9', '#ff9eff'],
    ['#4769ff', '#00b4ff'],
    ['#ff5673', '#ff8a68'],
    ['#ffb56b', '#fab800'],
    ['#fdf449', '#fdf496'],
    ['#81d08b', '#81ffa8'],
  ],
  pink: '#ee4c9c',
  remove: '#e10b17',
  standardPieChartColors: [
    '#08D2AF',
    '#008A80',
    '#57C7DD',
    '#3577FA',
    '#AA5CE0',
    '#FB80C7',
    '#FF6E6F',
    '#FFB63E',
    '#FAE139',
  ],
  tableOddEven: ['#EFEFF0', '#F7F7F7'],
  warningBanner: {
    background: '#FFF3CD',
    text: '#856404',
  },
}
