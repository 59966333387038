import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { EnquiryShowAround } from 'services/legacy/enquiries/models'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { ViewingsStatisticsWidget } from 'services/viewingsStatisticsWidget/models'

import { isToday } from 'utils/date'
import { generateRoute } from 'utils/routing'

import { Flex, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'

import { formatDate } from '../WidgetHelpers'
import { StyledItem } from './ViewingsWidgetStyled'

interface ViewingsWidgetViewProps {
  isLoading: boolean
  onOpenModal: (item: EnquiryShowAround) => void
  viewingsRecords: EnquiryShowAround[]
  viewingsStatistics: ViewingsStatisticsWidget
}

const ViewingsWidgetView: React.FC<ViewingsWidgetViewProps> = ({
  isLoading,
  onOpenModal,
  viewingsRecords,
  viewingsStatistics,
}) => {
  const renderStatistics = () => (
    <Flex gap="40px" padding="5px 0 12px">
      <Flex flexDirection="column" gap="2px">
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
          {i18n.t('module:Dashboard:Widgets:ViewingsWidget:thisWeek')}
        </Typography>
        <Typography fontSize={40} fontWeight="600">
          {viewingsStatistics.totalThisWeek}
        </Typography>
      </Flex>
      <Flex flexDirection="column" gap="2px">
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
          {i18n.t('module:Dashboard:Widgets:ViewingsWidget:thisMonth')}
        </Typography>
        <Typography fontSize={40} fontWeight="600">
          {viewingsStatistics.totalThisMonth}
        </Typography>
      </Flex>
    </Flex>
  )

  const renderItem = (item: EnquiryShowAround) => {
    const today = isToday(item.showAroundDate)

    return (
      <StyledItem $isToday={today} key={item.id} onClick={() => onOpenModal(item)}>
        <Flex alignItems="center" flexDirection="column" gap="4px">
          <Typography align="center" color={NEUTRAL_COLOURS.BASIC} fontSize={14} fontWeight={today ? '600' : '400'}>
            {formatDate(item.showAroundDate, 'DD MMM')}
          </Typography>
          <Typography align="center" color={NEUTRAL_COLOURS.BASIC} fontWeight={today ? '600' : '400'}>
            {moment(item.showAroundDate).format('HH:mm')}
          </Typography>
        </Flex>
        <Flex flexDirection="column" gap="4px">
          <Typography color={NEUTRAL_COLOURS.BASIC} fontWeight="600">
            {item.enquiry?.contactName}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
            {_.upperFirst(i18n.t('global:child'))}
            {': '}
            <Typography fontSize={12} fontWeight="600" variant="span" inline>
              {item.enquiry?.data?.child?.firstName}
              {' ('}
              {i18n.t('global:ageMonths', {
                ageMonths: item.enquiry?.data?.child?.ageMonths,
              })}
              {')'}
            </Typography>
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
            {i18n.t('module:Dashboard:Widgets:ViewingsWidget:viewingBy')}
            {': '}
            <Typography fontSize={12} fontWeight="600" variant="span" inline>
              {item.assignedPerson?.name || i18n.t('module:Dashboard:Widgets:ViewingsWidget:unassigned')}
            </Typography>
          </Typography>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Icon height={18} icon="chevron-right" />
        </Flex>
      </StyledItem>
    )
  }

  const renderContent = () => {
    if (!viewingsRecords?.length) {
      return (
        <Widget.EmptyState
          icon="enquiry-show-around"
          text1={i18n.t('module:Dashboard:Widgets:ViewingsWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:ViewingsWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="4px 0" bold>
          {i18n.t('module:Dashboard:Widgets:ViewingsWidget:upcoming')}
        </Typography>
        <Flex flexDirection="column" gap="8px" margin="8px 0">
          {_.map(viewingsRecords, renderItem)}
        </Flex>
        <SeeMore to={generateRoute('ENQUIRIES.VIEWINGS')} />
      </React.Fragment>

    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:ViewingsWidget:title')} />
      <Widget.Content isLoading={isLoading}>
        {renderStatistics()}
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ViewingsWidgetView
