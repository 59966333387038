import _ from 'lodash'

import i18n from 'translations'

// Legacy
export const ROLES = {
  DEPUTY_MANAGER: 'DEPUTY_MANAGER',
  NON_TEACHING: 'NON_TEACHING',
  NURSERY_ADMIN: 'NURSERY_ADMIN',
  NURSERY_MANAGER: 'NURSERY_MANAGER',
  ORGANIZATION_DIRECTOR: 'ORGANIZATION_DIRECTOR',
  ORGANIZATION_FINANCE_ADMIN: 'ORGANIZATION_FINANCE_ADMIN',
  ORGANIZATION_LINE_MANAGER: 'ORGANIZATION_LINE_MANAGER',
  ORGANIZATION_NATIONAL_ADMIN: 'ORGANIZATION_NATIONAL_ADMIN',
  ROOM_LEADER: 'ROOM_LEADER',
  SENIOR_TEACHER: 'SENIOR_TEACHER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  TEACHER: 'TEACHER',
}

export enum Roles {
  DEPUTY_MANAGER = 'DEPUTY_MANAGER',
  NON_TEACHING = 'NON_TEACHING',
  NURSERY_ADMIN = 'NURSERY_ADMIN',
  NURSERY_MANAGER = 'NURSERY_MANAGER',
  ORGANIZATION_DIRECTOR = 'ORGANIZATION_DIRECTOR',
  ORGANIZATION_FINANCE_ADMIN = 'ORGANIZATION_FINANCE_ADMIN',
  ORGANIZATION_LINE_MANAGER = 'ORGANIZATION_LINE_MANAGER',
  ORGANIZATION_NATIONAL_ADMIN = 'ORGANIZATION_NATIONAL_ADMIN',
  ROOM_LEADER = 'ROOM_LEADER',
  SENIOR_TEACHER = 'SENIOR_TEACHER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TEACHER = 'TEACHER',
}

// Legacy
export const FEATURE_FLAGS = {
  ACCOUNTING_EXTENDED_EXPORT: 'ACCOUNTING_EXTENDED_EXPORT',
  AUTO_CREDIT_NOTING: 'AUTO_CREDIT_NOTING',
  COHORT_TRACKER: 'COHORT_TRACKER',
  DEPOSITS: 'DEPOSITS',
  DIARY_AUTOSEND: 'DIARY_AUTOSEND',
  ENQUIRIES: 'ENQUIRIES',
  FILES_MANAGEMENT: 'FILES_MANAGEMENT',
  FINANCE_AUTOMATION: 'FINANCE_AUTOMATION',
  FINANCE_MVP: 'FINANCE_MVP',
  FINANCE_V3: 'FINANCE_V3',
  GROUP_REPORTING_V2: 'GROUP_REPORTING_V2',
  HALF_TERM_ENABLED: 'HALF_TERM_ENABLED',
  HAS_PARENT_APP_ACCESS: 'HAS_PARENT_APP_ACCESS',
  LIKES_AND_COMMENTS: 'LIKES_AND_COMMENTS',
  LJ_REPORTS: 'LJ_REPORTS',
  MESSAGING: 'MESSAGING',
  MOBILE_PAYMENTS: 'MOBILE_PAYMENTS',
  MOBILE_PAYMENTS_BACS_CAPABILITY: 'MOBILE_PAYMENTS_BACS_CAPABILITY',
  OCCUPANCY_ENABLED: 'OCCUPANCY_ENABLED',
  OFFLINE_MODE: 'OFFLINE_MODE',
  PARENT_BOOKING_CHILD_LIVES: 'PARENT_BOOKING_CHILD_LIVES',
  PA_UPDATE_CHILD: 'PA_UPDATE_CHILD',
  PDF_AUTOMATION: 'PDF_AUTOMATION',
  PRACTITIONER_CHILD_ACCESS: 'PRACTITIONER_CHILD_ACCESS',
  REQUESTING_EXTRA_SESSIONS: 'REQUESTING_EXTRA_SESSIONS',
  STAFF_REGISTER: 'STAFF_REGISTER',
  USERFLOW: 'USERFLOW',
  VIDEO_ENABLED: 'VIDEO_ENABLED',
}

export enum FeatureFlags {
  ACCOUNTING_EXTENDED_EXPORT = 'ACCOUNTING_EXTENDED_EXPORT',
  AUTO_CREDIT_NOTING = 'AUTO_CREDIT_NOTING',
  COHORT_TRACKER = 'COHORT_TRACKER',
  DEPOSITS = 'DEPOSITS',
  DIARY_AUTOSEND = 'DIARY_AUTOSEND',
  ENQUIRIES = 'ENQUIRIES',
  FILES_MANAGEMENT = 'FILES_MANAGEMENT',
  FINANCE_AUTOMATION = 'FINANCE_AUTOMATION',
  FINANCE_MVP = 'FINANCE_MVP',
  FINANCE_V3 = 'FINANCE_V3',
  GROUP_REPORTING_V2 = 'GROUP_REPORTING_V2',
  HALF_TERM_ENABLED = 'HALF_TERM_ENABLED',
  HAS_PARENT_APP_ACCESS = 'HAS_PARENT_APP_ACCESS',
  LIKES_AND_COMMENTS = 'LIKES_AND_COMMENTS',
  LJ_REPORTS = 'LJ_REPORTS',
  MESSAGING = 'MESSAGING',
  MOBILE_PAYMENTS = 'MOBILE_PAYMENTS',
  MOBILE_PAYMENTS_BACS_CAPABILITY = 'MOBILE_PAYMENTS_BACS_CAPABILITY',
  OCCUPANCY_ENABLED = 'OCCUPANCY_ENABLED',
  OFFLINE_MODE = 'OFFLINE_MODE',
  PARENT_BOOKING_CHILD_LIVES = 'PARENT_BOOKING_CHILD_LIVES',
  PA_UPDATE_CHILD = 'PA_UPDATE_CHILD',
  PDF_AUTOMATION = 'PDF_AUTOMATION',
  PRACTITIONER_CHILD_ACCESS = 'PRACTITIONER_CHILD_ACCESS',
  REQUESTING_EXTRA_SESSIONS = 'REQUESTING_EXTRA_SESSIONS',
  STAFF_REGISTER = 'STAFF_REGISTER',
  USERFLOW = 'USERFLOW',
  VIDEO_ENABLED = 'VIDEO_ENABLED',
}

export const ADMIN_ROLES_GROUP = 0
export const ORGANIZATION_ROLES_GROUP = 1
export const NURSERY_ROLES_GROUP = 2

export const RolesDetails = {
  [ROLES.SUPER_ADMIN]: {
    label: i18n.t('module:Staff:Roles:superAdmin'),
    roleHierarchy: 99999,
    type: ADMIN_ROLES_GROUP,
    value: ROLES.SUPER_ADMIN,
  },
  [ROLES.ORGANIZATION_DIRECTOR]: {
    label: i18n.t('module:Staff:Roles:organizationDirector'),
    roleHierarchy: 8,
    type: ORGANIZATION_ROLES_GROUP,
    value: ROLES.ORGANIZATION_DIRECTOR,
  },
  [ROLES.ORGANIZATION_NATIONAL_ADMIN]: {
    label: i18n.t('module:Staff:Roles:organizationNationalAdmin'),
    roleHierarchy: 7,
    type: ORGANIZATION_ROLES_GROUP,
    value: ROLES.ORGANIZATION_NATIONAL_ADMIN,
  },
  [ROLES.ORGANIZATION_FINANCE_ADMIN]: {
    label: i18n.t('module:Staff:Roles:organizationFinanceAdmin'),
    roleHierarchy: 6,
    type: ORGANIZATION_ROLES_GROUP,
    value: ROLES.ORGANIZATION_FINANCE_ADMIN,
  },
  [ROLES.ORGANIZATION_LINE_MANAGER]: {
    label: i18n.t('module:Staff:Roles:organizationLineManager'),
    roleHierarchy: 6,
    type: ORGANIZATION_ROLES_GROUP,
    value: ROLES.ORGANIZATION_LINE_MANAGER,
  },
  [ROLES.NURSERY_MANAGER]: {
    label: i18n.t('module:Staff:Roles:nurseryManager'),
    roleHierarchy: 5,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.NURSERY_MANAGER,
  },
  [ROLES.NURSERY_ADMIN]: {
    label: i18n.t('module:Staff:Roles:nurseryAdmin'),
    roleHierarchy: 4,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.NURSERY_ADMIN,
  },
  [ROLES.DEPUTY_MANAGER]: {
    label: i18n.t('module:Staff:Roles:deputyManager'),
    roleHierarchy: 4,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.DEPUTY_MANAGER,
  },
  [ROLES.ROOM_LEADER]: {
    label: i18n.t('module:Staff:Roles:roomLeader'),
    roleHierarchy: 3,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.ROOM_LEADER,
  },
  [ROLES.SENIOR_TEACHER]: {
    label: i18n.t('module:Staff:Roles:seniorTeacher'),
    roleHierarchy: 2,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.SENIOR_TEACHER,
  },
  [ROLES.TEACHER]: {
    label: i18n.t('module:Staff:Roles:teacher'),
    roleHierarchy: 1,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.TEACHER,
  },
  [ROLES.NON_TEACHING]: {
    label: i18n.t('module:Staff:Roles:nonTeaching'),
    roleHierarchy: 0,
    type: NURSERY_ROLES_GROUP,
    value: ROLES.NON_TEACHING,
  },
}

export const ORDERED_ROLES = _.orderBy(RolesDetails, ['roleHierarchy'], ['desc'])

export const FEATURE_FLAGS_OPTIONS = [
  {
    label: i18n.t('module:Nurseries:FeatureFlags:cohortTracker'),
    value: FEATURE_FLAGS.COHORT_TRACKER,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:financeAutomation'),
    value: FEATURE_FLAGS.FINANCE_AUTOMATION,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:finance'),
    value: FEATURE_FLAGS.FINANCE_MVP,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:financeV3'),
    value: FEATURE_FLAGS.FINANCE_V3,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:diaryAutoSend'),
    value: FEATURE_FLAGS.DIARY_AUTOSEND,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:halfTermEnabled'),
    value: FEATURE_FLAGS.HALF_TERM_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:hasParentAppAccess'),
    value: FEATURE_FLAGS.HAS_PARENT_APP_ACCESS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:ljReports'),
    value: FEATURE_FLAGS.LJ_REPORTS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:occupancyEnabled'),
    value: FEATURE_FLAGS.OCCUPANCY_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:pdfAutomation'),
    value: FEATURE_FLAGS.PDF_AUTOMATION,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:staffRegister'),
    value: FEATURE_FLAGS.STAFF_REGISTER,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:autoCreditNoting'),
    value: FEATURE_FLAGS.AUTO_CREDIT_NOTING,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:accountingExtendedExport'),
    value: FEATURE_FLAGS.ACCOUNTING_EXTENDED_EXPORT,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:filesManagement'),
    value: FEATURE_FLAGS.FILES_MANAGEMENT,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:videoEnabled'),
    value: FEATURE_FLAGS.VIDEO_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:messaging'),
    value: FEATURE_FLAGS.MESSAGING,
  },
  {
    hideForOrganization: true,
    label: i18n.t('module:Nurseries:FeatureFlags:mobilePayments'),
    value: FEATURE_FLAGS.MOBILE_PAYMENTS,
  },
  {
    hideForOrganization: true,
    label: i18n.t('module:Nurseries:FeatureFlags:mobilePaymentsBacs'),
    value: FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:requestingExtraSessions'),
    value: FEATURE_FLAGS.REQUESTING_EXTRA_SESSIONS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:parentBookingChileLeaves'),
    value: FEATURE_FLAGS.PARENT_BOOKING_CHILD_LIVES,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:enquiries'),
    value: FEATURE_FLAGS.ENQUIRIES,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:deposits'),
    value: FEATURE_FLAGS.DEPOSITS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:userflow'),
    value: FEATURE_FLAGS.USERFLOW,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:groupReporting'),
    value: FEATURE_FLAGS.GROUP_REPORTING_V2,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:paUpdateChild'),
    value: FEATURE_FLAGS.PA_UPDATE_CHILD,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:likesAndComments'),
    value: FEATURE_FLAGS.LIKES_AND_COMMENTS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:offlineMode'),
    value: FEATURE_FLAGS.OFFLINE_MODE,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:practitionerChildAccess'),
    value: FEATURE_FLAGS.PRACTITIONER_CHILD_ACCESS,
  },
]
