import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { AgedDebtStatistics } from 'services/legacy/agedDebt/models'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { Chart, Flex, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledChartContainer } from './AgedDebtWidgetStyled'

interface AgedDebtWidgetViewProps {
  agedDebtStatistics: AgedDebtStatistics
  isFetching: boolean
}

const config = {
  chart: {
    height: 210,
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointWidth: 32,
    },
  },
  xAxis: {
    labels: {
      rotation: 0,
      style: {
        color: NEUTRAL_COLOURS.GRAY,
        font: null,
        fontSize: '9px',
        letterSpacing: '-0.3px',
      },
    },
    tickWidth: 0,
  },
  yAxis: {
    labels: {
      style: {
        color: NEUTRAL_COLOURS.GRAY,
        font: null,
        fontSize: '12px',
      },
    },
    stackLabels: {
      style: {
        color: NEUTRAL_COLOURS.BASIC,
        font: null,
        fontSize: '9px',
        fontWeight: 600,
      },
    },
    tickAmount: 5,
  },
} as const

const AgedDebtWidgetView: React.FC<AgedDebtWidgetViewProps> = ({ agedDebtStatistics, isFetching }) => {
  const { agedDebts, total } = agedDebtStatistics || {}

  const renderContent = () => {
    if (!agedDebts || !total) {
      return (
        <Widget.EmptyState
          icon="invoices"
          text1={i18n.t('module:Dashboard:Widgets:AgedDebtWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:AgedDebtWidget:emptyStateText2')}
        />
      )
    }

    const categories = _.map(_.keys(agedDebts), (item) => `${item} ${_.lowerFirst(i18n.t('global:Days'))}`)
    const chartData = [{
      color: getBrandingColor('primary-color-lighten-10'),
      data: _.values(agedDebts),
      dataLabels: {
        style: { color: NEUTRAL_COLOURS.GRAY },
      },
      name: i18n.t('module:Finance:Reports:AgedDebt:chartTooltipTitle'),
    }]

    return (
      <StyledChartContainer>
        <Chart.Column
          categories={categories}
          config={config}
          data={chartData}
          isCurrencyValue
        />
      </StyledChartContainer>
    )
  }

  return (
    <Widget>
      <Widget.Header
        label={(
          <Flex alignItems="center" gap="12px">
            <Typography fontSize={18} fontWeight={600}>
              {i18n.t('module:Dashboard:Widgets:AgedDebtWidget:title')}
            </Typography>
            <Link to={generateRoute('FINANCE.REPORT.AGED_DEBT')}>
              <Icon height={16} icon="chevron-right" />
            </Link>
          </Flex>
        )}
      />
      <Widget.Content isLoading={isFetching}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default AgedDebtWidgetView
