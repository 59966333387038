import i18n from 'translations'

import ExtraSessionRequestsWidget from './ExtraSessionRequestsWidgetContainer'

export const WIDGET_NAME = {
  component: ExtraSessionRequestsWidget,
  id: 'EXTRA_SESSION_REQUESTS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:ExtraSessionRequestsWidget:titleInSettings'),
}

export default ExtraSessionRequestsWidget
