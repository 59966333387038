import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { Invoice } from 'services/legacy/invoices/models'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { withAppService, withAppServiceProps } from 'services/app'
import { withInvoicesService, withInvoicesServiceProps } from 'services/legacy/invoices'

import { getCriteria } from 'module/Finance/FinanceInvoices/helpers'

import DraftInvoicesWidgetView from './DraftInvoicesWidgetView'

const GROUPS = { read: ['child'] }

type DraftInvoicesWidgetContainerProps = withAppServiceProps & withInvoicesServiceProps

const DraftInvoicesWidgetContainer: React.FC<DraftInvoicesWidgetContainerProps> = ({
  invoicesActions,
}) => {
  const [invoicesList, setInvoicesList] = useState<Invoice[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const criteria = getCriteria({ status: STATUS_TYPES.DRAFT })

    const params = {
      criteria,
      groups: GROUPS,
      limit: 20,
      order: {
        sortField: 'issueDate',
        sortOrder: 'DESC',
      },
    }

    invoicesActions.list(params, false, ({ data }) => {
      setInvoicesList(data)
      setIsLoading(false)
    })
  }, [])

  return (
    <DraftInvoicesWidgetView
      invoicesList={invoicesList}
      isLoading={isLoading}
    />
  )
}

const enhance = compose(
  withAppService,
  withInvoicesService,
)

export default enhance(DraftInvoicesWidgetContainer)
