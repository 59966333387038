import { Meta } from 'constants/service'

import { mergeData } from 'utils/data'

export interface BasicListInitialState {
  data: any
  error: any
  isFetching: boolean
  meta: Meta
}

export interface BasicSingleInitialState {
  data: any
  error: any
  isFetching: boolean
  isSubmitting: boolean
}

export const getBasicListInitialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export const getBasicSingleInitialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

interface BasicListReducerConstants {
  clear?: string
  failed?: string
  init?: string
  success?: string
}

interface PropsTypes {
  error?: any
  /** This is flag to merging results in paginated lists. Often used in examples with infinite scroll */
  mergeResult?: boolean

  payload?: any

  /** This is flag to disabling changing value like isFetching and isSubmitting, which are used to spinners in
   * application */
  silent?: boolean

  /** Redux action name */
  type: string
}

interface BasicSingleReducerConstants {
  clear?: string
  createFailed?: string
  createInit?: string
  createSuccess?: string
  itemFailed?: string
  itemInit?: string
  itemSuccess?: string
  removeFailed?: string
  removeInit?: string
  removeSuccess?: string
  updateFailed?: string
  updateInit?: string
  updateSuccess?: string
}

export const getBasicListReducers = (constants: BasicListReducerConstants, initialState, props) => {
  const [state = initialState, options] = props
  const { error, mergeResult, payload, silent, type } = options as PropsTypes

  switch (type) {
    case constants.init:
      return { ...state, error: initialState.error, isFetching: !silent }
    case constants.success:
      return {
        data: mergeData(state.data, payload.data, mergeResult),
        error: null,
        isFetching: false,
        meta: {
          ...state.meta,
          ...payload.meta,
        },
      }
    case constants.failed:
      return { ...state, error, isFetching: false }
    case constants.clear:
      return initialState
    default:
      return null
  }
}

export const getBasicSingleReducers = (constants: BasicSingleReducerConstants, initialState, props) => {
  const [state = initialState, options] = props
  const { error, payload, silent, type } = options as PropsTypes

  switch (type) {
    case constants.itemInit:
    case constants.removeInit:
      return {
        ...state,
        error: initialState.error,
        isFetching: !silent,
      }
    case constants.itemSuccess:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
      }
    case constants.removeSuccess:
      return {
        ...state,
        isFetching: false,
      }
    case constants.itemFailed:
    case constants.removeFailed:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case constants.createInit:
    case constants.updateInit:
      return {
        ...state,
        error: initialState.error,
        isSubmitting: true,
      }
    case constants.createSuccess:
    case constants.updateSuccess:
      return {
        ...state,
        isSubmitting: false,
      }
    case constants.createFailed:
    case constants.updateFailed:
      return {
        ...state,
        error,
        isSubmitting: false,
      }
    case constants.clear:
      return initialState
    default:
      return null
  }
}
