import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { Observation } from 'services/legacy/observations/models'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Hyperlink, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'

import { StyledHeader } from './ObservationsWidgetStyled'

interface ObservationsWidgetViewProps {
  isFetching: boolean
  observations: Observation[]
  pendingCount: number
}

const ObservationsWidgetView: React.FC<ObservationsWidgetViewProps> = ({
  isFetching,
  observations,
  pendingCount,
}) => {
  const draftStatus = OBSERVATION_STATUSES.DRAFT.value

  const renderSubtitle = ({
    childObservationsCount,
    previewChildObservations,
  }) => {
    if (!childObservationsCount) {
      return null
    }

    if (1 < childObservationsCount) {
      return `${i18n.t('components:GroupAvatars:Group')} (${childObservationsCount})`
    }

    return previewChildObservations.length ? previewChildObservations[0].child.displayName : null
  }

  const renderListRow = (observation: Observation) => {
    const { childObservationsCount, id, observationDate, previewChildObservations } = observation
    const previewPersons = _.map(previewChildObservations, ({ child }) => child) || []

    return (
      <Widget.List.Row
        key={id}
        leftBottomColumn={(
          <Typography fontSize={14} ellipsis>
            {moment(observationDate).format(`L [${i18n.t('global:at')}] LT`)}
          </Typography>
        )}
        leftTopColumn={renderSubtitle(observation)}
        personsCount={childObservationsCount}
        previewPersons={previewPersons}
        to={generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: id })}
      />
    )
  }

  const renderContent = () => {
    if (!observations?.length) {
      return (
        <Widget.EmptyState
          icon="observations"
          text1={i18n.t('module:Dashboard:Widgets:ObservationsWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:ObservationsWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="4px 0" bold>
          {i18n.t('module:Dashboard:Widgets:ObservationsWidget:draftObservations')}
        </Typography>
        <Widget.List>
          {_.map(observations, renderListRow)}
        </Widget.List>
        <SeeMore to={`${generateRoute('LEARNING.OBSERVATIONS')}${draftStatus ? `?status=${draftStatus}` : ''}`} />
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:ObservationsWidget:titleInSettings')} />
      <Widget.Content isLoading={isFetching}>
        <Hyperlink
          to={`${generateRoute('LEARNING.OBSERVATIONS')}?status=${OBSERVATION_STATUSES.PENDING_APPROVAL.value}`}
          variant="div"
        >
          <StyledHeader>
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={40} lineHeight={48} bold>
              {pendingCount || 0}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={18}>
              {i18n.t('module:Dashboard:Widgets:ObservationsWidget:pendingApproval')}
            </Typography>
            <Icon height={16} icon="chevron-right" />
          </StyledHeader>
        </Hyperlink>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ObservationsWidgetView
