import React from 'react'

import { BasicModalProps } from 'modals'

import { Button, FooterActions, MinimalModalBox, Typography } from 'components'

import i18n from 'i18next'

import { StyledRoot } from './PeriodHasInvoiceModalStyled'

export interface PeriodHasInvoiceModalProps {
  onConfirm: () => void
  onProceedAnyway: () => void
}

const PeriodHasInvoiceModal: React.FC<BasicModalProps & PeriodHasInvoiceModalProps> = ({
  hideModal,
  onConfirm,
  onProceedAnyway,
}) => {
  const handleConfirm = () => {
    hideModal()
    onConfirm()
  }

  const handleProceedAnyway = () => {
    hideModal()
    onProceedAnyway()
  }

  return (
    <MinimalModalBox>
      <StyledRoot>
        <Typography align="center" fontSize={20} lineHeight={24} padding="0 20px">
          {i18n.t('modals:PeriodHasInvoice:content')}
          <br />
          <br />
          {i18n.t('modals:PeriodHasInvoice:content2')}
          <br />
          <br />
        </Typography>
        <FooterActions noBorder noMargin>
          <FooterActions.Item>
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Cancel')}
              inverted
              negativeMargins
              onClick={hideModal}
            />
          </FooterActions.Item>
          <FooterActions.Item>
            <Button
              hierarchy="secondary"
              label={i18n.t('modals:PeriodHasInvoice:proceedAnyway')}
              inverted
              negativeMargins
              onClick={handleProceedAnyway}
            />
          </FooterActions.Item>
          <FooterActions.Item>
            <Button
              color="tertiary"
              label={i18n.t('modals:PeriodHasInvoice:goToInvoices')}
              inverted
              negativeMargins
              onClick={handleConfirm}
            />
          </FooterActions.Item>
        </FooterActions>
      </StyledRoot>
    </MinimalModalBox>
  )
}

export default PeriodHasInvoiceModal
