import React from 'react'

import { BasicViewProps, Option } from 'constants/models'
import { Nursery } from 'services/nurseries/models'
import { NURSERY_SESSIONS_FILTER, NURSERY_SESSIONS_FILTER_OPTIONS } from 'services/product/nurserySessionsV3/constants'
import { PaginationDetails } from 'constants/pagination'

import { generateRoute } from 'utils/routing'

import {
  Button,
  Callout,
  EmptyState,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { getTableColumns } from './SessionsV3Helpers'

interface SessionsV3ViewProps extends BasicViewProps {
  FinanceAutomationGranted?: boolean
  isEmpty?: boolean
  isOrganizationContext: boolean
  nursery: Nursery
  onSortChange?: (key: string) => void
  onStatusFilterChange?: (status?: Option) => void
  pagination?: PaginationDetails
  sessions: any
  sortField?: string
  sortOrder?: string
  statusFilter?: Option
}

const SessionsV3View: React.FC<SessionsV3ViewProps> = ({
  FinanceAutomationGranted,
  errorMessages,
  isEmpty,
  isLoading,
  isOrganizationContext,
  nursery,
  onSortChange,
  onStatusFilterChange,
  pagination,
  sessions,
  sortField,
  sortOrder,
  statusFilter,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination {...pagination} />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!sessions.length) {
      return (
        <EmptyState
          actions={!isOrganizationContext
            && !(NURSERY_SESSIONS_FILTER.ARCHIVED === statusFilter?.value)
            && (
              <Button
                icon="plus"
                label={i18n.t('module:Management:Finance:SessionsV3:addButtonLabel')}
                to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS.ADD')}
              />
            )}
          icon="sessions"
          text1={NURSERY_SESSIONS_FILTER.ARCHIVED === statusFilter?.value
            ? i18n.t('module:Management:Finance:SessionsV3:archiveEmptyStateText')
            : i18n.t('module:Management:Finance:SessionsV3:emptyStateText')}
        />
      )
    }

    const COLUMNS = getTableColumns(FinanceAutomationGranted)

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={sessions}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        to: isOrganizationContext
          ? generateRoute('SETTINGS.SESSIONS.NURSERY.SESSIONS.ADD', {
            nurseryId: nursery?.id,
          })
          : generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS.ADD'),
      }]}
    />
  )

  const renderBody = (
    <React.Fragment>
      <Callout content={errorMessages} error />
      {!isEmpty && (
        <Typography margin="20px 0">
          {FinanceAutomationGranted
            ? i18n.t('module:Management:Finance:SessionsV3:InformationMessage:financeAutomation')
            : i18n.t('module:Management:Finance:SessionsV3:InformationMessage:default')}
        </Typography>
      )}
      {!isEmpty && (
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={NURSERY_SESSIONS_FILTER_OPTIONS}
                searchable={false}
                value={statusFilter}
                onChange={onStatusFilterChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      {renderContent()}
    </React.Fragment>
  )

  if (isOrganizationContext) {
    return (
      <Section
        actions={actions}
        title={i18n.t('module:Management:Finance:SessionsV3:sessionsFor', { name: nursery?.name })}
      >
        {renderBody}
      </Section>
    )
  }

  return (
    <Page.Section
      actions={actions}
      title={i18n.t('module:Management:Finance:SessionsV3:title')}
    >
      {renderBody}
    </Page.Section>
  )
}

export default SessionsV3View
