import i18n from 'translations'

import ViewingsWidget from './ViewingsWidgetContainer'

export const WIDGET_NAME = {
  component: ViewingsWidget,
  id: 'VIEWINGS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:ViewingsWidget:titleInSettings'),
}

export default ViewingsWidget
