import {
  CLEAR_INVOICES_FORECAST,
  INVOICES_FORECAST,
  INVOICES_FORECAST_FAILED,
  INVOICES_FORECAST_SUCCESS,
  SEND_INVOICE_FORECAST_REPORT,
  SEND_INVOICE_FORECAST_REPORT_FAILED,
  SEND_INVOICE_FORECAST_REPORT_SUCCESS,
} from './constants'

export const getForecast = (params, headers = {}) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: INVOICES_FORECAST,
  })

  invoicesApiClient.forecast(params, headers).then(
    (data) => {
      dispatch({
        payload: data,
        type: INVOICES_FORECAST_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: INVOICES_FORECAST_FAILED,
      })
    },
  )
}

export const clearInvoicesForecast = () => ({
  type: CLEAR_INVOICES_FORECAST,
})

export const sendAnnualRevenueReport = (params, onSuccess, onFailed) => ({
  invoicesApiClient,
}) => (dispatch) => {
  dispatch({ type: SEND_INVOICE_FORECAST_REPORT })

  return invoicesApiClient.sendAnnualReport(params).then(
    (data) => {
      dispatch({ type: SEND_INVOICE_FORECAST_REPORT_SUCCESS })

      onSuccess?.(data)
    },
    ({ response }) => {
      dispatch({ type: SEND_INVOICE_FORECAST_REPORT_FAILED })

      onFailed?.(response)
    },
  )
}
