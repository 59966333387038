import React from 'react'

import { NURSERY_SESSIONS_FILTER_OPTIONS } from 'services/nurserySessions/constants'
import { TableColumn } from 'components/Table/Table'
import { BasicViewProps, Option } from 'constants/models'
import { PaginationDetails } from 'constants/pagination'

import {
  Button,
  Callout,
  EmptyState,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

interface SessionsV2ViewProps extends BasicViewProps {
  FinanceAutomationGranted?: boolean
  isEmpty?: boolean
  onSortChange?: (key: string) => void
  onStatusFilterChange?: (status?: Option) => void
  pagination?: PaginationDetails
  routePrefix?: string
  sessions: any
  sortField?: string
  sortOrder?: string
  statusFilter?: Option
}

const SessionsV2View: React.FC<SessionsV2ViewProps> = ({
  FinanceAutomationGranted,
  errorMessages,
  isEmpty,
  isLoading,
  onSortChange,
  onStatusFilterChange,
  pagination,
  routePrefix,
  sessions,
  sortField,
  sortOrder,
  statusFilter,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination {...pagination} />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          actions={(
            <Button
              icon="plus"
              label={i18n.t('module:Management:Finance:Sessions:addButtonLabel')}
              to={`${routePrefix}/finance-settings/sessions/add`}
            />
          )}
          icon="sessions"
          text1={i18n.t('module:Management:Finance:Sessions:emptyStateText')}
        />
      )
    }

    const columns: TableColumn[] = [
      {
        align: 'left',
        field: 'name',
        sortable: true,
        title: i18n.t('module:Management:Finance:Sessions:Table:Session:label'),
      },
      {
        field: 'startTime',
        sortable: true,
        title: i18n.t('module:Management:Finance:Sessions:Table:Start:label'),
      },
      {
        field: 'endTime',
        sortable: true,
        title: i18n.t('module:Management:Finance:Sessions:Table:Finish:label'),
      },
    ]

    if (FinanceAutomationGranted) {
      columns.concat([
        {
          field: 'costUnderTwo',
          title: i18n.t('module:Management:Finance:Sessions:Table:Under2s:label'),
        },
        {
          field: 'costTwoYears',
          title: i18n.t('module:Management:Finance:Sessions:Table:TwoYearOld:label'),
        },
        {
          field: 'costTwoYearsAbove',
          title: i18n.t('module:Management:Finance:Sessions:Table:ThreeToFiveYearOld:label'),
        },
      ])
    }

    columns.push({ field: 'edit' })

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={sessions}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: `${routePrefix}/sessions/add` }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Sessions"
    >
      <Callout content={errorMessages} error />
      {!isEmpty && (
        <Typography margin="20px 0">
          {FinanceAutomationGranted
            ? i18n.t('module:Management:Finance:Sessions:InformationMessage:financeAutomation')
            : i18n.t('module:Management:Finance:Sessions:InformationMessage:default')}
        </Typography>
      )}
      {!isEmpty && (
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={NURSERY_SESSIONS_FILTER_OPTIONS}
                searchable={false}
                value={statusFilter}
                onChange={onStatusFilterChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      {renderContent()}
    </Page.Section>
  )
}

export default SessionsV2View
