import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { INVOICE_TYPES_DROPDOWN } from 'services/legacy/invoices/constants'

import { EVENTS, logEvent } from 'analytics'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withInvoicesService, withInvoicesServiceProps } from 'services/legacy/invoices'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import {
  withNurseryPaymentTypesService,
  withNurseryPaymentTypesServiceProps,
} from 'services/legacy/nurseryPaymentTypes'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withRouter, withRouterProps } from 'services/router'

import { getInvoices } from './ChildFinanceDetailsInvoicesHelpers'
import ChildFinanceDetailsInvoicesView from './ChildFinanceDetailsInvoicesView'

const groups = {
  read: [
    'invoice.payments',
    'invoice.child',
    'child',
    'child.childBankDetail',
    'childBankDetail',
  ],
}
const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  invoicesListState,
  invoicesSelectors,
  nurseryPaymentTypesListState,
}) => ({
  cannotBePaidOverTheParentApp: childSelectors.getCannotBePaidOverTheParentApp(state),
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(invoicesListState),
  invoiceList: invoicesSelectors.getInvoiceListSelector(state),
  invoiceListMeta: invoicesSelectors.getInvoiceListMetaSelector(state),
  isFetching: appSelectors.getIsFetching(invoicesListState, nurseryPaymentTypesListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type ChildFinanceDetailsInvoicesContainerProps = withAppServiceProps
  & withChildServiceProps
  & withInvoicesServiceProps
  & withModalServiceProps
  & withPaginationUtilsProps
  & withNurseryPaymentTypesServiceProps
  & withRouteUtilsProps
  & withSortUtilsProps
  & withRouterProps

const DEFAULT_SORT_FIELD = 'issueDate'

const ChildFinanceDetailsInvoicesContainer: React.FC<ChildFinanceDetailsInvoicesContainerProps & PropsFromRedux> = ({
  cannotBePaidOverTheParentApp,
  childActions,
  childFinanceDetails,
  errorMessages,
  invoiceList,
  invoiceListMeta,
  invoicesActions,
  invoicesListState,
  invoicesSelectors,
  isFetching,
  location,
  nurseryPaymentTypesActions,
  paginationUtils,
  params,
  setLocationQuery,
  sortingUtils,
}) => {
  const { query } = location || {}
  const { childId } = params
  const { onSortChange, sortField, sortOrder } = sortingUtils
  const { getPageCount, onPageChange, page, perPage } = paginationUtils

  const [filters, setFilters] = useState({
    endDate: query?.endDate,
    invoiceEndDate: query?.invoiceEndDate,
    invoiceStartDate: query?.invoiceStartDate,
    startDate: query?.startDate,
    status: query?.status ? query?.status?.split(',') : [],
    type: query?.type,
  })

  const fetch = () => {
    const criteria = invoicesSelectors.getCriteriaSelector({ ...filters, child: childId })

    setLocationQuery({
      ...filters,
      status: filters.status.join(','),
    })

    const listParams = {
      criteria,
      groups,
      order: { sortField: sortField || DEFAULT_SORT_FIELD, sortOrder },
      page,
    }

    invoicesActions.list(listParams)
  }

  useEffect(() => {
    onPageChange(fetch)(1)
  }, [filters])

  useEffect(() => {
    nurseryPaymentTypesActions.list()
    childActions.getFinanceDetails({
      params: [childId, {
        groups: CHILD_FINANCE_DETAILS_GROUPS,
      }],
    })

    logEvent(EVENTS.INVOICE_LIST_PAGE_VIEWED, { context: 'child profile' })

    return () => {
      nurseryPaymentTypesActions.clearList()
      invoicesActions.clear()
      childActions.clearFinanceDetails()
    }
  }, [])

  const handleSortChange = () => {
    onSortChange(onPageChange(fetch)(1))
  }

  const handleDateChange = (dateRange) => {
    setFilters((prevState) => ({
      ...prevState,
      endDate: dateRange?.[1] ? moment(dateRange[1]).format(DEFAULT_DATE_FORMAT) : undefined,
      startDate: dateRange?.[0] ? moment(dateRange[0]).format(DEFAULT_DATE_FORMAT) : undefined,
    }))

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child profile', type: 'date' })
  }

  const handleStatusChange = (newStatuses) => {
    setFilters((prevState) => ({
      ...prevState,
      status: _.map(newStatuses, (status) => status.value),
    }))

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child profile', type: 'status' })
  }

  const handleTypeChange = (newType) => {
    setFilters((prevState) => ({
      ...prevState,
      type: newType ? newType.value : null,
    }))

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child', type: 'invoice type' })
  }

  const handleInvoiceDateChange = (invoiceDateRange) => {
    setFilters((prevState) => ({
      ...prevState,
      invoiceEndDate: invoiceDateRange[0],
      invoiceStartDate: invoiceDateRange[1],
    }))
  }

  const { endDate, invoiceEndDate, invoiceStartDate, startDate, status, type } = filters

  const invoices = getInvoices(invoiceList)
  const isEmpty = 0 === invoicesListState?.data?.length && !startDate && !endDate && !status
  const pageCount = getPageCount(invoiceListMeta ? invoiceListMeta.totalResults : null)
  const dateRange = [startDate, endDate]
  const invoiceDateRange = [invoiceStartDate, invoiceEndDate]

  return (
    <ChildFinanceDetailsInvoicesView
      cannotBePaidOverTheParentApp={cannotBePaidOverTheParentApp}
      childFinanceDetails={childFinanceDetails}
      childId={childId}
      dateRange={dateRange}
      errorMessages={errorMessages}
      invoiceDateRange={invoiceDateRange}
      invoices={invoices}
      isEmpty={isEmpty}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      sortField={sortField}
      sortOrder={sortOrder}
      status={status}
      totalResults={invoiceListMeta?.totalResults}
      type={type}
      typeOptions={INVOICE_TYPES_DROPDOWN}
      onDateChange={handleDateChange}
      onInvoiceDateChange={handleInvoiceDateChange}
      onPageChange={onPageChange(fetch)}
      onSortChange={handleSortChange}
      onStatusChange={handleStatusChange}
      onTypeChange={handleTypeChange}
    />
  )
}

const enhance = compose(
  withRouter,
  withChildService,
  withAppService,
  withInvoicesService,
  withModalService,
  withNurseryPaymentTypesService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connector,
)

export default enhance(ChildFinanceDetailsInvoicesContainer)
