import _ from 'lodash'

import React from 'react'

import { StyledBadge, StyledHeader } from './WidgetStyled'

interface WidgetHeaderProps {
  badge?: number
  label: string | React.ReactNode
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({ badge, label }) => (
  <StyledHeader>
    {label}
    {_.isNumber(badge) ? <StyledBadge>{badge}</StyledBadge> : null}
  </StyledHeader>
)

export default WidgetHeader
