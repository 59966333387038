import _ from 'lodash'

import React from 'react'

import { STATUS_TYPES } from 'services/legacy/invoices/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { Invoice } from 'services/legacy/invoices/models'

import { generateRoute } from 'utils/routing'

import { Currency, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'

interface DraftInvoicesWidgetViewProps {
  invoicesList: Invoice[]
  isLoading: boolean
}

const DraftInvoicesWidgetView: React.FC<DraftInvoicesWidgetViewProps> = ({
  invoicesList,
  isLoading,
}) => {
  const renderListItem = (item: Invoice) => {
    const { child, id: invoiceId, total } = item
    const { id: childId, name } = child

    return (
      <Widget.List.Row
        key={invoiceId}
        leftTopColumn={name}
        persons={[child]}
        rightColumn={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            <Currency value={total} sameSize />
          </Typography>
        )}
        to={generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId })}
      />
    )
  }

  const renderContent = () => {
    if (!invoicesList?.length) {
      return (
        <Widget.EmptyState
          icon="invoices"
          text1={i18n.t('module:Dashboard:Widgets:DraftInvoicesWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:DraftInvoicesWidget:emptyStateText2')}
        />
      )
    }

    return (
      <Widget.List>
        {_.map(invoicesList, renderListItem)}
        {invoicesList.length && (
          <SeeMore to={`${generateRoute('FINANCE.INVOICES')}?status=${STATUS_TYPES.DRAFT}`} />
        )}
      </Widget.List>
    )
  }

  return (
    <Widget>
      <Widget.Header badge={invoicesList.length} label={i18n.t('module:Dashboard:Widgets:DraftInvoicesWidget:title')} />
      <Widget.Content isLoading={isLoading}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default DraftInvoicesWidgetView
