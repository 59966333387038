import _ from 'lodash'

import React from 'react'
import { ReactSVG } from 'react-svg'

import { generateRoute } from 'utils/routing'

import { Button, EmptyState, Page, Spinner } from 'components'

import i18n from 'translations'

import WidgetContainer from '../components/WidgetContainer'

interface NurseryDashboardViewProps {
  authAccessMap: { section: Record<string, boolean> }
  isFetching: boolean
  isOffline: boolean
  selectedWidgets: {
    component: React.ComponentType
    id: string
    label: string
  }[]
}

const NurseryDashboardView: React.FC<NurseryDashboardViewProps> = ({
  authAccessMap,
  isFetching,
  isOffline,
  selectedWidgets,
}) => {
  if (isFetching) {
    if (isOffline) {
      return (
        <EmptyState
          icon="offline"
          text1={i18n.t('global:youAreOffline')}
        />
      )
    }

    return (
      <Page>
        <Page.Content>
          <Spinner />
        </Page.Content>
      </Page>
    )
  }

  if (selectedWidgets && !selectedWidgets.length) {
    return (
      <Page>
        <Page.Content>
          <EmptyState
            iconComponent={(
              <ReactSVG src="/images/empty-blossomboard.svg" />
            )}
            text1={i18n.t('module:Dashboard:NurseryDashboard:emptyStateText')}
            text2={(
              <Button
                label={i18n.t('module:Dashboard:NurseryDashboard:customiseWidgets')}
                to={generateRoute('MANAGEMENT.BLOSSOMBOARD')}
              />
            )}
          />
        </Page.Content>
      </Page>
    )
  }

  return (
    <Page alwaysExpanded>
      <Page.Content>
        <WidgetContainer>
          {_.map(selectedWidgets, ({
            component: WidgetComponent,
            id,
          }) => authAccessMap.section[id] && (
            <WidgetComponent
              key={id}
            />
          ))}
        </WidgetContainer>
      </Page.Content>
    </Page>
  )
}

export default NurseryDashboardView
