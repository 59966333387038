import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withSecurityService } from 'services/security'

import DashboardView from './DashboardView'

const mapState = (state: RootState, { securitySelectors }) => ({
  hasOrganizationAccess: securitySelectors.hasOrganizationAccess(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isSuperAdmin: securitySelectors.isSuperAdmin(state),
})

const connector = connect(mapState)

export type PropsFromRedux = ConnectedProps<typeof connector>

const DashboardContainer: React.FC<PropsFromRedux> = ({
  hasOrganizationAccess,
  isAdministrationContext,
  isOrganizationContext,
  isSuperAdmin,
}) => (
  <DashboardView
    hasOrganizationAccess={hasOrganizationAccess}
    isAdministrationContext={isAdministrationContext}
    isOrganizationContext={isOrganizationContext}
    isSuperAdmin={isSuperAdmin}
  />
)

const enhance = compose(
  withSecurityService,
  connector,
)

export default enhance(DashboardContainer)
