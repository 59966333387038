import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { LIST_INJURY_RECORDS, LIST_INJURY_RECORDS_FAILED, LIST_INJURY_RECORDS_SUCCESS } from './constants'

export const list = (
  options: BasicActionOptionsProps,
) => ({ injuriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_INJURY_RECORDS_FAILED,
    init: LIST_INJURY_RECORDS,
    success: LIST_INJURY_RECORDS_SUCCESS,
  },
  options,
  service: injuriesApiClient,
  serviceMethod: 'list',
})
