import i18n from 'translations'

import StaffingOverviewWidgetContainer from './StaffingOverviewWidgetContainer'

export const WIDGET_NAME = {
  component: StaffingOverviewWidgetContainer,
  id: 'STAFFING_OVERVIEW_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:StaffingOverviewWidget:titleInSettings'),
}

export default StaffingOverviewWidgetContainer
