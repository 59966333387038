import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import { StartersAndLeaversWidgetListState } from './constants'

export interface withStartersAndLeaversWidgetServiceProps {
  startersAndLeaversWidgetActions: typeof actions
  startersAndLeaversWidgetListState: StartersAndLeaversWidgetListState
}

const withStartersAndLeaversWidgetService = <P extends withStartersAndLeaversWidgetServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ startersAndLeaversWidget }) => ({ startersAndLeaversWidget })

  const mapDispatch = injectActions('startersAndLeaversWidgetActions', actions)

  const Component = ({ startersAndLeaversWidget, startersAndLeaversWidgetActions, ...others }) => (
    <WrappedComponent
      startersAndLeaversWidgetActions={startersAndLeaversWidgetActions}
      startersAndLeaversWidgetListState={startersAndLeaversWidget.list}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withStartersAndLeaversWidgetService
