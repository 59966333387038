import styled from 'styled-components'

import { StyledLoader } from 'components/InfiniteScroll/InfiniteScrollStyled'
import { StyledContainer, StyledContainerWrapper } from 'components/SearchBar/SearchBarStyled'
import { NEUTRAL_COLOURS } from 'constants/colors'

const SHOW_SCROLLBAR_WHEN_MORE_THAN = 6

interface StyledChildrenContainerProps {
  $childrenListLength: number
}

export const StyledChildrenContainer = styled.div<StyledChildrenContainerProps>`
  padding: 8px 24px;
  margin-top: 8px;
  min-height: calc(100% - 48px);
  overflow-y: hidden;

  ${({ $childrenListLength }) => SHOW_SCROLLBAR_WHEN_MORE_THAN < $childrenListLength && `
    overflow-y: scroll;
    padding-right: 12px;
  `}

  & > div > div {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-gap: 10px;
  }
  
  ${StyledLoader} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const StyledChildBox = styled.div`
  padding: 8px;
  max-width: 100%;
  overflow-x: hidden;
  border-radius: 8px;
  border: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
`

export const StyledSearchWrapper = styled.div`
  ${StyledContainerWrapper} {
    height: 40px;
    min-height: 40px;
  }

  ${StyledContainer} {
    padding: 0 24px;
    height: 40px;
  }
`
