import React, { PropsWithChildren } from 'react'

import { StyledWidgetContainer } from './WidgetContainerStyled'

const WidgetContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledWidgetContainer>
    {children}
  </StyledWidgetContainer>
)

export default WidgetContainer
