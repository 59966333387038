import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import * as agedDebtSelectors from './selectors'
import actions from './actions'

export interface withAgedDebtServiceProps {
  agedDebtActions: typeof actions
  // @TODO
  agedDebtListChildrenState: any
  agedDebtSelectors: typeof agedDebtSelectors
  // @TODO
  agedDebtStatisticsState: any
}

const withAgedDebtService = <P extends withAgedDebtServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ agedDebt }) => ({ agedDebt })

  const mapDispatch = injectActions('agedDebtActions', actions)

  const Component = ({ agedDebt, agedDebtActions, ...others }) => (
    <WrappedComponent
      agedDebtActions={agedDebtActions}
      agedDebtListChildrenState={agedDebt.listChildren}
      agedDebtSelectors={agedDebtSelectors}
      agedDebtStatisticsState={agedDebt.statistics}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAgedDebtService
