import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { Child } from 'services/child/models'
import { AgedDebtStatistics } from 'services/legacy/agedDebt/models'
import { BasicSingleResponse } from 'constants/service'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withAgedDebtService, withAgedDebtServiceProps } from 'services/legacy/agedDebt'

import TopOutstandingAccountsWidgetView from './TopOutstandingAccountsWidgetView'

const GROUPS = {
  read: [
    'child',
    'child.finance',
  ],
}

type TopOutstandingAccountsWidgetContainerProps = withAppServiceProps
  & withChildServiceProps
  & withAgedDebtServiceProps

const TopOutstandingAccountsWidgetContainer: React.FC<TopOutstandingAccountsWidgetContainerProps> = ({
  agedDebtActions,
  childActions,
  childSelectors,
}) => {
  const [childrenList, setChildrenList] = useState<Child[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalOutstanding, setTotalOutstanding] = useState<number>(0)

  useEffect(() => {
    const params = {
      criteria: childSelectors.getCriteria({
        onlyOutstanding: true,
      }),
      groups: GROUPS,
      limit: 10,
      order: {
        sortField: 'balance',
        sortOrder: 'ASC',
      },
    }

    agedDebtActions.getWidgetStatistics({
      onSuccess: (response: BasicSingleResponse<AgedDebtStatistics>) => {
        setTotalOutstanding(response.data.total)
      },
      onlyData: true,
    })

    childActions.list({
      mergeResult: false,
      onSuccess: ({ data }) => {
        setChildrenList(data)
        setIsLoading(false)
      },
      onlyData: true,
      params,
    })

    return () => {
      childActions.clear()
    }
  }, [])

  return (
    <TopOutstandingAccountsWidgetView
      childrenList={childrenList}
      isLoading={isLoading}
      totalOutstanding={totalOutstanding}
    />
  )
}

const enhance = compose(
  withAppService,
  withChildService,
  withAgedDebtService,
)

export default enhance(TopOutstandingAccountsWidgetContainer)
