import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withAgedDebtService, withAgedDebtServiceProps } from 'services/legacy/agedDebt'
import appSelectors from 'services/app/selectors'
import * as agedDebtSelectors from 'services/legacy/agedDebt/selectors'

import AgedDebtWidgetView from './AgedDebtWidgetView'

const mapState = (state: RootState, { agedDebtStatisticsState }) => ({
  agedDebtStatistics: agedDebtSelectors.getAgedDebtStatisticsDataSelector(state),
  isFetching: appSelectors.getIsFetching(agedDebtStatisticsState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AgedDebtWidgetContainerProps = PropsFromRedux & withAgedDebtServiceProps

const AgedDebtWidgetContainer: React.FC<AgedDebtWidgetContainerProps> = ({
  agedDebtActions,
  agedDebtStatistics,
  isFetching,
}) => {
  useEffect(() => {
    agedDebtActions.getWidgetStatistics()

    return () => {
      agedDebtActions.clearStatistics()
    }
  }, [])

  return (
    <AgedDebtWidgetView
      agedDebtStatistics={agedDebtStatistics}
      isFetching={isFetching}
    />
  )
}

const enhance = compose(
  withAgedDebtService,
  connector,
)

export default enhance(AgedDebtWidgetContainer)
