import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as registerSelectors from './selectors'

export interface withRegisterServiceProps {
  registerActions: typeof actions
  // @TODO
  registerListState: any
  registerSelectors: typeof registerSelectors
  // @TODO
  registerState: any
}

const withRegistersService = <P extends withRegisterServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = (state) => ({
    register: registerSelectors.getRegisterSelector(state),
  })

  const mapDispatch = injectActions('registerActions', actions)

  const RegisterService = (props) => {
    const { register, registerActions, ...others } = props

    const registerState = {
      ...register,
      isEmpty: register.data && 0 === register.data.length,
    }

    return (
      <WrappedComponent
        registerActions={registerActions}
        registerListState={registerState.list}
        registerSelectors={registerSelectors}
        registerState={registerState}
        {...others as P}
      />
    )
  }

  RegisterService.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(RegisterService)
}

export default withRegistersService
