import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { Option } from 'constants/models'

import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'

import {
  Avatar,
  Button,
  Callout,
  Checkbox,
  DatePicker,
  EmptyState,
  FooterActions,
  Hyperlink,
  Icon,
  InfiniteDropdowns,
  InfiniteScroll,
  ModalBox,
  Pagination,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledContainer, StyledIconWrapper, StyledTableContainer } from './DailyDiaryModalStyled'

export type Filters = {
  date: moment.Moment
  room: string | number | null
}

export const COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'child.firstName',
    sortable: true,
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:child'),
    width: '45%',
  },
  {
    field: 'sent',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:sent'),
    width: '15%',
  },
  {
    field: 'lastSentAt',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:lastSentOn'),
    width: '25%',
  },
  {
    field: 'send',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:send'),
    width: '15%',
  },
]

interface DailyDiaryModalViewProps {
  all: boolean
  autoSendDiarySetting: boolean
  dailyDiaries: number[]
  data: any
  error: string
  filters: Filters
  isDiaryAutoSendEnabled: boolean
  isExporting: boolean
  isFetching: boolean
  isSubmitDisabled: boolean
  onCancelClick: () => void
  onDateChange: (date: moment.Moment) => void
  onPageChange: (page: number) => void
  onRoomChange: (room: Option) => void
  onSelectAllClick: (value: boolean) => void
  onSelectItemClick: (value: boolean, id: number) => void
  onSortChange: (key: string) => void
  onSubmit: () => void
  page: number
  pageCount: number
  skipIds: number[]
  sortField: string
  sortOrder: string
  totalResults: number
}

const DailyDiaryModalView: React.FC<DailyDiaryModalViewProps> = ({
  all,
  autoSendDiarySetting,
  dailyDiaries,
  data,
  error,
  filters,
  isDiaryAutoSendEnabled,
  isExporting,
  isFetching,
  isSubmitDisabled,
  onCancelClick,
  onDateChange,
  onPageChange,
  onRoomChange,
  onSelectAllClick,
  onSelectItemClick,
  onSortChange,
  onSubmit,
  page,
  pageCount,
  skipIds,
  sortField,
  sortOrder,
  totalResults,
}) => {
  const getTableData = () => {
    if (!data?.length) {
      return []
    }

    return _.map(data, ({ approved, child, id, updatedAt }) => ({
      child: (
        <Avatar
          avatarSize="small"
          initials={[child.firstName, child.surname]}
          src={child.photo}
          title={(
            <Typography fontSize={14} primary>
              {`${child.firstName} ${child.surname}`}
            </Typography>
          )}
        />
      ),
      lastSentAt: approved && moment(updatedAt).format(DISPLAY_SHORT_DATE_FORMAT),
      send: (
        <Checkbox
          value={
            all
            || (skipIds?.length && !_.includes(skipIds, id))
            || _.includes(dailyDiaries, id)
          }
          centered
          onChange={(value: boolean) => onSelectItemClick(value, id)}
        />
      ),
      sent: approved && (
        <StyledIconWrapper>
          <Icon color={getBrandingColor('tertiary-color')} icon="check" />
        </StyledIconWrapper>
      ),
    }))
  }

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <InfiniteDropdowns.Rooms
            value={filters.room}
            onChange={onRoomChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <DatePicker
            value={filters.date}
            onChange={onDateChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <Checkbox
            disabled={!data?.length}
            label={i18n.t('global:selectAll')}
            value={all}
            onChange={onSelectAllClick}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <StyledContainer>
          <Spinner />
        </StyledContainer>
      )
    }

    if (!data?.length) {
      return (
        <StyledContainer>
          <EmptyState
            icon="daily-diary"
            text1={i18n.t('module:Modals:DailyDiary:emptyState')}
          />
        </StyledContainer>
      )
    }

    return (
      <React.Fragment>
        <Pagination
          titlePlural={i18n.t('module:Modals:DailyDiary:Pagination:plural')}
          titleSingular={i18n.t('module:Modals:DailyDiary:Pagination:singular')}
          totalResults={totalResults}
        />
        <StyledTableContainer id="dailyDiariesScrollableTarget">
          <InfiniteScroll
            dataLength={data?.length || 0}
            hasMore={page < pageCount}
            isFetching={isFetching}
            next={() => onPageChange((+page) + 1)}
            scrollableTarget="dailyDiariesScrollableTarget"
            lightSpinner
          >
            <Table
              columns={COLUMNS}
              data={getTableData()}
              minWidth={0}
              sortField={sortField}
              sortOrder={sortOrder}
              disableSticky
              noMargin
              onSortChange={onSortChange}
            />
          </InfiniteScroll>
        </StyledTableContainer>
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Item>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Cancel')}
          negativeMargins
          onClick={onCancelClick}
        />
      </FooterActions.Item>
      <FooterActions.Item>
        <Button
          disabled={isSubmitDisabled}
          isLoading={isExporting}
          label={i18n.t('module:Modals:DailyDiary:approveDiary')}
          negativeMargins
          onClick={onSubmit}
        />
      </FooterActions.Item>
    </FooterActions>
  )

  return (
    <ModalBox autoHeight noHeader>
      <Callout content={error} error />
      {renderToolbar()}
      {isDiaryAutoSendEnabled && (
        <Typography>
          {i18n.t(`module:Modals:DailyDiary:${autoSendDiarySetting ? 'infoAutomatic' : 'infoManual'}`, {
            components: [
              <Hyperlink
                target="_blank"
                to={generateRoute('MANAGEMENT.PARENT_APP.PARENT_COMMUNICATION')}
                variant="span"
                primary
              />,
            ],
          })}
        </Typography>
      )}
      {renderContent()}
      {renderFooter()}
    </ModalBox>
  )
}

export default DailyDiaryModalView
