import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as membershipsShiftsSelectors from './selectors'

export interface withMembershipsShiftsServiceProps {
  membershipsShiftsActions: typeof actions
  membershipsShiftsListState: any // @TODO
  membershipsShiftsSelectors: typeof membershipsShiftsSelectors
  membershipsShiftsSingleState: any // @TODO
  membershipsShiftsStatisticsState: any // @TODO
}

const withMembershipsShiftsService = <P extends withMembershipsShiftsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ membershipsShifts }) => ({ membershipsShifts })

  const mapDispatch = injectActions('membershipsShiftsActions', actions)

  const Component = ({ membershipsShifts, membershipsShiftsActions, ...others }) => (
    <WrappedComponent
      membershipsShiftsActions={membershipsShiftsActions}
      membershipsShiftsListState={membershipsShifts.list}
      membershipsShiftsSelectors={membershipsShiftsSelectors}
      membershipsShiftsSingleState={membershipsShifts.single}
      membershipsShiftsStatisticsState={membershipsShifts.statistics}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMembershipsShiftsService
