import styled from 'styled-components'
import { Property } from 'csstype'

export interface StyledFlexProps {
  alignItems?: Property.AlignItems
  alignSelf?: Property.AlignSelf
  columnGap?: Property.ColumnGap
  flex?: Property.Flex
  flexDirection?: Property.FlexDirection
  flexFlow?: Property.FlexFlow
  flexWrap?: Property.FlexWrap
  gap?: Property.Gap
  height?: Property.Height
  justifyContent?: Property.JustifyContent
  margin?: Property.Margin
  padding?: Property.Padding
  rowGap?: Property.RowGap
  width?: Property.Width
}

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;

  ${({ flex }) => flex && `flex: ${flex};`}

  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}

  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}

  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}

  ${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}

  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}

  ${({ height }) => height && `height: ${height};`}

  ${({ gap }) => gap && `gap: ${gap};`}

  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`}

  ${({ columnGap }) => columnGap && `column-gap: ${columnGap};`}

  ${({ flex }) => flex && `flex: ${flex};`}

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ padding }) => padding && `padding: ${padding};`}

  ${({ width }) => width && `width: ${width};`}
`
