import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiarySelectors from './selectors'

export interface withDailyDiaryServiceProps {
  dailyDiaryActions: typeof actions
  // @TODO
  dailyDiaryListState: any
  // @TODO
  dailyDiaryMyDailyDiaryState: any
  dailyDiarySelectors: typeof dailyDiarySelectors
}

const withDailyDiaryService = <P extends withDailyDiaryServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ newDailyDiary }) => ({ newDailyDiary })

  const mapDispatch = injectActions('dailyDiaryActions', actions)

  const Component = (props) => {
    const { dailyDiaryActions, newDailyDiary, ...others } = props

    return (
      <WrappedComponent
        dailyDiaryActions={dailyDiaryActions}
        dailyDiaryListState={newDailyDiary.list}
        dailyDiaryMyDailyDiaryState={newDailyDiary.myDailyDiary}
        dailyDiarySelectors={dailyDiarySelectors}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryService
