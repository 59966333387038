import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { AVATAR_SIZE_VARIANTS } from 'components/Avatar/Avatar'
import { Child } from 'services/child/models'
import { Membership } from 'services/legacy/memberships/models'

import { Flex, GroupAvatars, Icon, Typography } from 'components'

import { StyledAvatarContainer, StyledContainer, StyledDetail, StyledLinkContainer } from './WidgetListStyled'

interface WidgetListRowProps extends PropsWithChildren {
  leftBottomColumn?: React.ReactNode
  leftTopColumn?: React.ReactNode | string
  persons?: (Child | Membership)[]
  personsCount?: number
  previewPersons?: (Child | Membership)[]
  rightColumn?: React.ReactNode
  to?: string
}

const WidgetListRow: React.FC<WidgetListRowProps> = ({
  children,
  leftBottomColumn,
  leftTopColumn,
  persons,
  personsCount,
  previewPersons,
  rightColumn,
  to,
}) => {
  const renderContent = () => {
    if (children) {
      return children
    }

    return (
      <React.Fragment>
        {(persons || previewPersons) && (
          <Flex margin="0 12px 0 0" width="40px">
            <StyledAvatarContainer>
              <GroupAvatars
                avatarSize={1 < (personsCount || persons?.length)
                  ? AVATAR_SIZE_VARIANTS.VERY_SMALL
                  : AVATAR_SIZE_VARIANTS.MEDIUM}
                backgroundColor={NEUTRAL_COLOURS.WHITE}
                borderColor={NEUTRAL_COLOURS.WHITE}
                borderWidth={2}
                countOverride={personsCount || persons?.length}
                persons={previewPersons || persons}
                withoutText
              />
            </StyledAvatarContainer>
          </Flex>
        )}
        <StyledDetail>
          {leftTopColumn && (
            <Typography color={NEUTRAL_COLOURS.BASIC} variant="div" ellipsis>
              {leftTopColumn}
            </Typography>
          )}
          {leftBottomColumn && (
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} variant="div">
              {leftBottomColumn}
            </Typography>
          )}
        </StyledDetail>
        {rightColumn && (
          <Flex justifyContent="flex-start" padding="0 0 0 15px">
            {rightColumn}
          </Flex>
        )}
        {to && (
          <Flex padding="0 10px 0 15px">
            <Icon height={16} icon="chevron-right" />
          </Flex>
        )}
      </React.Fragment>
    )
  }

  if (to) {
    return (
      <StyledLinkContainer to={to}>
        {renderContent()}
      </StyledLinkContainer>
    )
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

export default WidgetListRow
