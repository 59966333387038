import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import { NeedsAttentionWidgetSingleState } from './constants'

export interface withNeedsAttentionWidgetServiceProps {
  needsAttentionWidgetActions: typeof actions
  needsAttentionWidgetSingleState: NeedsAttentionWidgetSingleState
}

const withNeedsAttentionWidgetService = <P extends withNeedsAttentionWidgetServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ needsAttentionWidget }) => ({ needsAttentionWidget })

  const mapDispatch = injectActions('needsAttentionWidgetActions', actions)

  const Component = ({ needsAttentionWidget, needsAttentionWidgetActions, ...others }) => (
    <WrappedComponent
      needsAttentionWidgetActions={needsAttentionWidgetActions}
      needsAttentionWidgetSingleState={needsAttentionWidget.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNeedsAttentionWidgetService
