import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as roomsSelectors from './selectors'

export interface withRoomsServiceProps {
  roomsActions: typeof actions
  roomsListState: any // @TODO
  roomsSelectors: typeof roomsSelectors
  roomsSingleState: any // @TODO
}

const withRoomsService = <P extends withRoomsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ newRooms }) => ({ newRooms })

  const mapDispatch = injectActions('roomsActions', actions)

  const Component = ({ newRooms, roomsActions, ...others }) => (
    <WrappedComponent
      roomsActions={roomsActions}
      roomsListState={newRooms.list}
      roomsSelectors={roomsSelectors}
      roomsSingleState={newRooms.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRoomsService
