import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { BasicListResponse, BasicSingleResponse } from 'constants/service'
import { EnquiryShowAround } from 'services/legacy/enquiries/models'
import { SORT_ORDER } from 'constants/global'
import { ModalType } from 'modals'
import { ViewingsStatisticsWidget } from 'services/viewingsStatisticsWidget/models'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withEnquiryViewingsService, withEnquiryViewingsServiceProps } from 'services/legacy/enquiries'
import {
  withViewingsStatisticsWidgetService,
  withViewingsStatisticsWidgetServiceProps,
} from 'services/viewingsStatisticsWidget'
import * as enquiryViewingsSelectors from 'services/legacy/enquiries/viewings/selectors'

import ViewingsWidgetView from './ViewingsWidgetView'

type ViewingsWidgetContainerProps = withEnquiryViewingsServiceProps
  & withViewingsStatisticsWidgetServiceProps
  & withModalServiceProps

const ENQUIRY_VIEWING_GROUPS = {
  read: [
    'enquiry',
    'enquiry.data',
    'membership',
    'showAround.status',
    'showAround.enquiry',
    'showAround.assignedPerson',
  ],
}

const ViewingsWidgetContainer: React.FC<ViewingsWidgetContainerProps> = ({
  enquiryViewingsActions,
  modalActions,
  viewingsStatisticsWidgetActions,
}) => {
  const [viewingsRecords, setViewingsRecords] = useState<EnquiryShowAround[]>([])
  const [viewingsStatistics, setViewingsStatistics] = useState<ViewingsStatisticsWidget>({
    totalThisMonth: 0,
    totalThisWeek: 0,
  })
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getStatisticsSuccess = (response: BasicSingleResponse<ViewingsStatisticsWidget>) => {
    setViewingsStatistics(response.data)
  }

  const getListSuccess = (response: BasicListResponse<EnquiryShowAround>) => {
    setViewingsRecords(response.data)
    setIsFetching(false)
  }

  const fetchList = () => {
    viewingsStatisticsWidgetActions.get({
      onSuccess: getStatisticsSuccess,
    })

    enquiryViewingsActions.list({
      onSuccess: getListSuccess,
      onlyData: true,
      params: {
        criteria: enquiryViewingsSelectors.getCriteria({
          showAroundDate: {
            after: moment(),
          },
        }),
        groups: ENQUIRY_VIEWING_GROUPS,
        order: {
          sortField: 'showAroundDate',
          sortOrder: SORT_ORDER.ASC,
        },
      },
    })
  }

  const handleSubmitSuccess = () => {
    setIsFetching(true)
    fetchList()
  }

  const handleOpenModal = (showAroundData: EnquiryShowAround) => {
    modalActions.show<ModalType.ENQUIRY_VIEWING_EDIT>(ModalType.ENQUIRY_VIEWING_EDIT, {
      onSubmitSuccess: handleSubmitSuccess,
      showAroundData,
    }, {
      enableMultipleModal: true,
    })
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <ViewingsWidgetView
      isLoading={isFetching}
      viewingsRecords={viewingsRecords}
      viewingsStatistics={viewingsStatistics}
      onOpenModal={handleOpenModal}
    />
  )
}

const enhance = compose(
  withEnquiryViewingsService,
  withViewingsStatisticsWidgetService,
  withModalService,
)

export default enhance(ViewingsWidgetContainer)
