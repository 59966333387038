import _ from 'lodash'

import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ImagesSize from 'constants/imagesSize'
import { CursorTypes, TARGET_TYPES } from 'constants/css'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getOptimizedImage } from 'utils/image'
import { typeByObject } from 'utils/typescript'

import { Icon, Typography } from 'components'

import placeholderImage from 'assets/images/placeholders/avatar.jpg'

import { IconProps } from 'components/Icon/Icon'
import { StyledAvatarContainer, StyledContainer, StyledDetails, StyledSubTitle, StyledTitle } from './AvatarStyled'

const EMPTY_ICON_PROPORTION = 1.8

export const AVATAR_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
} as const

export const AVATAR_SIZE_VARIANTS = {
  BIG: 'big',
  MEDIUM: 'medium',
  MEDIUM_BIG: 'medium_big',
  MEDIUM_BIGGER: 'medium_bigger',
  SMALL: 'small',
  STANDARD: 'standard',
  VERY_SMALL: 'very_small',
} as const

export interface AvatarProps {
  /**
   * Define size of avatar
   *
   * very_small - 24x24
   * small - 30x30
   * medium - 40x40
   * medium_big - 48x48
   * standard - 55x55
   * medium_bigger - 80x80
   * big - 120x120
   */
  avatarSize?: typeByObject<typeof AVATAR_SIZE_VARIANTS>
  borderColor?: string
  borderWidth?: number
  cursor?: CursorTypes
  direction?: typeByObject<typeof AVATAR_DIRECTION>
  disableEffect?: boolean
  disabled?: boolean
  /**
   * This param defines the distance of the image from the text
   */
  gap?: number
  iconProps?: IconProps
  initials?: string | string[]
  isArchived?: boolean
  minWidth?: number
  showPlaceholder?: boolean
  src?: string
  subTitle?: string | React.ReactNode
  subTitleAbove?: boolean
  target?: typeByObject<typeof TARGET_TYPES>
  title?: string | React.ReactNode
  to?: string
  /**
   * This param disable converting image url to optimized version
   */
  withoutOptimization?: boolean
}

const Avatar: React.FC<PropsWithChildren<AvatarProps>> = ({
  avatarSize = AVATAR_SIZE_VARIANTS.STANDARD,
  borderColor = '#E3E3E3',
  borderWidth,
  cursor,
  direction = AVATAR_DIRECTION.HORIZONTAL,
  disableEffect,
  disabled,
  gap,
  iconProps,
  initials,
  isArchived,
  minWidth,
  showPlaceholder,
  src,
  subTitle,
  subTitleAbove,
  target,
  title,
  to,
  withoutOptimization,
}) => {
  const avatarSizeKey = _.findKey(AVATAR_SIZE_VARIANTS, (key) => key === avatarSize)

  const getInitials = () => {
    if (_.isArray(initials)) {
      return _.map(initials, (i) => i && String(i[0]).toUpperCase()).join('')
    }

    if (_.isString(initials)) {
      return String(initials[0]).toUpperCase()
    }

    return null
  }

  const renderAvatar = () => {
    const initialsData = getInitials()

    const imageSize = (avatarSize === AVATAR_SIZE_VARIANTS.BIG)
      ? ImagesSize.AVATARS.BIG
      : ImagesSize.AVATARS.STANDARD

    return (
      <StyledAvatarContainer
        $avatarSize={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.width}
        $borderAvatar={!src || !!borderWidth}
        $borderColor={borderColor}
        $borderWidth={borderWidth}
        $isArchived={isArchived}
        $isEmpty={!src && !initialsData && !iconProps}
      >
        {!showPlaceholder && src && (
          <LazyLoadImage
            effect={disableEffect ? null : 'opacity'}
            height={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.height}
            placeholderSrc={placeholderImage}
            src={!withoutOptimization ? getOptimizedImage(src, imageSize) : src}
            width={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.width}
          />
        )}
        {showPlaceholder && (
          <LazyLoadImage
            effect={disableEffect ? null : 'opacity'}
            height={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.height}
            placeholderSrc={placeholderImage}
            src={placeholderImage}
            width={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.width}
          />
        )}
        {!src && initialsData}
        {!src && !initialsData && !showPlaceholder && iconProps && (
          <Icon
            height={(imageSize.width || imageSize.height) / EMPTY_ICON_PROPORTION}
            {...iconProps}
          />
        )}
        {!src && !initialsData && !showPlaceholder && !iconProps && (
          <Icon
            color="#B0B0B0"
            height={(imageSize.width || imageSize.height) / EMPTY_ICON_PROPORTION}
            icon="profile"
          />
        )}
      </StyledAvatarContainer>
    )
  }

  const renderDetails = () => (
    <StyledDetails>
      {title && (
        <StyledTitle>
          {_.isString(title) ? (
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={14}>
              {title}
            </Typography>
          ) : title}
        </StyledTitle>
      )}
      {subTitle && (
        <StyledSubTitle $subTitleAbove={subTitleAbove}>
          {subTitle}
        </StyledSubTitle>
      )}
    </StyledDetails>
  )

  const renderContent = () => (
    <StyledContainer
      $avatarSize={avatarSizeKey ? ImagesSize.AVATARS[avatarSizeKey].width : ImagesSize.AVATARS.STANDARD.width}
      $cursor={cursor}
      $direction={direction}
      $gap={gap}
      $hasRightColumn={!!(title || subTitle)}
      $minWidth={minWidth}
    >
      {renderAvatar()}
      {(title || subTitle) && renderDetails()}
    </StyledContainer>
  )

  if (to && !disabled) {
    return (
      <Link
        rel={TARGET_TYPES.BLANK === target ? 'noreferrer' : null}
        target={target}
        to={to}
      >
        {renderContent()}
      </Link>
    )
  }

  return renderContent()
}

export default Avatar
