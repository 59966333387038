import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { BasicListResponse, CriterionV2, Meta } from 'constants/service'
import { Child } from 'services/child/models'
import { mergeData } from 'utils/data'

import { withChildService, withChildServiceProps } from 'services/legacy/child'

import ChildrenSearchWidgetView from './ChildrenSearchWidgetView'

type ChildrenSearchWidgetProps = withChildServiceProps

const ChildrenSearchWidget: React.FC<ChildrenSearchWidgetProps> = ({ childActions }) => {
  const [childrenList, setChildrenList] = useState<Child[]>([])
  const [childrenListMeta, setChildrenListMeta] = useState<Meta>({})
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [searchedValue, setSearchedValue] = useState<string | null>(null)

  const handleGetChildrenSuccess = (response: BasicListResponse<Child>) => {
    const { data, meta } = response

    setChildrenList(_.uniqBy(mergeData(childrenList, data, true), ({ id }) => id))
    setChildrenListMeta(meta)
    setIsFetching(false)
    setPage(page + 1)
  }

  const fetch = () => {
    const criteria: CriterionV2[] = [{ field: 'isArchived', value: false }]

    if (searchedValue) {
      criteria.push({
        field: 'displayName',
        value: searchedValue,
      })
    }

    const params = {
      criteria,
      order: {
        sortField: 'displayName',
        sortOrder: 'ASC',
      },
      page: page + 1,
    }

    setIsFetching(true)
    childActions.list({
      onSuccess: handleGetChildrenSuccess,
      onlyData: true,
      params,
    })
  }

  const handleChangeSearch = (newSearchedValue: string) => {
    setPage(0)
    setChildrenList([])
    setChildrenListMeta({})
    setSearchedValue(newSearchedValue)
  }

  useEffect(() => {
    fetch()
  }, [searchedValue])

  const isLoading = isFetching && 0 === page && null === searchedValue
  const isLoadingSearchedPages = isFetching && 0 === page && null !== searchedValue

  return (
    <ChildrenSearchWidgetView
      childrenList={childrenList}
      childrenListMeta={childrenListMeta}
      isLoading={isLoading}
      isLoadingSearchedPages={isLoadingSearchedPages}
      onChangeSearch={handleChangeSearch}
      onLoadNextPage={fetch}
    />
  )
}

const enhance = compose(
  withChildService,
)

export default enhance(ChildrenSearchWidget)
