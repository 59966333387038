import React from 'react'

import { Button, Flex } from 'components'

import i18n from 'translations'

interface SeeMoreProps {
  to: string
}

const SeeMore: React.FC<SeeMoreProps> = ({ to }) => (
  <Flex justifyContent="center" padding="12px" width="100%">
    <Button
      hierarchy="tertiary"
      icon="chevron-right"
      label={i18n.t('module:Dashboard:seeMore')}
      to={to}
      iconRight
    />
  </Flex>
)

export default SeeMore
