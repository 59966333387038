import i18n from 'translations'

import Safeguarding from 'module/Safeguarding/index'
import InjuryWrapper from 'module/Safeguarding/Injury/InjuryWrapper'
import InjuryRecords from 'module/Safeguarding/Injury/InjuryRecords'
import InjurySingleRecord from 'module/Safeguarding/Injury/InjurySingleRecord'
import LogInjury from 'module/Safeguarding/Injury/LogInjury'

/* eslint max-len:0 */
export const ROUTE_NAMES = {
  'SAFEGUARDING.HOME_ACCIDENTS.LIST': 'SAFEGUARDING.HOME_ACCIDENTS.LIST',
  'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD': 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD',
  'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT': 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT',
  'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW': 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW',
  'SAFEGUARDING.INDEX': 'SAFEGUARDING.INDEX',
  'SAFEGUARDING.INJURY.LIST': 'SAFEGUARDING.INJURY.LIST',
  'SAFEGUARDING.INJURY.SINGLE_ADD': 'SAFEGUARDING.INJURY.SINGLE_ADD',
  'SAFEGUARDING.INJURY.SINGLE_EDIT': 'SAFEGUARDING.INJURY.SINGLE_EDIT',
  'SAFEGUARDING.INJURY.SINGLE_PREVIEW': 'SAFEGUARDING.INJURY.SINGLE_PREVIEW',
}

const SafeguardingRouting = {
  childRoutes: [
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: LogInjury,
              name: ROUTE_NAMES['SAFEGUARDING.INJURY.SINGLE_EDIT'],
              path: 'edit',
            },
          ],
          indexRoute: {
            component: InjurySingleRecord,
          },
          name: ROUTE_NAMES['SAFEGUARDING.INJURY.SINGLE_PREVIEW'],
          path: ':injuryId/preview',
        },
        {
          component: LogInjury,
          name: ROUTE_NAMES['SAFEGUARDING.INJURY.SINGLE_ADD'],
          path: 'add',
          title: i18n.t('module:Injury:Routing:Add:title'),
        },
      ],
      component: InjuryWrapper,
      indexRoute: {
        component: InjuryRecords,
      },
      name: ROUTE_NAMES['SAFEGUARDING.INJURY.LIST'],
      path: 'injury',
      title: i18n.t('module:Injury:Routing:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: LogInjury,
              name: ROUTE_NAMES['SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT'],
              path: 'edit',
            },
          ],
          indexRoute: {
            component: InjurySingleRecord,
          },
          name: ROUTE_NAMES['SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW'],
          path: ':injuryId/preview',
        },
        {
          component: LogInjury,
          name: ROUTE_NAMES['SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD'],
          path: 'add',
          title: i18n.t('module:Safeguarding:Add:addTitle'),
        },
      ],
      component: InjuryWrapper,
      indexRoute: {
        component: InjuryRecords,
      },
      name: ROUTE_NAMES['SAFEGUARDING.HOME_ACCIDENTS.LIST'],
      path: 'home-accidents',
      title: i18n.t('module:Safeguarding:Routing:homeAccidents'),
    },
  ],
  component: Safeguarding,
  name: ROUTE_NAMES['SAFEGUARDING.INDEX'],
  path: 'safeguarding',
  title: i18n.t('module:Safeguarding:Routing:title'),
} as any

SafeguardingRouting.ROUTE_NAMES = ROUTE_NAMES

export default SafeguardingRouting
