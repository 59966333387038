import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledScrollbar = styled.div`
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${NEUTRAL_COLOURS.WHITE};
  }
  
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${NEUTRAL_COLOURS.WHITE};
  }
  
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  }  
`
