import i18n from 'translations'

import AnnualRevenueWidgetContainer from './AnnualRevenueWidgetContainer'

export const WIDGET_NAME = {
  component: AnnualRevenueWidgetContainer,
  id: 'ANNUAL_REVENUE_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:AnnualRevenueWidget:titleInSettings'),
}

export default AnnualRevenueWidgetContainer
