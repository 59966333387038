import _ from 'lodash'

import React from 'react'

import { Child } from 'services/child/models'
import { INJURIES, INJURIES_STATUS } from 'services/legacy/injuries/constants'
import { Injury } from 'services/legacy/injuries/models'

import { generateRoute } from 'utils/routing'

import { Flex, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'
import { FLAG_COLOURS } from 'constants/colors'

interface AccidentsIncidentsSignaturesWidgetViewProps {
  injuries: Injury[]
  isFetching: boolean
}

const ExtraSessionRequestsWidgetView: React.FC<AccidentsIncidentsSignaturesWidgetViewProps> = ({
  injuries,
  isFetching,
}) => {
  const renderSubtitle = (children: Child[]) => {
    if (1 < children?.length) {
      return `${children.length} ${i18n.t('global:Children')}`
    }

    return children.length ? children[0].name : null
  }

  const renderListRow = (injury: Injury) => {
    const { id, injured, injurySignatureStatistics, type } = injury
    const { firstAid, parent, staffMember, witness } = injurySignatureStatistics
    const children = _.map(injured, ({ child }) => child)

    const parentSignatureCount = parent.all - parent.signed
    let finalWitnessAll = witness.all

    if (type === INJURIES.HOME_ACCIDENT && 2 >= finalWitnessAll) {
      finalWitnessAll = 2
    }

    const staffSignatureCount = (
      (staffMember.all + firstAid.all + finalWitnessAll) - (staffMember.signed + firstAid.signed + witness.signed)
    )

    return (
      <Widget.List.Row
        key={id}
        leftBottomColumn={(
          <Flex gap="8px">
            {0 < staffSignatureCount && (
              <Flex>
                <Typography fontSize={14}>
                  {staffSignatureCount}
                  {' '}
                  {i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:staff')}
                </Typography>
              </Flex>
            )}
            {0 < parentSignatureCount && (
              <Flex flex="1">
                <Typography fontSize={14}>
                  {parentSignatureCount}
                  {' '}
                  {1 === parentSignatureCount
                    ? i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:parent')
                    : i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:parents')}
                </Typography>
              </Flex>
            )}
          </Flex>
        )}
        leftTopColumn={renderSubtitle(children)}
        persons={children}
        to={type === INJURIES.HOME_ACCIDENT
          ? generateRoute('SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW', { injuryId: id })
          : generateRoute('SAFEGUARDING.INJURY.SINGLE_PREVIEW', { injuryId: id })}
      />
    )
  }

  const renderContent = () => {
    if (!injuries?.length) {
      return (
        <Widget.EmptyState
          icon="accident"
          text1={i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Flex alignItems="center" gap="8px" padding="4px 0">
          <Flex>
            <Typography color={FLAG_COLOURS.WARNING} fontSize={14} fontWeight="600">
              {i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:signaturesNeeded')}
            </Typography>
          </Flex>
          <Flex flex="1">
            <Icon color={FLAG_COLOURS.WARNING} height={18} icon="alert" />
          </Flex>
        </Flex>
        <Widget.List>
          {_.map(injuries, renderListRow)}
        </Widget.List>
        <SeeMore
          to={`${generateRoute('SAFEGUARDING.INJURY.LIST')}?status=${INJURIES_STATUS.SIGNATURES_NEEDED.value}`}
        />
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:AccidentsIncidentsSignaturesWidget:title')} />
      <Widget.Content isLoading={isFetching}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ExtraSessionRequestsWidgetView
