import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as observationsSelectors from './selectors'

export interface withObservationsServiceProps {
  observationsActions: typeof actions
  // @TODO
  observationsListState: any
  observationsSelectors: typeof observationsSelectors
  // @TODO
  observationsSingleState: any
}

const withObservationsService = <P extends withObservationsServiceProps>(WrappedComponent) => {
  const mapState = ({ observations }) => ({ observations })

  const mapDispatch = injectActions('observationsActions', actions)

  const Component = ({ observations, observationsActions, ...others }) => (
    <WrappedComponent
      observationsActions={observationsActions}
      observationsListState={observations.list}
      observationsSelectors={observationsSelectors}
      observationsSingleState={observations.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withObservationsService
