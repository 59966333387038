import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouter, withRouterProps } from 'services/router'
import appSelectors from 'services/app/selectors'
import nurseriesSelectors from 'services/nurseries/selectors'

import NurseryAdd from 'module/Nurseries/NurseriesAdd'

import OrganizationDashboardView from './OrganizationDashboardView'

const GROUPS = {
  read: [
    'membership',
    'membership.details',
    'nursery.details',
    'nursery.extraInfo',
    'nursery.primaryContact',
    'nursery.statistics',
  ],
}

const mapState = (state: RootState, { nurseriesListState }) => ({
  authAccessMap: {
    module: {
      NurseryAdd: auth.SELECTORS.getComponentIsAuthorised(state, NurseryAdd),
    },
  },
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataWithPublicUrl(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type OrganizationDashboardContainerProps = PropsFromRedux
  & withAppServiceProps
  & withNurseriesServiceProps
  & withPaginationUtilsProps
  & withRouterProps

const OrganizationDashboardContainer: React.FC<OrganizationDashboardContainerProps> = ({
  authAccessMap,
  isFetching,
  navigate,
  nurseries,
  nurseriesActions,
  paginationUtils,
  totalResults,
}) => {
  const { getPageCount, onPageChange, page, setPageLocationQuery } = paginationUtils

  const [search, setSearch] = useState<string | null>(null)

  const fetch = (newPage: number) => {
    const params = {
      criteria: nurseriesSelectors.getCriteria({ search }),
      groups: GROUPS,
      page: newPage,
    }

    nurseriesActions.list({
      params,
      recurrency: 1 !== newPage,
    })
  }

  const handleSearchChange = (newSearch: string) => {
    onPageChange()(1)
    setSearch(newSearch)
  }

  const handlePageChange = (newPage: number) => {
    onPageChange()(newPage)
    fetch(newPage)
  }

  const handleEditClick = (nurseryId: number) => {
    navigate(generateRoute('NURSERIES.EDIT', { nurseryId }))
  }

  useEffect(() => {
    fetch(1)
  }, [search])

  useEffect(() => {
    setPageLocationQuery(false)

    return () => {
      nurseriesActions.clear()
    }
  }, [])

  const pagination = {
    onPageChange: handlePageChange,
    page,
    pageCount: getPageCount(totalResults),
    totalResults,
  }

  return (
    <OrganizationDashboardView
      authAccessMap={authAccessMap}
      isLoading={isFetching}
      nurseries={nurseries}
      pagination={pagination}
      onEditClick={handleEditClick}
      onSearchChange={handleSearchChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaginationUtils,
  withRouter,
  connector,
)

export default enhance(OrganizationDashboardContainer)
