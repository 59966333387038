import i18n from 'translations'

import AgedDebtWidgetContainer from './AgedDebtWidgetContainer'

export const WIDGET_NAME = {
  component: AgedDebtWidgetContainer,
  id: 'AGED_DEBT_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:AgedDebtWidget:titleInSettings'),
}

export default AgedDebtWidgetContainer
