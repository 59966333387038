import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class StartersAndLeaversWidgetApiClient extends ServiceBase {
  list = (params: QueryPropsV2) => this.request({
    path: `/v2/widget/starters_and_leavers_widget_records?${getQueryStringV2(params)}`,
  })
}

export default StartersAndLeaversWidgetApiClient
