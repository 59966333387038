import React, { PropsWithChildren } from 'react'

import { StyledFlex, StyledFlexProps } from './FlexStyled'

interface FlexProps extends StyledFlexProps, PropsWithChildren {}

const Flex: React.FC<FlexProps> = ({ children, ...props }) => (
  <StyledFlex {...props}>{children}</StyledFlex>
)

export default Flex
