import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { RegisterStatistics, RegisterStatisticsClassResult } from 'services/legacy/registerStatistics/models'

import { generateRoute } from 'utils/routing'

import { Arc, Flex, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledNurseryClassItem, StyledWrapper } from './AttendanceWidgetStyled'

interface AttendanceWidgetViewProps {
  isFetching: boolean
  registerStatistics: RegisterStatistics | null
}

const AttendanceWidgetView: React.FC<AttendanceWidgetViewProps> = ({ isFetching, registerStatistics }) => {
  const { totalInTheNursery, totalScheduled } = registerStatistics || {}

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:AttendanceWidget:title')} />
      <Widget.Content isLoading={isFetching}>
        <StyledWrapper>
          <Flex alignItems="center" gap="22px">
            <Flex>
              <Arc
                content={<Icon color={NEUTRAL_COLOURS.SUCCESS} height={24} icon="check" />}
                size={80}
                total={totalScheduled || 0}
                value={totalInTheNursery || 0}
              />
            </Flex>
            <Flex flexDirection="column" gap="3px" justifyContent="center">
              <Typography fontSize={14}>
                {i18n.t('global:Children')}
              </Typography>
              <Flex alignItems="center" gap="8px">
                <Flex>
                  <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={40} fontWeight="600">
                    {totalInTheNursery}
                  </Typography>
                </Flex>
                <Flex>
                  <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={24}>
                    {`${i18n.t('global:of')} ${totalScheduled}`}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </StyledWrapper>
        {_.map(registerStatistics?.classResults, (classResult: RegisterStatisticsClassResult) => (
          <StyledNurseryClassItem key={classResult.id}>
            <Link to={`${generateRoute('REGISTER.INDEX')}?room=${classResult.id}`}>
              <Flex>
                <Flex alignItems="center" flex="1">
                  <Typography color={NEUTRAL_COLOURS.BASIC}>
                    {classResult.name}
                  </Typography>
                </Flex>
                <Flex alignItems="center" gap="12px">
                  <Flex alignItems="center" gap="4px">
                    <Flex>
                      <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={20} fontWeight="600">
                        {classResult.totalInTheNursery}
                      </Typography>
                    </Flex>
                    <Flex>
                      <Typography color={NEUTRAL_COLOURS.GRAY}>
                        {`${i18n.t('global:of')} ${classResult.totalScheduled}`}
                      </Typography>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Icon height={16} icon="chevron-right" />
                  </Flex>
                </Flex>
              </Flex>
            </Link>
          </StyledNurseryClassItem>
        ))}
      </Widget.Content>
    </Widget>
  )
}

export default AttendanceWidgetView
