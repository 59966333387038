import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class NeedsAttentionWidgetApiClient extends ServiceBase {
  get = (params: QueryPropsV2) => this.request({
    path: `/v2/widget/needs_attention_widget?${getQueryStringV2(params)}`,
  })
}

export default NeedsAttentionWidgetApiClient
