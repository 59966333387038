import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledGrayBox = styled.div`
  border-radius: ${layout.borderRadius};
  padding: 10px 14px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  ${({ value }) => 0 > value && `color: ${getBrandingColor('tertiary-color')};`}
  font-size: 20px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`
