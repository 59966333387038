import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import * as requestedExtraSessionsSelectors from './selectors'
import actions from './actions'

export interface withRequestedExtraSessionsServiceProps {
  exceptionalUnavailableTimesListState: any // @TODO
  exceptionalUnavailableTimesSingleState: any // @TODO
  regularUnavailableTimesBatchState: any // @TODO
  regularUnavailableTimesListState: any // @TODO
  requestedExtraSessionsActions: typeof actions
  requestedExtraSessionsAvailabilityStatisticsState: any // @TODO
  requestedExtraSessionsCostStatisticsState: any // @TODO
  requestedExtraSessionsListState: any // @TODO
  requestedExtraSessionsSelectors: typeof requestedExtraSessionsSelectors
  requestedExtraSessionsSingleState: any // @TODO
}

const withRequestedExtraSessionsService = <P extends withRequestedExtraSessionsServiceProps>(WrappedComponent) => {
  const mapState = ({ requestedExtraSessions }) => ({ requestedExtraSessions })

  const mapDispatch = injectActions('requestedExtraSessionsActions', actions)

  const Component = ({ requestedExtraSessions, requestedExtraSessionsActions, ...others }) => (
    <WrappedComponent
      exceptionalUnavailableTimesListState={requestedExtraSessions.exceptionalUnavailableTimesList}
      exceptionalUnavailableTimesSingleState={requestedExtraSessions.exceptionalUnavailableTimesSingle}
      regularUnavailableTimesBatchState={requestedExtraSessions.regularUnavailableTimesBatch}
      regularUnavailableTimesListState={requestedExtraSessions.regularUnavailableTimesList}
      requestedExtraSessionsActions={requestedExtraSessionsActions}
      requestedExtraSessionsAvailabilityStatisticsState={requestedExtraSessions.availabilityStatistics}
      requestedExtraSessionsCostStatisticsState={requestedExtraSessions.costStatistics}
      requestedExtraSessionsListState={requestedExtraSessions.list}
      requestedExtraSessionsSelectors={requestedExtraSessionsSelectors}
      requestedExtraSessionsSingleState={requestedExtraSessions.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRequestedExtraSessionsService
