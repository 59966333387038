import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledIcon = styled.div`
  margin-right: 10px;
`

export const StyledTodayBirthdays = styled.div`
  background-color: ${getBrandingColor('quinary-color')};
  margin: 0 -24px 12px;
  padding: 8px 24px;
`
