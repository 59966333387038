import i18n from 'translations'

import DraftInvoicesWidgetContainer from './DraftInvoicesWidgetContainer'

export const WIDGET_NAME = {
  component: DraftInvoicesWidgetContainer,
  id: 'DRAFT_INVOICE_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:DraftInvoicesWidget:titleInSettings'),
}

export default DraftInvoicesWidgetContainer
