import { Child } from 'services/child/models'
import { NurseryClass } from 'services/legacy/rooms/models'

export enum StartersAndLeaversWidgetType {
  LEAVER = 'leaver',
  STARTER = 'starter'
}

export interface StartersAndLeaversWidgetRecord {
  child: Child
  class: NurseryClass
  date: string
  type: StartersAndLeaversWidgetType
}
