import { generateBasicActions } from 'utils/service'

import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_NEEDS_ATTENTION_WIDGET,
  GET_NEEDS_ATTENTION_WIDGET,
  GET_NEEDS_ATTENTION_WIDGET_FAILED,
  GET_NEEDS_ATTENTION_WIDGET_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ needsAttentionWidgetApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NEEDS_ATTENTION_WIDGET_FAILED,
      init: GET_NEEDS_ATTENTION_WIDGET,
      success: GET_NEEDS_ATTENTION_WIDGET_SUCCESS,
    },
    options,
    service: needsAttentionWidgetApiClient,
    serviceMethod: 'get',
  })
)

export const clear = () => ({
  type: CLEAR_NEEDS_ATTENTION_WIDGET,
})
