import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledBody = styled.div`
  background-color: ${NEUTRAL_COLOURS.WHITE};
  display: flex;
  border-radius: 16px;
  height: 100%;
  padding: 16px 24px;
`
