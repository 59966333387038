import _ from 'lodash'

import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { RegisterChild } from 'services/legacy/register/models'

import { getAllergyType } from 'services/legacy/child/helpers'

import { Flex, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledItemWrapper } from './TodayAllergiesWidgetStyled'

interface TodayAllergiesWidgetViewProps {
  allergyChildren: RegisterChild[]
  isLoading: boolean
}

const TodayAllergiesWidgetView: React.FC<TodayAllergiesWidgetViewProps> = ({
  allergyChildren,
  isLoading,
}) => {
  const renderListItem = (child: RegisterChild) => {
    const { id, name } = child

    return (
      <StyledItemWrapper key={id}>
        <Widget.List.Row
          leftBottomColumn={(
            <Typography color={FLAG_COLOURS.ERROR} fontSize={14}>
              {getAllergyType(child.allergies, child.allergiesDetails)}
            </Typography>
          )}
          leftTopColumn={name}
          persons={[child]}
        />
      </StyledItemWrapper>
    )
  }

  const renderContent = () => {
    if (!allergyChildren?.length) {
      return (
        <Widget.EmptyState
          icon="allergies"
          text1={i18n.t('module:Dashboard:Widgets:TodayAllergiesWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:TodayAllergiesWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Flex alignItems="center" alignSelf="flex-start" gap="8px" margin="0 0 8px">
          <Typography fontSize={40} lineHeight={48} bold>
            {allergyChildren.length || 0}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={18}>
            {i18n.t('global:Children')}
          </Typography>
        </Flex>
        <Widget.List>
          {_.map(allergyChildren, renderListItem)}
        </Widget.List>
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:TodayAllergiesWidget:titleInSettings')} />
      <Widget.Content isLoading={isLoading}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default TodayAllergiesWidgetView
