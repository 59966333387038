import _ from 'lodash'

import React from 'react'

import { MONTESSORI_AREA } from 'services/legacy/nextSteps/constants'
import { NextStep } from 'services/legacy/nextSteps/models'

import { generateRoute } from 'utils/routing'

import { Flex } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'

import { StyledSquare } from './NextStepsWidgetStyled'

interface NextStepsWidgetViewProps {
  isLoading: boolean
  nextSteps: NextStep[]
}

const NextStepsWidgetView: React.FC<NextStepsWidgetViewProps> = ({ isLoading, nextSteps }) => {
  const renderNextStepAreas = (nextStep: NextStep) => {
    const { frameworkAreas, montessoriActivity } = nextStep
    const areas = !montessoriActivity ? frameworkAreas : [MONTESSORI_AREA]

    return (
      <Flex flexFlow="wrap" gap="4px">
        {_.map(areas, ({ colour, shortName }) => (
          <StyledSquare $color={colour} key={shortName}>
            {shortName}
          </StyledSquare>
        ))}
      </Flex>
    )
  }

  const renderListItem = (nextStep: NextStep) => {
    const { childObservation, id } = nextStep
    const { child, observation } = childObservation
    const { id: observationId } = observation

    return (
      <Widget.List.Row
        key={id}
        leftBottomColumn={renderNextStepAreas(nextStep)}
        leftTopColumn={child.name}
        persons={[child]}
        to={generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId })}
      />
    )
  }

  const renderContent = () => {
    if (!nextSteps?.length) {
      return (
        <Widget.EmptyState
          icon="next-steps"
          text1={i18n.t('module:Dashboard:Widgets:NextStepsWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:NextStepsWidget:emptyStateText2')}
        />
      )
    }

    return (
      <Widget.List>
        {_.map(nextSteps, renderListItem)}
        <Widget.List.Row>
          <SeeMore to={generateRoute('LEARNING.NEXT_STEPS')} />
        </Widget.List.Row>
      </Widget.List>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:NextStepsWidget:title')} />
      <Widget.Content id="nextStepsScrollTarget" isLoading={isLoading}>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default NextStepsWidgetView
