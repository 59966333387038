import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledChartWrapper } from 'module/Staff/StaffRota/components/ChartRota/ChartRotaStyled'

interface StyledBorderBoxProps {
  $grayBackground?: boolean
  $noBorderLeft?: boolean
  $noBorderTop?: boolean
}

export const StyledBorderBox = styled.div<StyledBorderBoxProps>`
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  width: 100%;
  height: 100%;

  ${({ $noBorderLeft }) => $noBorderLeft && `
    border-left: none;
  `}

  ${({ $noBorderTop }) => $noBorderTop && `
    border-top: none;
  `}

  ${({ $grayBackground }) => $grayBackground && `
    background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  `}
`

export const StyledChartContainer = styled.div`
  ${StyledChartWrapper} {
    margin: 0;
  }
`

export const StyledWidgetContainer = styled.div`
  overflow: hidden;
  margin-right: -24px;
  padding-right: 24px;
`
