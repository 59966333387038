
import moment from 'moment'

import React from 'react'

import { DateString } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledDateSpan, StyledSuffixSpan, StyledTimeAndDate, StyledTimeSpan } from './TimeWidgetStyled'

interface TimeWidgetViewProps {
  date: Date
}

const TimeWidgetView: React.FC<TimeWidgetViewProps> = ({ date }) => {
  const formattedDate = moment(date).format('dddd, MMM D')
  const suffix = moment(date).format('Do').replace(/\d+/g, '')
  const year = moment(date).format('YYYY')

  return (
    <Widget>
      <StyledTimeAndDate>
        <StyledTimeSpan>
          <DateString date={date} format="LT" />
        </StyledTimeSpan>
        <StyledDateSpan>
          {formattedDate}
          <StyledSuffixSpan>{suffix}</StyledSuffixSpan>
          {` ${year}`}
        </StyledDateSpan>
      </StyledTimeAndDate>
    </Widget>
  )
}

export default TimeWidgetView
