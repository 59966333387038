import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { Option } from 'constants/models'
import { NurseryClass } from 'services/legacy/rooms/models'
import { BasicListResponse } from 'constants/service'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { RotaStatisticsPlanned } from 'services/legacy/membershipsShifts/models'
import { CUSTOM_HEADERS } from 'constants/http'

import { withRoomsService, withRoomsServiceProps } from 'services/legacy/rooms'
import { withMembershipsShiftsService, withMembershipsShiftsServiceProps } from 'services/legacy/membershipsShifts'
import * as membershipsShiftsSelectors from 'services/legacy/membershipsShifts/statistics/selectors'

import StaffingOverviewWidgetView, { Statistics } from './StaffingOverviewWidgetView'

const STATISTICS_GROUPS = {
  read: [
    'rotaStatistic.nurseryClass',
    'rotaStatistic.periods',
    'rotaStatisticPeriod',
    'rotaStatisticPeriod.breakdowns',
    'rotaStatisticBreakdown',
    'rotaStatisticPeriod.staffingFlags',
    'staffingFlag',
  ],
}

type StaffingOverviewWidgetContainerProps = withRoomsServiceProps & withMembershipsShiftsServiceProps

const StaffingOverviewWidgetContainer: React.FC<StaffingOverviewWidgetContainerProps> = ({
  membershipsShiftsActions,
  roomsActions,
}) => {
  const [nurseryClass, setNurseryClass] = useState<Option | null>(null)
  const [statistics, setStatistics] = useState<Statistics | null>(null)
  const [doesntExistAnyNurseryClass, setDoesntExistAnyNurseryClass] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const handleNurseryClassChange = (option: Option) => {
    setIsFetching(true)
    setNurseryClass(option)
  }

  const getStaffRota = () => {
    const statisticsCriteria = membershipsShiftsSelectors.getStatisticsCriteria({
      dateFrom: moment().endOf('week').format(DEFAULT_DATE_FORMAT),
      room: nurseryClass.value,
    })

    membershipsShiftsActions.getRotaStatistics({
      headers: {
        [CUSTOM_HEADERS.ENABLE_CACHE]: 1,
      },
      onSuccess: (response: BasicListResponse<RotaStatisticsPlanned>) => {
        const { data } = response
        const { periods } = data?.[0] || {}

        setStatistics(
          _.groupBy(periods, ({ date }) => moment(date).format(DEFAULT_DATE_FORMAT)),
        )
        setIsFetching(false)
      },
      params: {
        criteria: statisticsCriteria,
        groups: STATISTICS_GROUPS,
      },
    })
  }

  const getNurseryList = () => {
    const params = {
      criteria: [
        {
          field: 'archived',
          value: false,
        },
      ],
    }

    // @ts-ignore TODO: To remove after refactor nursery class service
    roomsActions.list(params, false, (response: BasicListResponse<NurseryClass>) => {
      const { data, meta } = response
      const firstNurseryClass = data[0]

      if (0 === meta.total_results) {
        setDoesntExistAnyNurseryClass(true)
      }

      if (firstNurseryClass) {
        setNurseryClass({
          label: firstNurseryClass.name,
          value: firstNurseryClass.id,
        })

        return
      }

      setIsFetching(false)
    })
  }

  useEffect(() => {
    if (nurseryClass) {
      getStaffRota()
    }
  }, [nurseryClass])

  useEffect(() => {
    getNurseryList()
  }, [])

  return (
    <StaffingOverviewWidgetView
      doesntExistAnyNurseryClass={doesntExistAnyNurseryClass}
      isFetching={isFetching}
      nurseryClass={nurseryClass}
      statistics={statistics}
      onNurseryClassChange={handleNurseryClassChange}
    />
  )
}

const enhance = compose(
  withRoomsService,
  withMembershipsShiftsService,
)

export default enhance(StaffingOverviewWidgetContainer)
