import React, { PropsWithChildren } from 'react'

import WidgetContent from './WidgetContent'
import WidgetHeader from './WidgetHeader'
import WidgetList from './WidgetList'
import WidgetPart from './WidgetPart'
import WidgetEmptyState from './WidgetEmptyState'
import { StyledContainer } from './WidgetStyled'

interface WidgetProps extends PropsWithChildren {
  height?: string
  part?: boolean
  width?: string
}

interface WidgetRoot extends React.FC<WidgetProps> {
  Content?: typeof WidgetContent
  EmptyState?: typeof WidgetEmptyState
  Header?: typeof WidgetHeader
  List?: typeof WidgetList
  Part?: typeof WidgetPart
}

const Widget: WidgetRoot = ({ children, height, part, width }) => (
  <StyledContainer $height={height} $part={part} $width={width}>
    {children}
  </StyledContainer>
)

export default Widget
