import moment from 'moment'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withRegisterStatisticsService, withRegisterStatisticsServiceProps } from 'services/legacy/registerStatistics'
import * as registerStatisticsSelectors from 'services/legacy/registerStatistics/selectors'
import appSelectors from 'services/app/selectors'

import AttendanceWidgetView from './AttendanceWidgetView'

const mapState = (state: RootState, { registerStatisticsState }) => ({
  isFetching: appSelectors.getIsFetching(registerStatisticsState),
  registerStatistics: registerStatisticsSelectors.getRegisterStatistics(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AttendanceWidgetContainerProps = PropsFromRedux & withRegisterStatisticsServiceProps

const AttendanceWidgetContainer: React.FC<AttendanceWidgetContainerProps> = ({
  isFetching,
  registerStatistics,
  registerStatisticsActions,
}) => {
  useEffect(() => {
    registerStatisticsActions.getRegisterStatistics(moment().utc().format('YYYY-MM-DD HH:mm:ss'))

    return () => {
      registerStatisticsActions.clearRegisterStatistics()
    }
  }, [])

  return (
    <AttendanceWidgetView
      isFetching={isFetching}
      registerStatistics={registerStatistics}
    />
  )
}

const enhance = compose(
  withRegisterStatisticsService,
  connector,
)

export default enhance(AttendanceWidgetContainer)
