import { QueryPropsV2 } from 'constants/service'
import { getQueryStringV2 } from 'utils/api'
import { CUSTOM_HEADERS } from 'constants/http'

import ServiceBase from 'services/ServiceBase'

class AgedDebtApiClient extends ServiceBase {
  sendReport = (params) => this.request({
    path: `/v2/children/report/aged-debt?${getQueryStringV2(params)}`,
  })

  getWidgetStatistics = (params: QueryPropsV2 = {}) => this.request({
    headers: {
      [CUSTOM_HEADERS.ENABLE_CACHE]: 1,
    },
    path: `/v2/statistics/aged-debt?${getQueryStringV2(params)}`,
  })

  getStatistics = (params = {}) => this.request({
    path: `/v2/statistics/aged-debt?${getQueryStringV2(params)}`,
  })
}

export default AgedDebtApiClient
