import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledWrapper = styled.div`
  background-color: ${getBrandingColor('quinary-color')};
  margin: 0 -24px;
  padding: 11px 20px;
`

export const StyledNurseryClassItem = styled.div`
  margin: 0 -24px;
  padding: 12px 24px;

  a {
    display: block;
  }
`
