import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { RootState } from 'core/reducers'

import { withAppService, withAppServiceProps } from 'services/app'
import {
  observationsSelectors,
  withObservationsService,
  withObservationsServiceProps,
} from 'services/legacy/observations'
import appSelectors from 'services/app/selectors'

import ObservationsWidgetView from './ObservationsWidgetView'

const mapState = (state: RootState, { observationsListState }) => ({
  isFetching: appSelectors.getIsFetching(observationsListState),
  observations: observationsSelectors.getObservationsListDataSelector(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type ObservationsWidgetContainerProps = PropsFromRedux
  & withAppServiceProps
  & withObservationsServiceProps

const OBSERVATION_GROUPS = {
  read: [
    'observation.preview',
    'childObservation.child',
    'child',
  ],
}

const ObservationsWidgetContainer: React.FC<ObservationsWidgetContainerProps> = ({
  isFetching,
  observations,
  observationsActions,
}) => {
  const [pendingCount, setPendingCount] = useState(null)

  useEffect(() => {
    const draftParams = {
      criteria: [{
        field: 'status',
        value: [OBSERVATION_STATUSES.DRAFT.value],
      }],
      groups: OBSERVATION_GROUPS,
    }

    const pendingParams = {
      criteria: [{
        field: 'status',
        value: [OBSERVATION_STATUSES.PENDING_APPROVAL.value],
      }],
      limit: 0,
    }

    observationsActions.list({
      onSuccess: ({ meta: { total_results: totalResults } }) => setPendingCount(totalResults),
      onlyData: true,
      params: pendingParams,
    })
    observationsActions.list({ params: draftParams })
  }, [])

  return (
    <ObservationsWidgetView
      isFetching={isFetching}
      observations={observations}
      pendingCount={pendingCount}
    />
  )
}

const enhance = compose(
  withAppService,
  withObservationsService,
  connector,
)

export default enhance(ObservationsWidgetContainer)
