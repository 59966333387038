import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import {
  ADMIN_ROLES_GROUP,
  FEATURE_FLAGS,
  FeatureFlags,
  NURSERY_ROLES_GROUP,
  ORDERED_ROLES,
  ORGANIZATION_ROLES_GROUP,
  ROLES,
  Roles,
  RolesDetails,
} from 'constants/security'

import auth from 'utils/auth'

import {
  isAdministrationContext,
  isNurseryContext,
  isOrganizationContext,
} from 'services/security/authorization/selectors'

export const getAuthentication = (state: RootState) => state.authentication.common

// imported because of this issue https://share.getcloudapp.com/rRu7m1Kg
const getMembershipSelector = (state) => state.memberships.single

export const getMembershipDataSelector = createSelector([getMembershipSelector], (state) => state.data)

export const getAuthProfile = createSelector([getAuthentication], (authentication) => {
  if (!authentication || !authentication.profile) {
    return null
  }

  return authentication.profile
})

export const getAuthUserRoles = createSelector(
  [getAuthProfile],
  (authProfile) => (!authProfile || !authProfile.roles ? [] : authProfile.roles),
)

export const getAuthUserFeatureFlags = createSelector(
  [getAuthProfile],
  (authProfile) => (!authProfile || !authProfile.featureFlags ? [] : authProfile.featureFlags),
)

export const hasFeatureFlag = (featureFlags, featureFlag) => 0 <= featureFlags.indexOf(featureFlag)

export const getAuthUser = createSelector([getAuthProfile], (authProfile) => {
  if (!authProfile || !authProfile.user) {
    return null
  }

  return authProfile.user
})

export const isSuperAdmin = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.SUPER_ADMIN].value)
))

export const isNurseryManager = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.NURSERY_MANAGER].value)
))

export const isNurseryAdmin = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.NURSERY_ADMIN].value)
))

export const isDeputyManager = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.DEPUTY_MANAGER].value)
))

export const isSeniorPractitioner = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.SENIOR_TEACHER].value)
))

export const isPractitioner = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.TEACHER].value)
))

export const isRoomLeader = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.ROOM_LEADER].value)
))

export const isOrganizationDirector = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.ORGANIZATION_DIRECTOR].value)
))

export const isOrganizationNationalAdmin = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.ORGANIZATION_NATIONAL_ADMIN].value)
))

export const isOrganizationLineManager = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.ORGANIZATION_LINE_MANAGER].value)
))

export const isOrganizationFinanceAdmin = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.ORGANIZATION_FINANCE_ADMIN].value)
))

export const isNonTeachingUser = createSelector([getAuthUserRoles], (roles) => (
  roles.includes(RolesDetails[ROLES.NON_TEACHING].value)
))

export const hasOrganizationAccess = createSelector(
  [isOrganizationDirector, isOrganizationNationalAdmin, isOrganizationLineManager, isOrganizationFinanceAdmin],
  (isDirector, isNationalAdmin, isLineManager, isFinanceAdmin) => (
    isDirector || isNationalAdmin || isLineManager || isFinanceAdmin
  ),
)

export const hasOnlyNurseryAccess = createSelector(
  [hasOrganizationAccess, isSuperAdmin],
  (organizationAccess, superAdmin) => !organizationAccess && !superAdmin,
)

export const hasOrganizationFullAccess = createSelector(
  [isOrganizationDirector, isOrganizationNationalAdmin],
  (isDirector, isNationalAdmin) => isDirector || isNationalAdmin,
)

export const hasRoleManagementAccess = createSelector(
  [isSuperAdmin, hasOrganizationAccess, isDeputyManager, isNurseryAdmin],
  (
    superAdmin,
    organizationAccess,
    deputyManager,
    nurseryAdmin,
  ) => superAdmin || organizationAccess || deputyManager || nurseryAdmin,
)

export const hasFullAccessExceptFinAdmin = createSelector(
  [isNurseryAdmin, isPractitioner],
  (nurseryAdmin, practitioner) => nurseryAdmin || practitioner,
)

export const hasFullAccessExceptTeachers = createSelector(
  [isNurseryAdmin, isRoomLeader],
  (nurseryAdmin, roomLeader) => nurseryAdmin || roomLeader,
)

export const hasFullAccessExceptNonTeaching = createSelector(
  [isPractitioner],
  (isPractitionerUser) => isPractitionerUser,
)

const userDoesNotHaveRoles = (state, roles) => createSelector(
  [getAuthUserRoles],
  (userRoles) => !_.find(roles, (role) => _.includes(userRoles, role)),
)(state)

const userHaveRoles = (state, roles) => createSelector(
  [getAuthUserRoles],
  (userRoles) => _.some(roles, (role) => _.includes(userRoles, role)),
)(state)

export const hasOnlyRoomLeaderOrTeacherAccess = (state) => userDoesNotHaveRoles(state, [
  ROLES.SUPER_ADMIN,
  ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ROLES.ORGANIZATION_FINANCE_ADMIN,
  ROLES.ORGANIZATION_DIRECTOR,
  ROLES.ORGANIZATION_LINE_MANAGER,
  ROLES.DEPUTY_MANAGER,
  ROLES.NURSERY_MANAGER,
  ROLES.NURSERY_ADMIN,
])

export const hasAccessExcludeFinanceAdminAndTeachers = (state) => auth.SELECTORS.getIsAuthorised(state, {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
})

export const hasOnlyLearningUserAccess = (state) => userDoesNotHaveRoles(state, [
  ROLES.SUPER_ADMIN,
  ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ROLES.ORGANIZATION_FINANCE_ADMIN,
  ROLES.ORGANIZATION_DIRECTOR,
  ROLES.ORGANIZATION_LINE_MANAGER,
  ROLES.NURSERY_MANAGER,
  ROLES.NURSERY_ADMIN,
])

export const hasOnlySeniorTeacherOrTeacherAccess = (state) => userDoesNotHaveRoles(state, [
  ROLES.SUPER_ADMIN,
  ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ROLES.ORGANIZATION_FINANCE_ADMIN,
  ROLES.ORGANIZATION_DIRECTOR,
  ROLES.ORGANIZATION_LINE_MANAGER,
  ROLES.NURSERY_MANAGER,
  ROLES.NURSERY_ADMIN,
  ROLES.DEPUTY_MANAGER,
  ROLES.ROOM_LEADER,
])

export const hasOnlyManagementAccess = (state) => userHaveRoles(state, [
  ROLES.SUPER_ADMIN,
  ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ROLES.ORGANIZATION_FINANCE_ADMIN,
  ROLES.ORGANIZATION_DIRECTOR,
  ROLES.ORGANIZATION_LINE_MANAGER,
  ROLES.NURSERY_MANAGER,
  ROLES.NURSERY_ADMIN,
  ROLES.DEPUTY_MANAGER,
])

const hasFinanceManagementAccess = (state) => auth.SELECTORS.getIsAuthorised(state, {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION, FEATURE_FLAGS.FINANCE_MVP],
})

export const getCurrentUserHighestRole = createSelector(
  [getAuthUserRoles],
  (userRoles) => _.find(ORDERED_ROLES, (role) => _.includes(userRoles, role.value)),
)

export const getContextAvailableRoles = createSelector([
  isAdministrationContext,
  isOrganizationContext,
  isNurseryContext,
  isSuperAdmin,
  hasOrganizationAccess,
  hasFinanceManagementAccess,
], (
  adminContext,
  orgContext,
  nurseryContext,
  superAdmin,
  orgAccess,
) => _.filter(ORDERED_ROLES, (role) => {
  switch (true) {
    case adminContext && superAdmin:
      return role.type === ADMIN_ROLES_GROUP
    case orgContext && orgAccess:
      return role.type === ORGANIZATION_ROLES_GROUP
    case nurseryContext:
      return role.type === NURSERY_ROLES_GROUP
    default:
      return []
  }
}))

export const getAvailableRoles = createSelector([
  getContextAvailableRoles,
  getCurrentUserHighestRole,
  getAuthUserFeatureFlags,
], (
  roles,
  userHighestRole,
  featureFlags,
) => {
  const { roleHierarchy, value } = userHighestRole
  const isStaffingEnabled = hasFeatureFlag(featureFlags, FEATURE_FLAGS.STAFF_REGISTER)

  const availableRoles = _.filter(roles, (role: any) => {
    if (isStaffingEnabled) {
      return role
    }

    return role.value !== ROLES.NON_TEACHING
  })

  return _.values(availableRoles).map((role) => {
    if (role.roleHierarchy < roleHierarchy) {
      return role
    }

    if (role.roleHierarchy === roleHierarchy && role.value === value) {
      return role
    }

    return ({
      ...role,
      isDisabled: true,
    })
  })
})

export const getIsMe = createSelector(
  [getAuthUser, getMembershipDataSelector],
  (authUser, membership) => authUser.id === membership?.user?.id,
)

/**
 * 9 - SUPER_ADMIN
 * 8 - ORGANIZATION_DIRECTOR
 * 7 - ORGANIZATION_NATIONAL_ADMIN
 * 6 - ORGANIZATION_FINANCE_ADMIN
 * 6 - ORGANIZATION_LINE_MANAGER
 * 5 - NURSERY_MANAGER
 * 4 - NURSERY_ADMIN
 * 4 - DEPUTY_MANAGER
 * 3 - ROOM_LEADER
 * 2 - SENIOR_TEACHER
 * 1 - TEACHER
 * 0 - NON_TEACHING
 */
export const hasAccessThisRoleAndAbove = (
  state: RootState,
  options: { excludedRoles?: Roles[], flags?: FeatureFlags[], role: Roles },
) => {
  const startRoleDetails = RolesDetails[options.role]

  if (!startRoleDetails) {
    return false
  }

  const startHierarchy = startRoleDetails.roleHierarchy

  const roles = _.map(
    _.sortBy(
      _.filter(
        _.values(RolesDetails),
        (role) => role.roleHierarchy >= startHierarchy && !_.includes(options.excludedRoles, role.value),
      ),
      'roleHierarchy',
    ),
    'value',
  )

  return (
    auth.SELECTORS.getIsAuthorised(state, {
      flags: options.flags,
      roles,
    })
  )
}
