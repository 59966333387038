import _ from 'lodash'

import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { RequestedExtraSession } from 'services/legacy/requestedExtraSessions/models'
import { STATUS_TYPES } from 'services/legacy/requestedExtraSessions/constants'

import { generateRoute } from 'utils/routing'

import { Flex, Hyperlink, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'
import SeeMore from 'module/Dashboard/components/SeeMore'

import i18n from 'translations'

import { StyledHeader } from './ExtraSessionRequestsWidgetStyled'
import { formatDate } from '../WidgetHelpers'

interface ExtraSessionRequestsWidgetViewProps {
  isFetching: boolean
  isFinanceV3Enabled: boolean
  pendingCount: number
  requestedExtraSessions: RequestedExtraSession[]
}

const ExtraSessionRequestsWidgetView: React.FC<ExtraSessionRequestsWidgetViewProps> = ({
  isFetching,
  isFinanceV3Enabled,
  pendingCount,
  requestedExtraSessions,
}) => {
  const renderListRow = (requestedExtraSession: RequestedExtraSession) => {
    const { child, date, id, nurserySession, nurserySessionProduct } = requestedExtraSession
    const { name } = child

    const session = isFinanceV3Enabled ? nurserySessionProduct : nurserySession

    return (
      <Widget.List.Row
        key={id}
        leftBottomColumn={(
          <Flex gap="8px">
            <Flex>
              <Typography fontSize={14} nowrap>
                {formatDate(date)}
              </Typography>
            </Flex>
            <Flex flex="1">
              <Typography color={FLAG_COLOURS.INFO} fontSize={14} ellipsis>
                {session.name}
              </Typography>
            </Flex>
          </Flex>
        )}
        leftTopColumn={name}
        persons={[child]}
        to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS', { id })}
      />
    )
  }

  const renderContent = () => {
    if (!requestedExtraSessions?.length) {
      return (
        <Widget.EmptyState
          icon="extra-session-request"
          text1={i18n.t('module:Dashboard:Widgets:ExtraSessionRequestsWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:ExtraSessionRequestsWidget:emptyStateText2')}
        />
      )
    }

    return (
      <React.Fragment>
        <Widget.List>
          {_.map(requestedExtraSessions, renderListRow)}
        </Widget.List>
        <SeeMore to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')} />
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:ExtraSessionRequestsWidget:title')} />
      <Widget.Content isLoading={isFetching}>
        {requestedExtraSessions?.length ? (
          <Hyperlink
            to={`${generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')}?status=${STATUS_TYPES.PENDING}`}
            variant="div"
          >
            <StyledHeader>
              <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={40} lineHeight={48} bold>
                {pendingCount || 0}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={18}>
                {i18n.t('module:Dashboard:Widgets:ExtraSessionRequestsWidget:pendingApproval')}
              </Typography>
              <Icon height={16} icon="chevron-right" />
            </StyledHeader>
          </Hyperlink>
        ) : null}
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ExtraSessionRequestsWidgetView
