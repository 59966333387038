import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import {
  HOME_ACCIDENT_STATUS_OPTIONS,
  INJURIES_STATUS_OPTIONS,
  INJURY_TYPE_OPTIONS,
  TYPE_OPTIONS,
} from 'services/legacy/injuries/constants'

import { generateRoute } from 'utils/routing'

import { withInjuriesService } from 'services/legacy/injuries'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import InjuryRecordsView from './InjuryRecordsView'

const GROUPS = {
  read: [
    'injury.injured',
    'injured',
    'injured.child',
    'child',
    'injury.injurySignatureStatistics',
    'injured.accidentDetails',
  ],
}

const HOME_ACCIDENT_GROUPS = {
  read: [
    'injury.injured',
    'injured',
    'injured.child',
    'child',
    'injury',
    'injured.accidentDetails',
    'injury.injurySignatureStatistics',
  ],
}

class InjuryRecordsContainer extends Component {
  constructor(props) {
    super(props)

    const { location, params } = props
    const { pathname, query } = location
    const { childId } = params
    const { date, injuryType, room, status, supervisor, type } = nest(query)

    const after = date && date.after ? moment(date.after).format(DEFAULT_DATE_FORMAT) : undefined
    const before = date && date.before ? moment(date.before).format(DEFAULT_DATE_FORMAT) : undefined

    this.state = {
      filters: {
        childSearch: null,
        date: {
          after,
          before,
        },
        injuryType,
        room,
        status,
        supervisor,
        type,
      },
      flag: {
        isHomeAccidents: pathname.includes(generateRoute('SAFEGUARDING.HOME_ACCIDENTS.LIST')),
      },
      isChildContext: !!childId,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const { injuriesActions, injuriesSelectors, paginationUtils, params, setLocationQuery } = this.props
    const { getCriteria } = injuriesSelectors
    const { filters, flag } = this.state
    const { isHomeAccidents } = flag
    const { childId } = params
    const { page, perPage } = paginationUtils

    setLocationQuery(flatten(filters))

    if (childId) {
      filters.child = childId
    }

    injuriesActions.list({
      params: {
        criteria: getCriteria(filters, flag),
        groups: isHomeAccidents ? HOME_ACCIDENT_GROUPS : GROUPS,
        limit: perPage,
        page,
      },
    })
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DEFAULT_DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DEFAULT_DATE_FORMAT) : undefined,
    }

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, date },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleTypeChange = (type) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, type: type && type.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleInjuryTypeChange = (type) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, injuryType: type && type.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSupervisorChange = (supervisor) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, supervisor: supervisor && supervisor.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room && room.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, status: status && status.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleChildSearch = (searchPhrase) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, childSearch: searchPhrase },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  render() {
    const {
      injuriesListState: { isFetching, meta },
      paginationUtils,
      params,
      records,
    } = this.props
    const { childId } = params
    const { filters, flag, isChildContext } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { date: { after, before }, injuryType, room, status, supervisor, type } = filters
    const { isHomeAccidents } = flag

    const dateRange = [after, before]
    const pageCount = getPageCount(meta.total_results)

    return (
      <InjuryRecordsView
        childId={childId}
        dateRange={dateRange}
        homeAccidentTypeOptions={INJURY_TYPE_OPTIONS}
        injuryType={injuryType}
        isChildContext={isChildContext}
        isHomeAccidents={isHomeAccidents}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        records={records}
        room={room}
        status={status}
        statusOptions={isHomeAccidents ? HOME_ACCIDENT_STATUS_OPTIONS : INJURIES_STATUS_OPTIONS}
        supervisor={supervisor}
        totalResults={meta.total_results}
        type={type}
        typeOptions={TYPE_OPTIONS}
        onChildSearch={this.handleChildSearch}
        onDateChange={this.handleDateChange}
        onInjuryTypeChange={this.handleInjuryTypeChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onStatusChange={this.handleStatusChange}
        onSupervisorChange={this.handleSupervisorChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { injuriesSelectors }) => ({
  records: injuriesSelectors.getDataRowOfInjuriesListSelector(state),
})

const enhance = compose(
  withRouter,
  withInjuriesService,
  withPaginationUtils,
  withRouterUtils,
  withModalService,
  connect(mapState),
)

export default enhance(InjuryRecordsContainer)
