import React, { PropsWithChildren } from 'react'

import { Flex } from 'components'

import WidgetListRow from './WidgetListRow'

interface WidgetListRoot extends React.FC<PropsWithChildren> {
  Row?: typeof WidgetListRow
}

const WidgetList: WidgetListRoot = ({ children }) => (
  <Flex flexDirection="column" margin="0 -24px">
    {children}
  </Flex>
)

export default WidgetList
