import React from 'react'

import { STAFF_COLOURS } from 'services/legacy/membershipsShifts/constants'

import { Typography } from 'components'

import i18n from 'translations'

import { StyledLegend, StyledLegendItem, StyledLine } from './ChartRotaLegendStyled'

const ChartRotaLegend = () => (
  <StyledLegend>
    <StyledLegendItem>
      <StyledLine $background={STAFF_COLOURS.REQUIRED} />
      <Typography fontSize={14}>
        {i18n.t('module:Staff:StaffRota:staffRequired')}
      </Typography>
    </StyledLegendItem>
    <StyledLegendItem>
      <StyledLine $background={STAFF_COLOURS.EXPECTED} />
      <Typography fontSize={14}>
        {i18n.t('module:Staff:StaffRota:staffExpected')}
      </Typography>
    </StyledLegendItem>
  </StyledLegend>
)

export default ChartRotaLegend
