import React from 'react'

import { TARGET_TYPES } from 'constants/css'
import { TypographyProps } from 'components/Typography/Typography'

import { typeByObject } from 'utils/typescript'

import { Typography } from 'components'

import { StyledHyperlink } from './HyperlinkStyled'

interface HyperlinkProps {
  onClick?: () => void
  target?: typeByObject<typeof TARGET_TYPES>
  to?: string
  visibility?: boolean
}

const Hyperlink: React.FC<HyperlinkProps & TypographyProps> = ({
  children,
  onClick,
  target,
  to,
  visibility,
  width,
  ...rest
}) => (
  <StyledHyperlink
    $visibility={visibility}
    $width={width}
    target={target}
    to={to}
    onClick={onClick}
  >
    <Typography width={width} {...rest}>
      {children}
    </Typography>
  </StyledHyperlink>
)

export default Hyperlink
