import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import {
  DataTable,
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Select,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { PriceStatistic } from './components'

import { StyledStatisticsWrapper } from './RequestedExtraSessionsListStyled'

const RequestedExtraSessionsListView = ({
  costStatistics,
  dateRange,
  isCostStatisticsFetching,
  isFinanceV3Enabled,
  isLoading,
  onDateChange,
  onPageChange,
  onSearch,
  onSessionChange,
  onSortChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  search,
  session,
  sortField,
  sortOrder,
  status,
  statusOptions,
  tableColumns,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!tableData?.length) {
      return (
        <EmptyState
          icon="extra-session-request"
          text1={i18n.t('module:Parent:RequestedSessions:List:emptyState')}
        />
      )
    }

    const renderTableContent = (isHeader) => _.map(tableColumns, ({
      align,
      field,
      justifyContent,
      sortKey,
      sortable,
      title,
      width,
    }) => (
      <DataTable.Column noBorder>
        <DataTable.Row
          background={NEUTRAL_COLOURS.WHITE}
          height="50"
          justifyContent={justifyContent}
          minWidth={width}
          textAlign={align}
          width={width}
        >
          {sortable ? (
            <DataTable.Sortable
              currentField={sortField}
              currentOrder={sortOrder}
              name={field}
              sortKey={sortKey}
              onChange={onSortChange}
            >
              {title}
            </DataTable.Sortable>
          ) : title}
        </DataTable.Row>
        {!isHeader && _.map(tableData, (item) => (
          <DataTable.Row
            alignItems="center"
            height="55"
            justifyContent={justifyContent}
            key={item.id}
            minWidth={width}
            textAlign={align}
            width={width}
            onClick={item.onClick}
          >
            {item[field]}
          </DataTable.Row>
        ))}
      </DataTable.Column>
    ))

    return (
      <React.Fragment>
        {renderPagination()}
        <DataTable>
          <DataTable.Head width="240">
            <DataTable.Column borderRight noBorder>
              <DataTable.Row
                background={NEUTRAL_COLOURS.WHITE}
                height="50"
                stickyElement
              >
                <DataTable.Sortable
                  currentField={sortField}
                  currentOrder={sortOrder}
                  name="name"
                  sortKey="child.firstName"
                  onChange={onSortChange}
                >
                  <Typography bold>
                    {i18n.t('module:Parent:RequestedSessions:List:columns:child')}
                  </Typography>
                </DataTable.Sortable>
              </DataTable.Row>
              {_.map(tableData, ({ name, onClick }) => (
                <DataTable.Row
                  alignItems="start"
                  height="55"
                  justifyContent="center"
                  minWidth="240"
                  width="240"
                  column
                  onClick={onClick}
                >
                  {name}
                </DataTable.Row>
              ))}
            </DataTable.Column>
          </DataTable.Head>
          <DataTable.ContentScrollable
            data={[{
              data: renderTableContent(),
              stickyHeader: () => [renderTableContent(true)],
            }]}
            enableCustomScrollbar
            hideSeparator
          />
        </DataTable>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        value={search}
        onChange={onSearch}
      />
      <Page.Section
        isLoading={isLoading}
        title={i18n.t('module:Parent:RequestedSessions:List:subtitle')}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <DatePicker
                value={dateRange}
                clearable
                range
                onChange={onDateChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              {isFinanceV3Enabled ? (
                <InfiniteDropdowns.NurserySessionsV3
                  placeholder={i18n.t('module:Parent:RequestedSessions:List:filters:sessions:title')}
                  value={session}
                  searchable
                  onChange={onSessionChange}
                />
              ) : (
                <InfiniteDropdowns.NurserySessions
                  placeholder={i18n.t('module:Parent:RequestedSessions:List:filters:sessions:title')}
                  value={session}
                  searchable
                  onChange={onSessionChange}
                />
              )}
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                options={statusOptions}
                placeholder={i18n.t('module:Parent:RequestedSessions:List:filters:status:title')}
                value={status}
                onChange={onStatusChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <StyledStatisticsWrapper>
          {!isCostStatisticsFetching && (
            <React.Fragment>
              <PriceStatistic
                label={i18n.t('module:Parent:RequestedSessions:List:filters:status:total')}
                statistic={costStatistics?.total}
              />
              <PriceStatistic
                label={i18n.t('module:Parent:RequestedSessions:List:filters:status:pending')}
                statistic={costStatistics?.pending}
              />
              <PriceStatistic
                label={i18n.t('module:Parent:RequestedSessions:List:filters:status:approved')}
                statistic={costStatistics?.approved}
              />
              <PriceStatistic
                label={i18n.t('module:Parent:RequestedSessions:List:filters:status:declined')}
                statistic={costStatistics?.declined}
              />
            </React.Fragment>
          )}
        </StyledStatisticsWrapper>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default RequestedExtraSessionsListView
