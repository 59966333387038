import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { BasicListResponse } from 'constants/service'
import { RequestedExtraSession } from 'services/legacy/requestedExtraSessions/models'
import { STATUS_TYPES } from 'services/legacy/requestedExtraSessions/constants'
import { RootState } from 'core/reducers'

import auth from 'utils/auth'

import {
  withRequestedExtraSessionsService,
  withRequestedExtraSessionsServiceProps,
} from 'services/legacy/requestedExtraSessions'
import * as requestedExtraSessionsSelectors from 'services/legacy/requestedExtraSessions/selectors'

import ExtraSessionRequestsWidgetView from './ExtraSessionRequestsWidgetView'

const REQUESTED_EXTRA_SESSIONS_PREVIEW_GROUPS = {
  read: [
    'requestExtraSession.nurserySessionProduct',
    'requestExtraSession.child',
    'requestExtraSession.nurserySession',
    'nurserySession',
    'child',
  ],
}

const mapState = (state: RootState) => ({
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type ExtraSessionRequestsWidgetContainerProps = PropsFromRedux & withRequestedExtraSessionsServiceProps

const ExtraSessionRequestsWidgetContainer: React.FC<ExtraSessionRequestsWidgetContainerProps> = ({
  isFinanceV3Enabled,
  requestedExtraSessionsActions,
}) => {
  const [pendingCount, setPendingCount] = useState<number | null>(null)
  const [requestedExtraSessions, setRequestedExtraSessions] = useState<RequestedExtraSession[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getExtraSessionRequestsSuccess = (response: BasicListResponse<RequestedExtraSession>) => {
    const { meta: { total_results: totalResults } } = response
    setPendingCount(totalResults)
    setRequestedExtraSessions(response.data)
    setIsFetching(false)
  }

  useEffect(() => {
    const criteria = requestedExtraSessionsSelectors.getListCriteria({
      status: STATUS_TYPES.PENDING,
    })

    requestedExtraSessionsActions.list({
      onSuccess: getExtraSessionRequestsSuccess,
      onlyData: true,
      params: {
        criteria,
        groups: REQUESTED_EXTRA_SESSIONS_PREVIEW_GROUPS,
        order: {
          sortField: 'date',
          sortOrder: 'DESC',
        },
      },
    })
  }, [])

  return (
    <ExtraSessionRequestsWidgetView
      isFetching={isFetching}
      isFinanceV3Enabled={isFinanceV3Enabled}
      pendingCount={pendingCount}
      requestedExtraSessions={requestedExtraSessions}
    />
  )
}

const enhance = compose(
  withRequestedExtraSessionsService,
  connector,
)

export default enhance(ExtraSessionRequestsWidgetContainer)
