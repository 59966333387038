import i18n from 'translations'

import StartersAndLeaversWidget from './StartersAndLeaversWidgetContainer'

export const WIDGET_NAME = {
  component: StartersAndLeaversWidget,
  id: 'STARTERS_AND_LEAVERS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:StartersAndLeaversWidget:titleInSettings'),
}

export default StartersAndLeaversWidget
