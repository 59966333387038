import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { BasicModalProps, ModalType } from 'modals'
import { FEATURE_FLAGS } from 'constants/security'
import { Option } from 'constants/models'

import { EVENTS, logEvent } from 'analytics'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withDailyDiaryService, withDailyDiaryServiceProps } from 'services/legacy/dailyDiary'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'

import i18n from 'translations'

import DailyDiaryModalView, { Filters } from './DailyDiaryModalView'

const GROUPS = {
  read: [
    'dailyDiary.child',
    'child',
  ],
}

const LIST_LIMIT = 20

const mapState = (state, { appSelectors, dailyDiaryListState, dailyDiarySelectors, nurseriesSelectors }) => ({
  autoSendDiarySetting: nurseriesSelectors.getAutoSendDiarySetting(state),
  data: dailyDiarySelectors.getDailyDiariesListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(dailyDiaryListState),
  isDiaryAutoSendEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DIARY_AUTOSEND],
  }),
  isFetching: appSelectors.getIsFetching(dailyDiaryListState),
  totalResults: appSelectors.getTotalResults(dailyDiaryListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type DailyDiaryModalContainerFullProps = PropsFromRedux
  & BasicModalProps
  & withAppServiceProps
  & withDailyDiaryServiceProps
  & withModalServiceProps
  & withNurseriesServiceProps
  & withPaginationUtilsProps
  & withSortUtilsProps

const DailyDiaryModalContainer: React.FC<DailyDiaryModalContainerFullProps> = ({
  autoSendDiarySetting,
  dailyDiaryActions,
  dailyDiarySelectors,
  data,
  errorMessages,
  hideModal,
  isDiaryAutoSendEnabled,
  isFetching,
  modalActions,
  paginationUtils,
  sortingUtils,
  totalResults,
}) => {
  const { getPageCount, onPageChange, page, setPageLocationQuery, setPerPage } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils

  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [filters, setFilters] = useState<Filters>({
    date: moment(),
    room: null,
  })
  const [dailyDiaries, setDailyDiaries] = useState<number[]>([])
  const [all, setAll] = useState<boolean>(false)
  const [skipIds, setSkipIds] = useState<number[]>([])

  const fetch = (newPage: number) => {
    const criteria = dailyDiarySelectors.getDailyDiariesListCriteria(filters)

    const params = {
      criteria,
      groups: GROUPS,
      limit: LIST_LIMIT,
      order: {
        sortField: sortField || 'child.firstName',
        sortOrder: !sortField ? 'ASC' : sortOrder,
      },
      page: newPage,
    }

    return dailyDiaryActions.list({
      mergeResult: 1 !== page,
      params,
    })
  }

  useEffect(() => {
    onPageChange()(1)

    if (1 === page) {
      fetch(1)
    }
  }, [filters])

  useEffect(() => {
    fetch(page)
  }, [page])

  const handleRoomChange = (room: Option) => {
    setAll(false)
    setSkipIds([])
    setDailyDiaries([])
    setFilters((prevFilters) => ({
      ...prevFilters,
      room: room?.value,
    }))
  }

  const handleDateChange = (date: moment.Moment) => {
    setAll(false)
    setSkipIds([])
    setDailyDiaries([])
    setFilters((prevFilters) => ({
      ...prevFilters,
      date,
    }))
  }

  const handleSelectAllClick = (value: boolean) => {
    setAll(value)
    setSkipIds([])
    setDailyDiaries([])
  }

  const handleSelectItemClick = (value: boolean, id: number) => {
    if (all) {
      setAll(false)
      setSkipIds((prevSkipIds) => [
        ...prevSkipIds,
        id,
      ])

      return
    }

    if (skipIds?.length) {
      if (totalResults - 1 === skipIds.length) {
        setSkipIds([])

        return
      }

      if (!value) {
        setSkipIds((prevSkipIds) => [
          ...prevSkipIds,
          id,
        ])

        return
      }

      const newSkipIds = _.clone(skipIds)
      _.remove(newSkipIds, (skipId) => id === skipId)

      if (!newSkipIds?.length) {
        setAll(true)
        setSkipIds([])

        return
      }

      setSkipIds(newSkipIds)

      return
    }

    if (value) {
      setDailyDiaries((prevDailyDiaries) => [
        ...prevDailyDiaries,
        id,
      ])

      return
    }

    const newDailyDiaries = _.clone(dailyDiaries)
    _.remove(newDailyDiaries, (dailyDiary) => id === dailyDiary)

    setDailyDiaries(newDailyDiaries)
  }

  const handlePageChange = onPageChange()

  const handleSortChange = (key: string) => onSortChange(onPageChange()(1))(key)

  const handleSubmitSuccess = () => {
    logEvent(EVENTS.DIARY_SENT, { context: 'dashboard' })

    setIsExporting(false)

    return modalActions.show<ModalType.ALERT>(ModalType.ALERT, {
      text: i18n.t('module:Modals:DailyDiary:successAlert'),
    })
  }

  const handleSubmit = () => {
    setIsExporting(true)

    const criteria = dailyDiarySelectors.getDailyDiariesSendCriteria({
      ...filters,
      all: totalResults === dailyDiaries?.length ? true : all,
      dailyDiaries,
      skipIds,
    })

    return dailyDiaryActions.send({
      onFailed: () => { setIsExporting(false) },
      onSuccess: handleSubmitSuccess,
      params: { criteria },
    })
  }

  useEffect(() => {
    setPageLocationQuery(false)
    setPerPage(LIST_LIMIT)

    return () => {
      dailyDiaryActions.clearList()
    }
  }, [])

  const pageCount = getPageCount(totalResults)
  const isSubmitDisabled = (!all && !skipIds?.length && !dailyDiaries?.length)
    || (isFetching && 1 === page)
    || isExporting

  return (
    <DailyDiaryModalView
      all={all}
      autoSendDiarySetting={autoSendDiarySetting}
      dailyDiaries={dailyDiaries}
      data={data}
      error={errorMessages}
      filters={filters}
      isDiaryAutoSendEnabled={isDiaryAutoSendEnabled}
      isExporting={isExporting}
      isFetching={isFetching}
      isSubmitDisabled={isSubmitDisabled}
      page={page}
      pageCount={pageCount}
      skipIds={skipIds}
      sortField={sortField}
      sortOrder={sortOrder}
      totalResults={totalResults}
      onCancelClick={hideModal}
      onDateChange={handleDateChange}
      onPageChange={handlePageChange}
      onRoomChange={handleRoomChange}
      onSelectAllClick={handleSelectAllClick}
      onSelectItemClick={handleSelectItemClick}
      onSortChange={handleSortChange}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withDailyDiaryService,
  withModalService,
  withNurseriesService,
  withPaginationUtils,
  withSortingUtils,
  connector,
)

export default enhance(DailyDiaryModalContainer)
