import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Chart, Flex, Icon, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledChartContainer } from './AnnualRevenueWidgetStyled'

interface AnnualRevenueWidgetViewProps {
  annualRevenueStatistics: {
    categories: string[]
    data: {
      color: string
      data: number[]
      name: string
    }[]
  }
  isFetching: boolean
}

const config = ({
  chart: {
    height: 210,
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointWidth: 10,
    },
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: false,
      },
    },
  },
  tooltip: {
    shared: true,
  },
  xAxis: {
    labels: {
      rotation: -60,
      style: {
        color: NEUTRAL_COLOURS.GRAY,
        font: null,
        fontSize: '9px',
        letterSpacing: '-0.3px',
      },
    },
    tickWidth: 0,
  },
  yAxis: {
    labels: {
      style: {
        color: NEUTRAL_COLOURS.GRAY,
        font: null,
        fontSize: '12px',
      },
    },
    stackLabels: {
      enabled: false,
    },
  },
}) as any

const AnnualRevenueWidgetView: React.FC<AnnualRevenueWidgetViewProps> = ({ annualRevenueStatistics, isFetching }) => {
  const { categories, data } = annualRevenueStatistics || {}
  const summary = _.sumBy(data, (item) => _.sum(item.data))

  const finalConfig = config

  if (0 === summary) {
    delete finalConfig.yAxis.tickAmount
    finalConfig.yAxis.tickPositions = [0]
  } else {
    delete finalConfig.yAxis.tickPositions
    finalConfig.yAxis.tickAmount = 5
  }

  return (
    <Widget>
      <Widget.Header
        label={(
          <Flex alignItems="center" gap="12px">
            <Typography fontSize={18} fontWeight={600}>
              {i18n.t('module:Dashboard:Widgets:AnnualRevenueWidget:title')}
            </Typography>
            <Link to={generateRoute('FINANCE.REPORT.ANNUAL_REVENUE')}>
              <Icon height={16} icon="chevron-right" />
            </Link>
          </Flex>
        )}
      />
      <Widget.Content isLoading={isFetching}>
        <StyledChartContainer>
          <Chart.Column
            categories={categories}
            config={finalConfig}
            data={data}
            isCurrencyValue
          />
        </StyledChartContainer>
      </Widget.Content>
    </Widget>
  )
}

export default AnnualRevenueWidgetView
