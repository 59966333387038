import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import { ViewingsStatisticsWidgetSingleState } from './constants'

export interface withViewingsStatisticsWidgetServiceProps {
  viewingsStatisticsWidgetActions: typeof actions
  viewingsStatisticsWidgetSingleState: ViewingsStatisticsWidgetSingleState
}

const withViewingsStatisticsWidgetService = <P extends withViewingsStatisticsWidgetServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ viewingsStatisticsWidget }) => ({ viewingsStatisticsWidget })

  const mapDispatch = injectActions('viewingsStatisticsWidgetActions', actions)

  const Component = ({ viewingsStatisticsWidget, viewingsStatisticsWidgetActions, ...others }) => (
    <WrappedComponent
      viewingsStatisticsWidgetActions={viewingsStatisticsWidgetActions}
      viewingsStatisticsWidgetSingleState={viewingsStatisticsWidget.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withViewingsStatisticsWidgetService
