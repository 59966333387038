import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { Spinner } from 'components'

import { StyledContent } from './WidgetStyled'

interface WidgetContentProps extends PropsWithChildren {
  horizontalAlign?: Property.JustifyContent
  id?: string
  isLoading?: boolean
  noPadding?: boolean
  verticalAlign?: Property.AlignItems
}

const WidgetContent: React.FC<WidgetContentProps> = ({
  children,
  horizontalAlign,
  id,
  isLoading,
  noPadding,
  verticalAlign,
}) => (
  <StyledContent
    horizontalAlign={horizontalAlign}
    id={id}
    isLoading={isLoading}
    noPadding={noPadding}
    verticalAlign={verticalAlign}
  >
    {isLoading ? <Spinner /> : children}
  </StyledContent>
)

export default WidgetContent
