import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { BirthdayWidgetRecord } from 'services/birthdayWidget/models'
import { BasicListResponse } from 'constants/service'

import { withBirthdayWidgetService, withBirthdayWidgetServiceProps } from 'services/birthdayWidget'

import BirthdaysWidgetView from './BirthdaysWidgetView'

type BirthdaysWidgetContainerProps = withBirthdayWidgetServiceProps

const BirthdaysWidgetContainer: React.FC<BirthdaysWidgetContainerProps> = ({
  birthdayWidgetActions,
}) => {
  const [birthdayRecords, setBirthdayRecords] = useState<BirthdayWidgetRecord[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)

  const getListSuccess = (response: BasicListResponse<BirthdayWidgetRecord>) => {
    setBirthdayRecords(1 === response.meta.start ? response.data : [...birthdayRecords, ...response.data])
    setIsFetching(false)
    setPage(response.meta.start)

    if (1 === response.meta.start) {
      setPageCount(Math.ceil(response.meta.total_results / response.meta.limit))
    }
  }

  const fetchList = () => {
    birthdayWidgetActions.list({
      onSuccess: getListSuccess,
      onlyData: true,
      params: {
        page: page + 1,
      },
    })
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <BirthdaysWidgetView
      birthdayRecords={birthdayRecords}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      onLoadNextPage={fetchList}
    />
  )
}

const enhance = compose(
  withBirthdayWidgetService,
)

export default enhance(BirthdaysWidgetContainer)
