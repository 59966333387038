import moment from 'moment'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { CUSTOM_HEADERS } from 'constants/http'

import { withInvoicesForecastService, withInvoicesForecastServiceProps } from 'services/legacy/invoices'
import appSelectors from 'services/app/selectors'
import * as invoicesForecastSelectors from 'services/legacy/invoices/forecast/selectors'

import AnnualRevenueWidgetView from './AnnualRevenueWidgetView'

const STATISTICS_GROUPS = {
  read: [
    'forecastStatistics',
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}

const mapState = (state: RootState, { invoicesForecastState }) => ({
  annualRevenueStatistics: invoicesForecastSelectors.getChartData(state),
  isFetching: appSelectors.getIsFetching(invoicesForecastState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AnnualRevenueWidgetContainerProps = PropsFromRedux & withInvoicesForecastServiceProps

const AnnualRevenueWidgetContainer: React.FC<AnnualRevenueWidgetContainerProps> = ({
  annualRevenueStatistics,
  invoicesForecastActions,
  isFetching,
}) => {
  useEffect(() => {
    const criteria = invoicesForecastSelectors.getCriteria({ date: {
      after: moment().startOf('year'),
      before: moment().endOf('year'),
    } })

    invoicesForecastActions.getForecast({
      criteria,
      groups: STATISTICS_GROUPS,
    }, {
      [CUSTOM_HEADERS.ENABLE_CACHE]: 1,
    })

    return () => {
      invoicesForecastActions.clearInvoicesForecast()
    }
  }, [])

  return (
    <AnnualRevenueWidgetView
      annualRevenueStatistics={annualRevenueStatistics}
      isFetching={isFetching}
    />
  )
}

const enhance = compose(
  withInvoicesForecastService,
  connector,
)

export default enhance(AnnualRevenueWidgetContainer)
