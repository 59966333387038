import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: grid;
  flex-direction: column;
  grid-template-columns: auto auto auto 1fr;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`
