import styled from 'styled-components'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { SHIFT_MAIN_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { DAY_RECORD_COLOURS } from 'module/Staff/StaffRota/components/DayMode/DayModeStyled'

import TableTr from 'components/Table/TableTr'

export const StyledWeek = styled(TableTr)`
  padding-top: 12px;
  border-top: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  
  & td:nth-of-type(1) {
    transform: translateY(2px);
  }

  ${({ disabled }) => !disabled && `
    &:hover {
      & td:not(:nth-of-type(1)) {
        cursor: pointer;
        background: #F5F5F5;
        transition: .2s;
      }
    }
  `}
`

export const StyledTableWrapper = styled.div`
  padding: 4px;
  
  @media(max-width: 1140px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  ${({ overflowPolyfill }) => overflowPolyfill && `
    padding-top: 80px;
    margin-top: -80px;
  `}
  
  table {
    width: 100%;
  }
`

export const StyledTooltipDetails = styled.div`
  display: grid;
  grid-row-gap: 2px;
`

export const StyledTooltipDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  grid-column-gap: 10px;
  
  ${({ dayView }) => dayView && `
    padding: 0 5px;
    height: 22px;
    align-items: center;
    grid-template-columns: 16px 120px auto;
  `}
`

export const StyledLegend = styled.div`
  width: 16px;
  height: 16px;
  
  ${({ type }) => WORK_SHIFT_TYPES.CONTRACTED === type && `
    background: ${DAY_RECORD_COLOURS[SHIFT_MAIN_TYPES.WORK].background};
    border-left: 2px solid ${DAY_RECORD_COLOURS[SHIFT_MAIN_TYPES.WORK].border};
  `}
    
  ${({ type }) => WORK_SHIFT_TYPES.OVERTIME === type && `
    background: ${DAY_RECORD_COLOURS[SHIFT_MAIN_TYPES.WORK].background};
    border-left: 2px solid ${FLAG_COLOURS.ERROR};
  `}

  ${({ type }) => WORK_SHIFT_TYPES.BREAK === type && `
    background: repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 4px, 
      ${DAY_RECORD_COLOURS.BREAK} 4px, 
      ${DAY_RECORD_COLOURS.BREAK} 8px
    );
  `}

  ${({ type }) => WORK_SHIFT_TYPES.BREAK_DURATION === type && `
    border: 1px solid ${NEUTRAL_COLOURS.GRAY};
  `}
`
