import React from 'react'
import { compose } from 'recompose'

import { EVENTS, logEvent } from 'analytics'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { ModalType } from 'modals'

import ObservationDiaryButtonsWidgetView from './ObservationDiaryButtonsWidgetView'

type ObservationDiaryButtonsWidgetProps = withModalServiceProps

const ObservationDiaryButtonsWidget: React.FC<ObservationDiaryButtonsWidgetProps> = ({
  modalActions,
}) => {
  const handleSendDailyDiaryButtonClick = () => {
    logEvent(EVENTS.DIARY_SEND_BTN_CLICKED, { context: 'dashboard' })

    modalActions.show<ModalType.DAILY_DIARY>(ModalType.DAILY_DIARY)
  }

  return (
    <ObservationDiaryButtonsWidgetView
      onSendDailyDiaryButtonClick={handleSendDailyDiaryButtonClick}
    />
  )
}

const enhance = compose(withModalService)

export default enhance(ObservationDiaryButtonsWidget)
