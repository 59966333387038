import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'

import * as injuriesSelectors from './selectors'

export interface withInjuriesServiceProps {
  injuriesActions: typeof actions
  injuriesListState: any // @TODO
  injuriesSelectors: typeof injuriesSelectors
  injuriesSignaturesState: any // @TODO
  injuriesSingleState: any // @TODO
}

const withInjuriesService = <P extends withInjuriesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ injuries }) => ({ injuries })

  const mapDispatch = injectActions('injuriesActions', actions)

  const Component = (props) => {
    const { injuries, injuriesActions, ...others } = props

    return (
      <WrappedComponent
        injuriesActions={injuriesActions}
        injuriesListState={injuries.list}
        injuriesSelectors={injuriesSelectors}
        injuriesSignaturesState={injuries.signatures}
        injuriesSingleState={injuries.single}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withInjuriesService
