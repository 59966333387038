import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledContainer } from 'module/Dashboard/components/Widget/WidgetList/WidgetListStyled'

export const StyledItemWrapper = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  border-radius: 12px;
  margin: 0 24px;
  margin-bottom: 8px;

  & ${StyledContainer} {
    padding: 12px;
  }
`
