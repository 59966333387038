import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { BasicListResponse } from 'constants/service'
import { Injury } from 'services/legacy/injuries/models'
import { INJURIES_STATUS } from 'services/legacy/injuries/constants'

import { withInjuriesService, withInjuriesServiceProps } from 'services/legacy/injuries'

import AccidentsIncidentsSignaturesWidgetView from './AccidentsIncidentsSignaturesWidgetView'

const INJURIES_SIGNATURES_NEEDED_GROUPS = {
  read: [
    'child',
    'injury.injured',
    'injured',
    'injured.child',
    'injury.injurySignatureStatistics',
  ],
}

type AccidentsIncidentsSignaturesWidgetContainerProps = withInjuriesServiceProps

const AccidentsIncidentsSignaturesWidgetContainer: React.FC<AccidentsIncidentsSignaturesWidgetContainerProps> = ({
  injuriesActions,
}) => {
  const [injuries, setInjuries] = useState<Injury[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getInjuriesSuccess = (response: BasicListResponse<Injury>) => {
    setInjuries(response.data)
    setIsFetching(false)
  }

  useEffect(() => {
    injuriesActions.list({
      onSuccess: getInjuriesSuccess,
      onlyData: true,
      params: {
        criteria: [{
          field: 'status',
          value: INJURIES_STATUS.SIGNATURES_NEEDED.value,
        }],
        groups: INJURIES_SIGNATURES_NEEDED_GROUPS,
      },
    })
  }, [])

  return (
    <AccidentsIncidentsSignaturesWidgetView
      injuries={injuries}
      isFetching={isFetching}
    />
  )
}

const enhance = compose(
  withInjuriesService,
)

export default enhance(AccidentsIncidentsSignaturesWidgetContainer)
