import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledContainer } from 'module/Dashboard/components/Widget/WidgetList/WidgetListStyled'

export const StyledBox = styled.div<{ $quinary?: boolean }>`
  padding: 12px;
  border-radius: 12px;
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  margin-top: 8px;

  ${({ $quinary }) => $quinary && `
    background-color: ${getBrandingColor('quinary-color')};
  `}

  ${StyledContainer} {
    padding: 0;
  }
`
