import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import RequestedExtraSessionsWrapper from 'module/RequestedExtraSessions/RequestedExtraSessionsWrapper'

import ParentInboxNoAccessPlaceholder from './ParentInboxNoAccessPlaceholder'
import ParentInboxWrapperView from './ParentInboxWrapperView'

class ParentInboxWrapperContainer extends Component {
  componentDidMount() {
    const { RequestedExtraSessionsGranted, shellActions } = this.props

    shellActions.setSubMenu([
      {
        icon: 'parent',
        label: i18n.t('module:Parent:Submenu:homeObservations'),
        to: generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.LIST'),
      },
      {
        hidden: !RequestedExtraSessionsGranted,
        icon: 'extra-session-request',
        label: i18n.t('module:Parent:Submenu:requestedSessions'),
        to: generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST'),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps

    if (generateRoute('PARENT_INBOX.INDEX') === pathname) {
      navigate(generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.LIST'))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <ParentInboxWrapperView>
        {children}
      </ParentInboxWrapperView>
    )
  }
}

ParentInboxWrapperContainer.authParams = {
  failureComponent: ParentInboxNoAccessPlaceholder,
  flags: [FEATURE_FLAGS.HAS_PARENT_APP_ACCESS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state) => ({
  RequestedExtraSessionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, RequestedExtraSessionsWrapper),
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ParentInboxWrapperContainer)
