import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'

export interface withRegisterStatisticsServiceProps {
  registerStatistics: any // @TODO
  registerStatisticsActions: typeof actions
}

const withTransactionsService = <P extends withRegisterStatisticsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ registerStatistics }) => ({ registerStatistics })

  const mapDispatch = injectActions('registerStatisticsActions', actions)

  const Component = ({ registerStatistics, registerStatisticsActions, ...others }) => (
    <WrappedComponent
      registerStatisticsActions={registerStatisticsActions}
      registerStatisticsState={registerStatistics.statistics}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withTransactionsService
