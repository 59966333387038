import _ from 'lodash'

import React from 'react'

import { Nursery } from 'services/nurseries/models'

import { Button, EmptyState, Pagination, Spinner, Table } from 'components'

import i18n from 'translations'

const TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: i18n.t('global:Nursery'),
    width: '80%',
  },
  {
    align: 'center',
    field: 'action',
    width: '20%',
  },
]

interface NurseriesListTableProps {
  getActionRouting: (nursery: Nursery) => string
  isFetching: boolean
  noDataIcon: IconType
  nurseries: Nursery[]
  onPageChange: (page: number) => void
  page: number
  pageCount: number
  perPage?: number
  totalResults: number
}

const NurseriesListTable: React.FC<NurseriesListTableProps> = ({
  getActionRouting,
  isFetching,
  noDataIcon,
  nurseries,
  onPageChange,
  page,
  pageCount,
  perPage,
  totalResults,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderRow = (nursery: Nursery) => {
    const { archived, id, name } = nursery

    return ({
      action: !archived && <Button.TableAction to={getActionRouting(nursery)} />,
      key: id,
      name: (
        <React.Fragment>
          {name}
          {archived && ` (${i18n.t('global:disabled')})`}
        </React.Fragment>
      ),
    })
  }

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!nurseries?.length) {
      return (
        <EmptyState
          icon={noDataIcon}
          text1={i18n.t('global:noDataFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={TABLE_COLUMNS}
          data={_.map(nurseries, renderRow)}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return renderContent()
}

export default NurseriesListTable
