import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Currency, Flex, Spinner, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledBody } from './MonthlyIncomeWidgetStyled'

export interface Statistics {
  data: {
    totalFundingIncome: number
    totalIncome: number
    totalInvoices: number
  }
  isLoading: boolean
  monthName: string
}

interface MonthlyIncomeWidgetViewProps {
  currentStatistics: Statistics
  previousStatistics: Statistics
}

const MonthlyIncomeWidgetView = ({ currentStatistics, previousStatistics }: MonthlyIncomeWidgetViewProps) => {
  const renderPart = (statistics: Statistics) => {
    const { data, isLoading, monthName } = statistics
    const { totalFundingIncome, totalIncome, totalInvoices } = data || {}

    if (isLoading) {
      return (
        <Flex alignItems="center" height="100%" justifyContent="center" width="100%">
          <Spinner />
        </Flex>
      )
    }

    return (
      <Flex flexDirection="column" gap="8px">
        <Flex flexDirection="column" gap="6px">
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {i18n.t('module:Dashboard:Widgets:MonthlyIncomeWidget:monthIncome', { monthName })}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={24} bold>
            <Currency value={totalIncome} sameSize />
          </Typography>
        </Flex>
        <Flex gap="40px">
          <Flex flexDirection="column">
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
              {i18n.t('module:Dashboard:Widgets:MonthlyIncomeWidget:invoiced')}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18}>
              <Currency value={totalInvoices} sameSize />
            </Typography>
          </Flex>
          <Flex flexDirection="column">
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
              {i18n.t('module:Dashboard:Widgets:MonthlyIncomeWidget:funding')}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18}>
              <Currency value={totalFundingIncome} sameSize />
            </Typography>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Widget part>
      <Widget.Part>
        <StyledBody>
          {renderPart(currentStatistics)}
        </StyledBody>
      </Widget.Part>
      <Widget.Part>
        <StyledBody>
          {renderPart(previousStatistics)}
        </StyledBody>
      </Widget.Part>
    </Widget>
  )
}

export default MonthlyIncomeWidgetView
