import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Currency } from 'components'

import { StyledAmountContainer, StyledContainer, StyledLabelContainer } from './AmountSummaryStyled'

interface AmountSummaryProps {
  amount: number
  color?: string
  grayBackground?: boolean
  label: string
  margin?: string
  suffix?: string
}

const AmountSummary: React.FC<PropsWithChildren<AmountSummaryProps>> = ({
  amount,
  color = getBrandingColor('primary-color'),
  grayBackground,
  label,
  margin,
  suffix,
}) => {
  let background

  if (grayBackground) {
    background = NEUTRAL_COLOURS.GRAY_QUATERNARY
  }

  return (
    <StyledContainer $background={background} $margin={margin}>
      <StyledAmountContainer $color={color}>
        <Currency value={amount} />
        {suffix}
      </StyledAmountContainer>
      {label && <StyledLabelContainer>{label}</StyledLabelContainer>}
    </StyledContainer>
  )
}

export default AmountSummary
