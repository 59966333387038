import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { Child } from 'services/child/models'
import { Meta } from 'constants/service'

import { generateRoute } from 'utils/routing'

import { Avatar, InfiniteScroll, SearchBar, Spinner, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledChildBox, StyledChildrenContainer, StyledSearchWrapper } from './ChildrenSearchWidgetStyled'

interface ChildrenSearchWidgetViewProps {
  childrenList: Child[]
  childrenListMeta: Meta
  isLoading: boolean
  isLoadingSearchedPages: boolean
  onChangeSearch: (value: string) => void
  onLoadNextPage: () => void
}

const ChildrenSearchWidgetView: React.FC<ChildrenSearchWidgetViewProps> = ({
  childrenList,
  childrenListMeta,
  isLoading,
  isLoadingSearchedPages,
  onChangeSearch,
  onLoadNextPage,
}) => {
  const { limit, start, total_results: totalResults } = childrenListMeta

  const renderContent = () => {
    if (isLoadingSearchedPages) {
      return (
        <StyledChildrenContainer>
          <Spinner />
        </StyledChildrenContainer>
      )
    }

    if (!childrenList?.length) {
      return (
        <Widget.EmptyState
          icon="children"
          text1={i18n.t('module:Dashboard:Widgets:ChildrenSearchWidget:emptyStateText1')}
          text2={i18n.t('module:Dashboard:Widgets:ChildrenSearchWidget:emptyStateText2')}
        />
      )
    }

    return (
      <StyledChildrenContainer $childrenListLength={childrenList?.length} id="scrollableDiv">
        <InfiniteScroll
          dataLength={childrenList ? childrenList.length : 0}
          hasMore={start * limit < totalResults}
          next={onLoadNextPage}
          scrollableTarget="scrollableDiv"
        >
          {_.map(childrenList, (child) => (
            <StyledChildBox key={child.id}>
              <Avatar
                avatarSize="small"
                initials={[child.firstName, child.surname]}
                src={child.photo}
                title={(
                  <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={14}>
                    {child.displayName}
                  </Typography>
                )}
                to={generateRoute('CHILDREN.CHILD', { childId: child.id })}
              />
            </StyledChildBox>
          ))}
        </InfiniteScroll>
      </StyledChildrenContainer>
    )
  }

  return (
    <Widget>
      <Widget.Header label={i18n.t('module:Dashboard:Widgets:ChildrenSearchWidget:title')} />
      <Widget.Content isLoading={isLoading} noPadding>
        <StyledSearchWrapper>
          <SearchBar
            placeholder={i18n.t('module:Dashboard:Widgets:ChildrenSearchWidget:searchByChildName')}
            noBackground
            onChange={onChangeSearch}
          />
        </StyledSearchWrapper>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ChildrenSearchWidgetView
