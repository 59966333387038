import _ from 'lodash'
import moment from 'moment'

import i18n from 'translations'

export const formatDate = (date: string, format?: string) => {
  const today = moment()
  const givenDate = moment(date)

  if (today.isSame(givenDate, 'day')) {
    return _.upperFirst(i18n.t('global:today'))
  }

  return givenDate.format(format || 'ddd D MMM')
}
